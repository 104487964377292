/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CommitteeVote {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  NOT_VOTED = "NOT_VOTED",
}

export enum LoanCommentEmoji {
  DART_BOARD = "DART_BOARD",
  DIAMOND = "DIAMOND",
  FAST_SPEDOMETER = "FAST_SPEDOMETER",
  FOCUS_ARROWS = "FOCUS_ARROWS",
  MAGNIFYING_GLASS = "MAGNIFYING_GLASS",
  QUESTION_MARK = "QUESTION_MARK",
  SILENCED_BELL = "SILENCED_BELL",
  SQUIRREL = "SQUIRREL",
  THERMOMETER = "THERMOMETER",
  THUMBS_DOWN = "THUMBS_DOWN",
  THUMBS_UP = "THUMBS_UP",
  TOILET = "TOILET",
  UNDO = "UNDO",
}

export enum LoanCommentType {
  COMMITTEE_COMMENT = "COMMITTEE_COMMENT",
  CONFERENCE_CALL = "CONFERENCE_CALL",
  PASSIVE_COMMENT = "PASSIVE_COMMENT",
  USER_NOTE = "USER_NOTE",
  VOTE = "VOTE",
  WATCH_LIST = "WATCH_LIST",
}

export enum LoanStatus {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  HISTORICAL = "HISTORICAL",
  PAID_OFF = "PAID_OFF",
  RECOMMENDED = "RECOMMENDED",
  UPLOADED = "UPLOADED",
  VOIDED = "VOIDED",
  WITHDRAWN = "WITHDRAWN",
  WORKING = "WORKING",
  WRITTEN_OFF = "WRITTEN_OFF",
}

export enum LoanType {
  AG_INPUT = "AG_INPUT",
  AG_PRO = "AG_PRO",
  ALL_IN = "ALL_IN",
  STORAGE = "STORAGE",
}

export enum PendingActionType {
  CHANGE_TOLERANCE = "CHANGE_TOLERANCE",
  COMMITTEE_COMMENT = "COMMITTEE_COMMENT",
  COMPLIANCE_HOLD = "COMPLIANCE_HOLD",
  COMPLIANCE_REVIEW = "COMPLIANCE_REVIEW",
  CONFERENCE_CALL = "CONFERENCE_CALL",
  CORRESPONDENCE = "CORRESPONDENCE",
  DISBURSE = "DISBURSE",
  DOCUMENTATION = "DOCUMENTATION",
  PAYMENT = "PAYMENT",
  RECONCILE = "RECONCILE",
  STALE = "STALE",
  SUPPORT = "SUPPORT",
  VERIFY = "VERIFY",
  VOTE = "VOTE",
}

export interface CropInspectionSaveInput {
  comment?: string | null;
  cropInspectionAnswers: SaveCropInspectionAnswerInput[];
  cropInspectionId: string;
  cropInspectionRatings: SaveCropInspectionRatingInput[];
  loanNumber: number;
}

export interface LoanCommentAddInput {
  message: string;
  type: LoanCommentType;
  vote?: CommitteeVote | null;
}

export interface QuestionResponseInput {
  categoryId?: number | null;
  cropCode?: string | null;
  loanFullId?: string | null;
  questionId?: number | null;
  responseText?: string | null;
}

export interface SaveCropInspectionAnswerInput {
  answer: boolean;
  cropCode: string;
  refQuestionId: number;
}

export interface SaveCropInspectionRatingInput {
  inspectedAcres: number;
  loanCropUnitId: number;
  rating: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
