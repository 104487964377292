import React from 'react'

import { ErrorWrapper, MessageContainer } from './styles'

interface ErrorBoundaryProps {
  message?: string
  children: React.ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error, info) {
    return {
      hasError: true,
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorWrapper>
          <MessageContainer>
            {this.props.message ? this.props.message : 'Something went wrong.'}
          </MessageContainer>
        </ErrorWrapper>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
