import React from 'react'

import { MessageContainer, Wrapper } from './style'

interface Props {
  message: string
}

export const EmptyCard: React.FC<Props> = ({ message }) => {
  return (
    <Wrapper>
      <MessageContainer>{message}</MessageContainer>
    </Wrapper>
  )
}
