import React from 'react'
import { LogoutOutlined } from '@ant-design/icons'

import * as logo from '../../assets/logos/arm_lets_grow.png'

// styles
import { HeaderWrap, Image, Logout } from './style'

// components
import MediaItems from '../mediaItems'
import { useAuth } from '@arm/react-auth'

const Header: React.FC = () => {
  const { redirectToLogout, isAuthenticated } = useAuth()

  if (window.location && window.location.pathname && window.location.pathname.includes('photos')) {
    return null
  }

  return (
    <HeaderWrap>
      <MediaItems />
      <Image alt="ARM - Let's Grow" src={logo} />
      {isAuthenticated && (
        <Logout color="light" onClick={() => redirectToLogout()}>
          <LogoutOutlined />
        </Logout>
      )}
    </HeaderWrap>
  )
}

export default Header
