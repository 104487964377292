import React from 'react'
import { useHistory, useRouteMatch, Link } from 'react-router-dom'

// models
import { LoanStatus, PendingActionType } from '../../types/globalTypes'

// components
import ErrorCard from '../../components/errorCard'
import EmptyCard from '../../components/emptyCard'
import LoadingIndicator from '../../components/loadingIndicator'
import { Th, Td } from '../../components/table/Table'
import { LoanStatusIcon } from '../../components/loanStatusIcon/LoanStatusIcon'

// constants
import { EMPTY_LOANS_MESSAGE } from '../../constants/messages'

// style
import {
  LoanListTable,
  BorrowerTd,
  LoanListTr,
  LoanListPAIcon,
  PaginationWrapper,
  PaginationButton,
  LeftArrow,
  RightArrow,
  PaginationInfo,
} from './style'

const PAGE_SIZE = 50

export type LoanListData = {
  loans: {
    pageInfo: {
      endCursor: string
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string
    }
    totalCount: number
    nodes: LoanListItem[]
  }
}

export interface LoanListItem {
  id: number
  displayId: string
  borrower?: {
    name: {
      fullName: string
    }
  }
  status: LoanStatus
  pendingActions: {
    type: PendingActionType
    escalationLevel: number
  }[]
}

const LoanList = ({
  data = {
    loans: {
      nodes: [],
      pageInfo: {
        endCursor: '',
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: '',
      },
      totalCount: 0,
    },
  },
  error,
  executeLoanGrab,
  loading,
  pendingActionSortFlag,
  pageCount,
  setPageCount,
  setPendingActionSortFlag,
}: {
  data?: LoanListData
  error: any
  executeLoanGrab: ({ variables: any }) => void
  loading: boolean
  pendingActionSortFlag: boolean
  pageCount: number
  setPageCount: (pageCount: number) => void
  setPendingActionSortFlag: () => void
}) => {
  const history = useHistory()
  const match = useRouteMatch()

  const getTableBody = () => {
    if (error)
      return (
        <>
          <tr>
            <td colSpan={4}>
              <ErrorCard />
            </td>
          </tr>
          <tr>
            <td>
              {process.env.TEST_PHOTOS === 'true' && (
                <Link to="/photos">Preview photo</Link>
              )}
            </td>
          </tr>
        </>
      )

    if (loading)
      return (
        <tr>
          <Td align="center" colSpan={4}>
            <LoadingIndicator />
          </Td>
        </tr>
      )

    if (data.loans.nodes.length === 0)
      return (
        <tr>
          <td colSpan={4}>
            <EmptyCard message={EMPTY_LOANS_MESSAGE} />
          </td>
        </tr>
      )

    return data.loans.nodes.map((loan) => (
      <LoanListTr
        key={loan.id}
        onClick={() => history.push(`/loans/${loan.id}/details`)}
        isActive={match.url.includes(loan.id.toString())}
      >
        <Td>{loan.displayId}</Td>
        <BorrowerTd>{loan.borrower?.name.fullName ?? '-'}</BorrowerTd>
        <Td align="center">
          <LoanStatusIcon loanStatus={loan.status} />
        </Td>
        <Td align="center">
          {loan.pendingActions.length > 0 && (
            <LoanListPAIcon
              escalationType={loan.pendingActions[0].type}
              escalationLevel={loan.pendingActions[0].escalationLevel}
            />
          )}
        </Td>
      </LoanListTr>
    ))
  }

  return (
    <>
      <LoanListTable>
        <thead>
          <tr>
            <Th>Loan Num</Th>
            <Th>Borrower</Th>
            <Th align="center">Status</Th>
            <Th
              align="center"
              color={pendingActionSortFlag ? 'red' : 'default'}
              onClick={setPendingActionSortFlag}
            >
              Pending
            </Th>
          </tr>
        </thead>
        <tbody>{getTableBody()}</tbody>
      </LoanListTable>

      {!loading && data.loans.nodes.length ? (
        <PaginationWrapper>
          <PaginationButton
            onClick={() => {
              if (data.loans.pageInfo.hasPreviousPage) {
                setPageCount(pageCount - 1)
                executeLoanGrab({
                  variables: {
                    first: null,
                    last: PAGE_SIZE,
                    before: data.loans.pageInfo.startCursor,
                  },
                })
              }
            }}
          >
            <LeftArrow disabled={!data.loans.pageInfo.hasPreviousPage} />
          </PaginationButton>
          <PaginationInfo>
            {data.loans.totalCount ? (
              `${pageCount} of ${Math.ceil(data.loans.totalCount / PAGE_SIZE)}`
            ) : (
              <LoadingIndicator size="sml" />
            )}
          </PaginationInfo>
          <PaginationButton
            onClick={() => {
              if (data?.loans.pageInfo.hasNextPage) {
                setPageCount(pageCount + 1)
                executeLoanGrab({
                  variables: {
                    first: PAGE_SIZE,
                    after: data.loans.pageInfo.endCursor,
                  },
                })
              }
            }}
          >
            <RightArrow disabled={!data.loans.pageInfo.hasNextPage} />
          </PaginationButton>
        </PaginationWrapper>
      ) : null}
    </>
  )
}

export default LoanList
