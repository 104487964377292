import React from 'react'

// icons
import {
  CaretUpOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  FileOutlined,
  PhoneOutlined,
  SyncOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'

// colors
import theme from '../../styles/theme'
import Handshake from '../../icons/Handshake'

// types
import { PendingActionType } from '../../types/globalTypes'


interface Props {
  escalationLevel: number
  escalationType: PendingActionType
}

const getTitle = (actionType: PendingActionType) => {
  return actionType
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.substr(1))
    .join(' ')
}

const findIcon = (actionType: PendingActionType) => {
  switch (actionType) {
    case PendingActionType.CHANGE_TOLERANCE:
      return CaretUpOutlined

    case PendingActionType.COMMITTEE_COMMENT:
    case PendingActionType.VOTE:
      return UsergroupAddOutlined

    case PendingActionType.CONFERENCE_CALL:
      return PhoneOutlined

    case PendingActionType.DISBURSE:
      return DollarOutlined

    case PendingActionType.DOCUMENTATION:
      return FileOutlined

    case PendingActionType.RECONCILE:
      return SyncOutlined

    case PendingActionType.STALE:
      return ClockCircleOutlined

    case PendingActionType.VERIFY:
      return CheckOutlined

    case PendingActionType.SUPPORT:
      return Handshake

    default:
      return null
  }
}

const BLUE_STATES = ['VOTE', 'SIGN_OFF']

const YELLOW_STATES = ['CHANGE_TOLERANCE', 'COMMITTEE_COMMENT']

const GREEN_STATES = ['SUPPORT']

const findColor = (actionType, escalationLevel) => {
  // 3
  if (escalationLevel === 3) {
    return theme.colors.pendingActions.red
  }

  // 2
  if (escalationLevel === 2) {
    if (BLUE_STATES.includes(actionType)) {
      return theme.colors.pendingActions.blue
    }

    if (YELLOW_STATES.includes(actionType)) {
      return theme.colors.pendingActions.yellow
    }

    return theme.colors.pendingActions.red
  }

  // 1
  if (BLUE_STATES.includes(actionType)) {
    return theme.colors.pendingActions.blue
  }

  if (GREEN_STATES.includes(actionType)) {
    return theme.colors.pendingActions.green
  }

  return theme.colors.pendingActions.yellow
}

export const ActionIcon: React.FC<Props> = ({ escalationLevel, escalationType, ...other }) => {
  const CurrentIcon = findIcon(escalationType)

  if (!CurrentIcon) {
    return null
  }

  const color = findColor(escalationType, escalationLevel)
  const title = getTitle(escalationType)

  // @ts-ignore
  return <CurrentIcon style={{ color }} title={title} {...other} />
}
