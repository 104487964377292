export default message => {
  switch(message) {
    case 'Summary Report located.':
      return 'Summary Report'

    case 'LoanInventorySummary Report located.':
      return 'Loan Inventory Report'

    case 'BorrowerSummary Report located.':
      return 'Borrower Report'

    case 'BalancesSummary Report located.':
      return 'Balances Report'

    case 'CrossCollateral Report located.':
      return 'Cross Collateral Report'

      default:
        return 'Report'
  }
}
