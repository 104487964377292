import styled from 'styled-components'

export const BackButton = styled.a`
  align-items: center;
  color: ${ ({ theme }) => theme.colors.background };
  cursor: pointer;
  display: grid;
  font-size: 1.4rem;
  font-weight: 400;
  grid-template-columns: 1.4rem 1fr;
  grid-gap: 0.6rem;
`

export const Header = styled.header`
  align-items: center;
  background-color: ${ ({ theme }) => theme.colors.blue };
  display: grid;
  grid-template-columns: 0.3fr 1fr 0.3fr;
  height: 6rem;
  padding: 0 1.6rem;
`

export const Header1 = styled.h1`
  color: ${ ({ theme }) => theme.colors.background };
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
`

export const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  list-style: none;
`

export const ListItem = styled.li`
  align-items: center;
  border-bottom: 1px solid ${ ({ theme }) => theme.colors.header };
  display: grid;
  grid-gap: 2.4rem;
  grid-template-columns: 3.4rem 1fr 1.2rem;
  grid-template-rows: 5rem;
  padding: 1.2rem 3.2rem;

  span {
    display: block;
    font-size: 3.2rem;
    height: 3.4rem;
    width: 3.4rem;
  }

  p {
    font-size: 1.4rem;
  }

  figure {
    background-color: ${ ({ theme }) => theme.colors.blue };
    border-radius: 50%;
    height: 1.2rem;
    width: 1.2rem;
  }
`

export const ScrollWrapper = styled.main`
  background-color: ${ ({ theme }) => theme.colors.background };
  height: calc(100vh - 14rem);
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`

export const Wrapper = styled.div`
  background-color: ${ ({ theme }) => theme.colors.background };
  display: grid;
  grid-template-rows: 6rem 1fr;
  width: 100%;
`
