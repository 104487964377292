import React, { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/client'

import LoanList, { LoanListData } from '../loanList/LoanList'

// helpers
import useDebounce from '../../helpers/useDebounce'

const PAGE_SIZE: number = 50
const HOME_QUERY = gql`
  query HomePage(
    $searchQuery: String
    $before: String
    $after: String
    $first: PaginationAmount
    $last: PaginationAmount
    $pendingActionSortFlag: Boolean
  ) {
    loans(
      searchQuery: $searchQuery
      first: $first
      last: $last
      before: $before
      after: $after
      usePendingActionSort: $pendingActionSortFlag
    ) {
      nodes {
        id
        displayId
        borrower: refBorrower {
          name {
            fullName
          }
        }
        status
        pendingActions {
          type
          escalationLevel
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`

type AccountDetails = {
  profile: {
    name: string
  }
}

interface Props {
  clearAccount: () => void
  account: AccountDetails
  history: {
    push: (path: string) => void
  }
  match: {
    url: string
  }
  searchTerm: string
}

const HomePage: React.FC<Props> = ({ searchTerm }) => {
  const localPendingActionSortFlag = localStorage.getItem('pendingActionSortFlag') || 'true'
  const convertedPendingActionSortFlag = localPendingActionSortFlag === 'true' ? true : false
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)
  const [pageCount, setPageCount] = useState<number>(1)
  const [pendingActionSortFlag, setPendingActionSortFlag] = useState<boolean>(
    convertedPendingActionSortFlag
  )
  const [executeLoanGrab, { data, error, loading }] = useLazyQuery<LoanListData>(HOME_QUERY, {
    variables: { searchQuery: debouncedSearchTerm, first: PAGE_SIZE, pendingActionSortFlag },
  })

  useEffect(() => {
    if (searchTerm === debouncedSearchTerm) {
      setPageCount(1)
      executeLoanGrab()
    }
  }, [debouncedSearchTerm, executeLoanGrab, searchTerm, pendingActionSortFlag])

  const togglePendingSort = () => {
    localStorage.setItem(
      'pendingActionSortFlag',
      !pendingActionSortFlag === true ? 'true' : 'false'
    )
    setPendingActionSortFlag(!pendingActionSortFlag)
  }

  return (
    <LoanList
      data={data}
      error={error}
      executeLoanGrab={executeLoanGrab}
      pageCount={pageCount}
      loading={loading}
      setPageCount={setPageCount}
      setPendingActionSortFlag={togglePendingSort}
      pendingActionSortFlag={pendingActionSortFlag}
    />
  )
}

export default HomePage
