import React, { useEffect } from 'react'

import { useAuth, useAuthCallback } from '@arm/react-auth'

import { DEFAULT_ROUTE } from '../../../constants/auth'
import LoadingIndicator, { LoadingIndicatorWrapper } from '../../loadingIndicator'
import { Wrapper } from './style'

interface Props {
  history: {
    push: (path: string) => void
  }
}

export const Callback: React.FC<Props> = ({ history }) => {
  const callbackError = useAuthCallback()
  const { redirectToLogin, isAuthenticated } = useAuth()

  useEffect(() => {
    if (!callbackError) {
      const timeout = setTimeout(() => {
        history.push(DEFAULT_ROUTE)
      }, 3000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [callbackError, history])

  useEffect(() => {
    if (isAuthenticated) {
      history.push(DEFAULT_ROUTE)
    } else if (callbackError && callbackError.retry) {
      const timeout = setTimeout(() => {
        redirectToLogin()
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isAuthenticated, callbackError, redirectToLogin, history])

  if (!isAuthenticated && callbackError) {
    return (
      <Wrapper>
        <h1>We had trouble signing you in</h1>
        <p>This is usually fixed by trying again.</p>
        <pre>{callbackError.errorDescription}</pre>
        <button onClick={() => redirectToLogin()}>Try Again</button>
      </Wrapper>
    )
  }

  return (
    <LoadingIndicatorWrapper>
      <LoadingIndicator />
    </LoadingIndicatorWrapper>
  )
}

export default Callback
