// TODO | BK | Use to display install flag

export const isIos = () => {
 return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}

export default () => {
  const isInStandaloneMode = () =>
    'standalone' in window.navigator && window.navigator['standalone']

  return isIos() && isInStandaloneMode()
}
