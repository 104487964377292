import styled from 'styled-components'

export const ButtonWrap = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.primaryDark};
  border-radius: 5rem;
  color: ${({ theme }) => theme.colors.background};
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1;
  margin: 0 auto;
  outline: none;
  padding: 0.8rem 3.2rem;
  will-change: opacity;

  &:disabled {
    opacity: 0.4;
  }
`
