import React, { useState, useRef, useEffect } from 'react'
import { CheckCircleOutlined, RedoOutlined, PlusCircleFilled } from '@ant-design/icons'
import { connect } from 'react-redux'

// hooks
import usePortal from '../../helpers/use-portal'

// components
import CropInspectionRater from '../cropInspectionRater'
import TableGrid from '../tableGrid'
import Modal from '../modal'
import CloseOnFinishModal from '../closeOnFinishModal'

import {
  AcresP,
  AcresVerified,
  Box,
  Button,
  ButtonRateAll,
  InputTel,
  ModalContent,
  RateAllWrapper,
  RatingGrid,
  RowGrid,
  RowSelectWrap,
  AddNewButton,
  TableButtons,
  CancelButton,
  NextButton,
} from './style'

import { UPDATE_RATINGS } from '../../constants/cropInspections'

export type CropTableData = {
  acres: {
    acres: any
    isVerified: boolean | null
  }
  id: string
  state: string
  county: string
  percentProd: any
  landowner: string | null
  fsn: string | null
  section: string | null
  cropName: string
  cropType: string
  irrPrac: string
  cropPrac: string
  inspectedAcres: {
    defaultAcres: boolean
    value: any | null
  }
  cropRating: {
    defaultAcres: boolean
    value: number | null
  }
  image: any
}

interface Props {
  cropData: CropTableData[]
  newInspection: () => void
  questionnaireRoute: any
  history: any
  readOnly: boolean
  tableRoute: any
  ratings: any[]
  updateRatings: (newRatings: any[]) => void
  isWorkingLoan: boolean
}

const CropInspectionTable: React.FC<Props> = ({
  cropData,
  newInspection,
  questionnaireRoute,
  history,
  readOnly,
  tableRoute,
  ratings,
  updateRatings,
  isWorkingLoan,
}) => {
  const [forceCloseModal, setForceCloseModal] = useState<boolean>(false)

  type ModalData = {
    acres?: number
    id?: string
    rating?: number
    ratingAll?: boolean
  }
  // stores column data for modal
  const [currentDataInModal, setCurrentDataInModal] = useState<ModalData | null>(null)

  // input value for new acres
  const [newAcres, setNewAcres] = useState<string>('')
  // is input valid acreage
  const [isValid, setIsValid] = useState<boolean>(false)

  // table is or not rotated
  const [tableRotated, setTableRotated] = useState<boolean>(false)

  // is Row select modal open or not
  const [rowSelectOpen, setRowSelectOpen] = useState<boolean>(false)

  const [editingModalOpen, setEditingModalOpen] = useState<boolean>(false)

  // save the "rate all" value for the UI
  const [tempAllRating, setTempAllRating] = useState<number>(0)

  // portal hook
  const { closePortal, isOpen, openPortal, Portal } = usePortal({
    closeOnOutsideClick: true,
    onClose: () => {
      setCurrentDataInModal(null)
      setEditingModalOpen(false)
      setForceCloseModal(false)
      setNewAcres('')
      setTempAllRating(0)
      setIsValid(false)
      setRowSelectOpen(false)
    },
  })
  // ref for input for focusing
  const inputRef = useRef<HTMLInputElement | null>(null)

  // onchange handler for input
  const newAcresChange = ({ target }) => {
    const { value } = target
    const isNumeric = new RegExp(/^-?\d*\.?\d*$/)
    const isOnlyNumbers = isNumeric.test(value)
    setIsValid(isOnlyNumbers)
    setNewAcres(value)
  }

  // if field is verified, prefill the input
  const verifiedPrefill = (defaultAcres) => {
    if (defaultAcres || defaultAcres === 0) {
      newAcresChange({ target: { value: defaultAcres } })
    }
  }

  const allRows = [
    {
      dataKey: 'state',
      header: 'State',
    },
    {
      dataKey: 'county',
      header: 'County',
    },
    {
      dataKey: 'percentProd',
      formatValue: (val) => `${val}%`,
      header: 'Prod %',
    },
    {
      dataKey: 'landowner',
      header: 'Landowner',
    },
    {
      dataKey: 'fsn',
      header: 'FSN',
    },
    {
      dataKey: 'section',
      header: 'Section',
    },
    {
      dataKey: 'cropName',
      header: 'Crop',
    },
    {
      dataKey: 'cropType',
      header: 'Crop Type',
    },
    {
      dataKey: 'irrPrac',
      header: 'Irr Prac',
    },
    {
      dataKey: 'cropPrac',
      header: 'Crop Prac',
    },
    {
      dataKey: 'acres',
      header: 'Acres',
      renderComponent: (id, { acres, isVerified }) => (
        <AcresVerified>
          <p>{acres}</p>
          {isVerified && (
            <figure>
              <CheckCircleOutlined />
            </figure>
          )}
        </AcresVerified>
      ),
    },
    {
      dataKey: 'inspectedAcres',
      header: 'Acres Inspected',
      renderComponent: (id, { defaultAcres, value }) => (
        <AcresP
          onClick={() => {
            if (!readOnly) {
              verifiedPrefill(defaultAcres)
              setCurrentDataInModal({ acres: value, id })
              setForceCloseModal(false)
              setEditingModalOpen(true)
              openPortal()
            }
          }}
        >
          {readOnly ? value : ratings.find((dr) => dr.loanCropUnitId === id)?.inspectedAcres}
        </AcresP>
      ),
    },
    {
      dataKey: 'cropRating',
      header: 'Crop Rating',
      renderComponent: (id, { defaultAcres, value }) => (
        <div
          onClick={() => {
            if (!readOnly) {
              verifiedPrefill(defaultAcres)
              setCurrentDataInModal({ id, rating: value })
              setForceCloseModal(false)
              setEditingModalOpen(true)
              openPortal()
            }
          }}
        >
          <CropInspectionRater
            onChange={() => false}
            rating={
              readOnly
                ? !value
                  ? 0
                  : value
                : ratings.find((dr) => dr.loanCropUnitId === id)?.rating ?? 0
            }
          />
        </div>
      ),
    },
  ]

  const [selectedRows, setSelectedRows] = useState<string[]>(allRows.map(({ dataKey }) => dataKey))

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  })

  return (
    <>
      <div style={{ position: 'relative' }}>
        <TableGrid
          data={cropData}
          identifier="id"
          format={allRows.filter((row) => selectedRows.includes(row.dataKey))}
          vertical={tableRotated}
        />

        {readOnly && isWorkingLoan ? (
          <AddNewButton
            onClick={() => {
              newInspection()

              history.push(tableRoute + '/edit/table')
            }}
          >
            <PlusCircleFilled style={{ color: '#5DBC9C', fontSize: '400%' }} />
          </AddNewButton>
        ) : (
          <></>
        )}
      </div>
      {!readOnly ? (
        <RateAllWrapper>
          <ButtonRateAll
            onClick={async () => {
              setCurrentDataInModal({
                rating: 0,
                ratingAll: true,
              })
              setForceCloseModal(false)
              setEditingModalOpen(true)
              openPortal()
            }}
          >
            Rate All Fields
          </ButtonRateAll>
        </RateAllWrapper>
      ) : null}

      {readOnly ? (
        <TableButtons>
          <Button
            className="rotate"
            name="rotate-table"
            onClick={() => {
              setRowSelectOpen(!rowSelectOpen)
              openPortal()
            }}
          >
            {tableRotated ? 'Columns' : 'Rows'}
          </Button>

          <Button
            className="rotate"
            name="rotate-table"
            onClick={() => setTableRotated(!tableRotated)}
          >
            <RedoOutlined />
          </Button>
        </TableButtons>
      ) : (
        <></>
      )}
      <Portal
        allRows={allRows}
        currentDataInModal={
          currentDataInModal && currentDataInModal.id
            ? cropData.find(({ id }) => id === currentDataInModal.id)
            : currentDataInModal
        }
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      >
        {editingModalOpen ? (
          <CloseOnFinishModal
            closeModal={closePortal}
            isOpen={isOpen}
            forceClose={forceCloseModal}
            children={({ currentDataInModal }) => (
              <ModalContent>
                {!currentDataInModal.ratingAll && (
                  <RatingGrid className="three-wide">
                    <label htmlFor="acre-input">Acres</label>
                    <InputTel
                      autoComplete="false"
                      autoFill="false"
                      autoFocus
                      id="acre-input"
                      key={`acres-input-${currentDataInModal ? currentDataInModal.id : 'number'}`}
                      name="acres"
                      onChange={newAcresChange}
                      placeholder={
                        currentDataInModal &&
                        currentDataInModal.inspectedAcres &&
                        currentDataInModal.inspectedAcres.value
                          ? currentDataInModal.inspectedAcres.value
                          : 'Acres'
                      }
                      ref={inputRef}
                      type="number"
                      step="0.01"
                      value={newAcres}
                    />
                  </RatingGrid>
                )}

                <RatingGrid className={currentDataInModal.ratingAll ? '' : 'horizontal-break'}>
                  <label htmlFor="rating-input">Rating</label>
                  <CropInspectionRater
                    onChange={(newRating) => {
                      setTempAllRating(newRating)
                      setIsValid(true)
                    }}
                    rating={tempAllRating}
                  />

                  <p className="stretch">{`Setting this rating will overwrite all ratings & acreage for ${cropData[0].cropName}`}</p>
                </RatingGrid>

                <RatingGrid>
                  <p>Poor</p>
                  <CropInspectionRater rating={1} onChange={() => false} />
                  <p>Fair</p>
                  <CropInspectionRater rating={2} onChange={() => false} />
                  <p>Good</p>
                  <CropInspectionRater rating={3} onChange={() => false} />
                  <p>Very Good</p>
                  <CropInspectionRater rating={4} onChange={() => false} />
                  <p>Excellent</p>
                  <CropInspectionRater rating={5} onChange={() => false} />
                </RatingGrid>
                <RatingGrid>
                  <Button
                    className={`apply ${isValid ? 'enabled' : ''}`}
                    disabled={!isValid}
                    name="apply"
                    onClick={(event) => {
                      let currRatings = ratings

                      if (currentDataInModal.ratingAll) {
                        currRatings = currRatings.map((ciRating) => {
                          const cd = cropData.find((cd) => cd.id === ciRating.loanCropUnitId)
                          if (cd) {
                            return {
                              ...ciRating,
                              inspectedAcres: cd.acres.acres,
                              rating: tempAllRating,
                            }
                          }
                          return ciRating
                        })
                      } else {
                        currRatings = currRatings.map((ciRating) => {
                          if (ciRating.loanCropUnitId === currentDataInModal.id) {
                            return {
                              ...ciRating,
                              inspectedAcres: Number(newAcres),
                              rating: tempAllRating,
                            }
                          }
                          return ciRating
                        })
                      }

                      updateRatings(currRatings)
                      setForceCloseModal(true)
                    }}
                  >
                    Apply
                  </Button>
                </RatingGrid>
              </ModalContent>
            )}
          />
        ) : rowSelectOpen ? (
          <Modal
            closeModal={closePortal}
            isOpen={isOpen}
            children={({ allRows, selectedRows, setSelectedRows }) => (
              <ModalContent>
                <RowGrid>
                  {allRows.map(({ dataKey, header }) => {
                    const isActive = selectedRows.includes(dataKey)

                    return (
                      <RowSelectWrap key={`row-select-${dataKey}`}>
                        <Box
                          className={isActive ? 'selected' : ''}
                          onClick={() =>
                            isActive
                              ? setSelectedRows(selectedRows.filter((r) => r !== dataKey))
                              : setSelectedRows([...selectedRows, dataKey])
                          }
                        />
                        <p>{header}</p>
                      </RowSelectWrap>
                    )
                  })}
                </RowGrid>
              </ModalContent>
            )}
          />
        ) : (
          <></>
        )}
      </Portal>

      {!readOnly ? (
        <TableButtons>
          <CancelButton
            onClick={() => {
              readOnly = true
              history.push(tableRoute + '/table')
            }}
          >
            Cancel
          </CancelButton>
          <NextButton
            onClick={() => {
              history.push(questionnaireRoute)
            }}
          >
            Next
          </NextButton>
        </TableButtons>
      ) : null}
    </>
  )
}
const mapStateToProps = (state, ownProps) => {
  return { ratings: state.inspection.cropInspectionRatings, ...ownProps }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateRatings: (updatedRatings) =>
      dispatch({
        type: UPDATE_RATINGS,
        updatedRatings: updatedRatings,
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CropInspectionTable)
