import React from 'react'

import { LoanCommentIconType } from './index'

import {
  ApproveIcon,
  DeclineIcon,
  ConferenceCallIcon,
  ComitteeCommentIcon,
  PassiveCommentIcon,
  UserNoteIcon,
  WatchListIcon,
  RescindIcon,
} from './style'

interface Props {
  loanCommentType: LoanCommentIconType
  color?: string
}

export const DecideCommentIcon: React.FC<Props> = ({ color, loanCommentType }) => {
  switch (loanCommentType) {
    case LoanCommentIconType.VOTE:
      return <ComitteeCommentIcon color={color} title="Committee Comment" />
    case LoanCommentIconType.DECLINE:
      return <DeclineIcon color={color} title="Decline" />
    case LoanCommentIconType.APPROVE:
      return <ApproveIcon color={color} title="Approve" />
    case LoanCommentIconType.CONFERENCE_CALL:
      return <ConferenceCallIcon color={color} title="Conference Call" />
    case LoanCommentIconType.COMMITTEE_COMMENT:
      return <ComitteeCommentIcon color={color} title="Committee Comment" />
    case LoanCommentIconType.PASSIVE_COMMENT:
      return <PassiveCommentIcon color={color} title="Committee Passive" />
    case LoanCommentIconType.USER_NOTE:
      return <UserNoteIcon color={color} title="User Note" />
    case LoanCommentIconType.WATCH_LIST:
      return <WatchListIcon color={color} title="Watchlist" />
    case LoanCommentIconType.RESCIND:
      return <RescindIcon color={color} title="Watchlist" />

    default:
      return null
  }
}
