import React from 'react'

import { LoadingIcon } from './style'

type Props = {
  size?: string
}

export const LoadingIndicator: React.FC<Props> = ({ size }) => {
  return <LoadingIcon size={size} />
}
