export let createImageBitmap = window.createImageBitmap

if (typeof createImageBitmap === 'undefined') {
  // @ts-ignore
  createImageBitmap = async (blob) => {
    return new Promise((resolve, reject) => {
      let img = document.createElement('img')

      img.addEventListener('load', function() {
          resolve(this)
      })
      img.src = URL.createObjectURL(blob);
    })
  }
}

window.createImageBitmap = createImageBitmap
