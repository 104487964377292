import React from 'react'

// components
import { DrawerContent } from '../drawerContent/DrawerContent'

// icons
import CollapseDown from '../icons/CollapseDown'

// types
import { LoanDetailsHeader_loan } from '../../types/LoanDetailsHeader'

import { Handle, TranslationFrame, Wrapper } from './style'

interface Props {
  isLandscapeMode: boolean
  isOpen: boolean
  loanDetails?: LoanDetailsHeader_loan
  loanId?: string
  openHeight: number
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const DynamicHeader: React.FC<Props> = ({
  isLandscapeMode,
  isOpen,
  loanDetails,
  loanId,
  openHeight,
  setIsOpen,
}) => (
  <Wrapper>
    <TranslationFrame isOpen={isOpen} totalHeight={openHeight}>
      {loanId && loanDetails && (
        <DrawerContent
          loan={loanDetails}
        />
      )}
      {!isLandscapeMode && (
        <Handle name="toggle-drawer" onClick={() => setIsOpen(!isOpen)}>
          <CollapseDown color="#717171" />
        </Handle>
      )}
    </TranslationFrame>
  </Wrapper>
)
