import React, { useEffect, useState, cloneElement, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { filter } from 'graphql-anywhere'
import ReactGA from 'react-ga'

// hooks
import useLoanId from '../loanIdHook/LoanIdHook'

// components
import BottomNavigation from '../bottomNavigation'
import DynamicHeader from '../dynamicHeader'
import SearchBar from '../searchBar'
import { StaticHeader, StaticHeaderFragment } from '../staticHeader/StaticHeader'
import { DrawerContentFragment } from '../drawerContent/DrawerContent'

// styles
import { ScrollWrapper, SwipeWrapper, Wrapper } from './style'
import theme from '../../styles/theme'

// types
import { DocumentNode } from 'graphql'
import { ApolloQueryResult } from '@apollo/client'

interface queryArgs {
  id: number | string
}

interface Props {
  children: React.ReactElement
  hideBottomNav?: boolean
  history: object
  location: object
  match: {
    params: {
      loanId: string | undefined
    }
    path: string
    url: string
  }
  pageFragment: {
    fragmentName?: string | ''
    fragment?: DocumentNode | ''
  }
}

// matchMedia for iPad base model resolutions and above
const isDeviceLandscape = window.matchMedia(
  `(orientation: landscape) and (min-width: ${theme.breakpoints.device_landscape})`
)
const isDevicePortrait = window.matchMedia(
  `(orientation: portrait) and (min-width: ${theme.breakpoints.device_portrait})`
)

// TODO | BK | This is not maintainable long-term. This is NOT the way.
// 1) useRef() to calc the outerHeight of elements
// 2) use flexbox/grid, <-- I'm leaning here
const DYNAMIC_CLOSED_HEIGHT = 20
const DYNAMIC_OPEN_HEIGHT = 210

const CLOSED_HEIGHT = 123 + DYNAMIC_CLOSED_HEIGHT
const LOGO_HEADER_HEIGHT = 40
const OPEN_HEIGHT = 333

type RefetchFunction = (
  variables?:
    | {
        loanId: string
      }
    | undefined
) => Promise<ApolloQueryResult<any>>

export const RefetchPageData = React.createContext<RefetchFunction | null>(null)

const FixedNav: React.FC<Props> = ({ children, hideBottomNav = false, match, pageFragment }) => {
  const query = gql`
  query LoanDetailsHeader($loanId: ID!) {
    ${pageFragment?.fragmentName}

    loan(id: $loanId) {
      ...DrawerContentData
      ...StaticHeaderData
    }
  }
  ${pageFragment?.fragment}

  ${DrawerContentFragment}
  ${StaticHeaderFragment}
  `
  // is a device and is landscape orientation
  const [deviceLandscape, setDeviceLandscape] = useState<boolean>(isDeviceLandscape.matches)

  // is the header opened
  const [isOpen, setIsOpen] = useState<boolean>(deviceLandscape || isDevicePortrait.matches)

  // search term state
  const [searchTerm, setSearchTerm] = useState<string>('')

  const loanSelection = useLoanId()

  const { error, data, loading, refetch } = useQuery(query, {
    variables: { loanId: loanSelection },
    skip: loanSelection === '',
  })
  const errorMessage = error?.graphQLErrors[0].message

  // swipe handlers for header
  const swipeHandlers = useSwipeable({
    onSwipedDown: (eventData) => setIsOpen(true),
    onSwipedUp: (eventData) => setIsOpen(false),
  })

  // scroller wrapper height definitions
  const scrollWrapperRef = useRef<HTMLDivElement>(null)
  const [scrollHeight, setScrollHeight] = useState<number>(0)

  // calc for phone for page heights
  const { innerHeight } = window

  const footerHeight = deviceLandscape ? 0 : 56

  const mainHeight = isOpen
    ? innerHeight - OPEN_HEIGHT - footerHeight - LOGO_HEADER_HEIGHT
    : innerHeight - CLOSED_HEIGHT - footerHeight - LOGO_HEADER_HEIGHT

  useEffect(() => {
    // scroll height
    if (scrollWrapperRef && scrollWrapperRef.current) {
      setScrollHeight(scrollWrapperRef.current.clientHeight)
    }

    // orientation listener
    const updateOrientation = (event: MediaQueryListEvent) => setDeviceLandscape(event.matches)

    isDeviceLandscape.addListener(updateOrientation)

    return () => isDeviceLandscape.removeListener(updateOrientation)
  }, [isOpen])

  // Add GA for routing events
  useEffect(() => {
    // NOTE | BK | Wait til gql loads so we don't dupe pageviews
    if (process.env.NODE_ENV === 'production' && !loading) {
      ReactGA.pageview(window.location.pathname)
    }
  }, [loading])

  return (
    <RefetchPageData.Provider value={refetch}>
      <Wrapper
        hideBottomNav={hideBottomNav}
        isLandscapeMode={deviceLandscape}
        isOpen={isOpen}
        isSearch={loanSelection === ''}
      >
        <SwipeWrapper
          hideBottomNav={hideBottomNav}
          isLandscapeMode={deviceLandscape}
          {...(loanSelection && !loading ? swipeHandlers : {})}
        >
          {!loanSelection && <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />}

          {!loading && data?.loan && (
            <>
              <StaticHeader loading={loading} loan={filter(StaticHeaderFragment, data.loan)} />
              <DynamicHeader
                isLandscapeMode={deviceLandscape}
                isOpen={isOpen}
                loanDetails={filter(DrawerContentFragment, data.loan)}
                loanId={loanSelection}
                openHeight={DYNAMIC_OPEN_HEIGHT}
                setIsOpen={setIsOpen}
              />
            </>
          )}
        </SwipeWrapper>

        <ScrollWrapper
          hideBottomNav={hideBottomNav}
          isLandscapeMode={deviceLandscape}
          ref={scrollWrapperRef}
          scrollHeight={scrollHeight}
        >
          {cloneElement(children, {
            data,
            error,
            errorMessage,
            isOpen,
            loading,
            mainHeight,
            searchTerm,
          })}
        </ScrollWrapper>

        <BottomNavigation hideBottomNav={hideBottomNav} match={match} />
      </Wrapper>
    </RefetchPageData.Provider>
  )
}

export default FixedNav
