import styled from 'styled-components'

export const ControlUnderlay = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  position: absolute;
  z-index: 10;
  bottom: 0;
  height: 10rem;
  left: 0;
  width: 100%;

  > div {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    position: relative;
  }
`

export const RetakeButton = styled.button`
  background-color: transparent;
  border: 0 none;
  color: ${ ({ theme }) => theme.colors.yellow };
  font-size: 1.8rem;
  outline: none;
`

export const UploadButton = styled(RetakeButton)``

export const Wrapper = styled.div`
  background-color: ${ ({ theme }) => theme.colors.header };
  height: 100vh;
  position: relative;
  width: 100vw;
`
