import React, { useContext } from 'react'
import { Switch, Route } from 'react-router'
import { useQuery, useMutation } from '@apollo/client'
import { CropInspectionSaveInput } from '../../types/globalTypes'

//hooks
import useCropCode from '../../components/cropCodeHook/cropCodeHook'
import useLoanId from '../../components/loanIdHook/LoanIdHook'

// queries
import {
  SAVE_CROP_INSPECTION,
  GET_LATEST_CROP_INSPECTION,
  GET_LANDLORD_RELATIONSHIP,
} from './queries'

// components
import CropInspectionBlock from '../../components/cropInspectionBlock'
import CropInspectionQuestionnaire from '../../components/cropInspectionQuestionnaire'
import CropInspectionTable from '../../components/cropInspectionTable'
import ErrorCard from '../../components/errorCard'
import LoadingIndicator, { LoadingIndicatorWrapper } from '../../components/loadingIndicator'

// types
import { cropInspectionPageData } from '../../types/cropInspectionPageData'
import { GetLatestCropInspection } from '../../types/GetLatestCropInspection'
import { CropTableData } from '../../components/cropInspectionTable/CropInspectionTable'

// data
import { CropInspectionBlockContainer, TabsContainer, Tab, Wrapper } from './style'
import CropInspectionHistory from '../../components/cropInspectionHistory'
import store from '../../store'
import { NEW_INSPECTION } from '../../constants/cropInspections'
import { RefetchPageData } from '../../components/fixedNav/FixedNav'

interface Props {
  error: boolean
  loading: boolean
  data: cropInspectionPageData
  history: {
    push: (url: string) => void
  }
  match: {
    path: string
    params: {
      cropId: string
    }
  }
}

const QUESTION_PATH = '/loans/:loanId/crop-inspection/:cropCode/questions'
const QUESTION_PATH_EDIT = '/loans/:loanId/crop-inspection/:cropCode/edit/questions'
const TABLE_PATH = '/loans/:loanId/crop-inspection/:cropCode/table'
const TABLE_PATH_EDIT = '/loans/:loanId/crop-inspection/:cropCode/edit/table'
let typeOfLoan: string | undefined = ''

export const CropInspectionPage: React.FC<Props> = ({ data, error, history, loading, match }) => {
  // state to figure out if all questions were answered
  const activeCropCode = useCropCode()
  const loanId = useLoanId()
  const [saveCropInspectionMutation] = useMutation(SAVE_CROP_INSPECTION)
  const {
    data: cropInspectionData,
    error: cropInspectionError,
    loading: cropInspectionLoading,
    refetch: cropInspectionRefetch,
  } = useQuery<GetLatestCropInspection>(GET_LATEST_CROP_INSPECTION, {
    variables: { loanId },
    fetchPolicy: 'network-only',
  })

  let map = new Array()
  try {
    cropInspectionData?.loan?.latestCropInspection?.cropCodeGroups
      ?.find((ccg) => ccg.cropCode === activeCropCode)
      ?.loanCropUnits.forEach((lcu) => map.push(lcu.farm.id))
  } catch {}

  const queryResult = useQuery<any>(GET_LANDLORD_RELATIONSHIP, {
    variables: { input: map[0] },
    fetchPolicy: 'network-only',
  })

  const queryData = queryResult.data

  const refetch = useContext(RefetchPageData)

  const loanCropUnits = cropInspectionData?.loan?.latestCropInspection?.cropCodeGroups?.find(
    (ccg) => ccg.cropCode === activeCropCode
  )?.loanCropUnits

  if (!loanCropUnits) {
    return (
      <LoadingIndicatorWrapper>
        <LoadingIndicator />
      </LoadingIndicatorWrapper>
    )
  }

  let landowner = ''
  let comparisonVal: any

  if (queryData !== undefined && queryData.landlordsByFarmId?.length > 0) {
    comparisonVal = queryData.landlordsByFarmId[0]

    loanCropUnits.forEach((lcu) => {
      if (lcu.farm.id === comparisonVal.farmId) {
        landowner = lcu.farm.landowner ?? ''
      }
    })
  }

  const saveCropInspection = async (saveCropInspectionInput: CropInspectionSaveInput) => {
    await saveCropInspectionMutation({
      variables: {
        input: saveCropInspectionInput,
      },
    })

    if (refetch) await refetch()
    await cropInspectionRefetch()
  }

  const newInspection = () => {
    const cropCodeGroups = cropInspectionData?.loan?.latestCropInspection?.cropCodeGroups

    if (!cropCodeGroups) return

    const ratingTemplate = cropCodeGroups.reduce((acc, cur) => {
      cur.loanCropUnits.forEach((lcu) => {
        acc.push({
          inspectedAcres: null,
          loanCropUnitId: lcu.id,
          rating: 0,
          cropCode: cur.cropCode,
        })
      })
      return acc
    }, [] as any[])

    const questionTemplate = cropCodeGroups.reduce((acc, cur) => {
      cropInspectionData?.cropInspectionQuestions.forEach((q) => {
        acc.push({
          answer: null,
          cropCode: cur.cropCode,
          refQuestionId: q.id,
        })
      })
      return acc
    }, [] as any[])

    store.dispatch({
      type: NEW_INSPECTION,
      loanNumber: Number(data?.loan?.displayId.split('-')[0]),
      ratingTemplate: ratingTemplate,
      questionTemplate: questionTemplate,
      numCrops: cropCodeGroups.length,
    })
  }

  const GetCropContent = (cropInspectionData, cropInspectionError, cropInspectionLoading) => {
    typeOfLoan = cropInspectionData?.loan?.status
    if (cropInspectionError) {
      return <ErrorCard />
    }

    if (cropInspectionLoading) {
      return (
        <LoadingIndicatorWrapper>
          <LoadingIndicator />
        </LoadingIndicatorWrapper>
      )
    }

    // Data for the table to consume
    // NOTE | WK | I tried to keep it flat but complicated with verified status
    const cropTableData: CropTableData[] = loanCropUnits.map(
      (lcu): CropTableData => ({
        acres: {
          acres: lcu.acres,
          isVerified: lcu.isAipVerified || lcu.isFsaVerified,
        },
        id: lcu.id,
        state: lcu.farm.state.abbreviation,
        county: lcu.farm.county.name,
        percentProd: lcu.farm.percentProd.percentage,
        landowner: process.env.landlordRentUpdate ? landowner : lcu.farm.landowner,
        fsn: lcu.farm.fsn,
        section: lcu.farm.section,
        cropName: lcu.crop.cropName,
        cropType: lcu.crop.cropType,
        irrPrac: lcu.crop.irrPrac,
        cropPrac: lcu.crop.cropPrac,
        inspectedAcres: {
          defaultAcres: (lcu.isAipVerified || lcu.isFsaVerified) && lcu.acres,
          value: lcu.inspectedAcres,
        },
        cropRating: {
          defaultAcres: (lcu.isAipVerified || lcu.isFsaVerified) && lcu.acres,
          value: lcu.cropRating,
        },
        image: null,
      })
    )

    return (
      <>
        {match.path === TABLE_PATH || match.path === QUESTION_PATH ? (
          <TabsContainer>
            <Tab
              active={match.path === TABLE_PATH}
              onClick={() =>
                history.push(`/loans/${data.loan?.id}/crop-inspection/${activeCropCode}/table`)
              }
            >
              Fields
            </Tab>
            <Tab
              active={match.path === QUESTION_PATH}
              onClick={() =>
                history.push(`/loans/${data.loan?.id}/crop-inspection/${activeCropCode}/questions`)
              }
            >
              Questions
            </Tab>
          </TabsContainer>
        ) : (
          <></>
        )}
        <Switch>
          <Route
            path={QUESTION_PATH}
            render={() => (
              <div className="crop_inspection_page_questions_wrapper">
                <CropInspectionQuestionnaire
                  latestQuestionsAndAnswers={
                    cropInspectionData?.loan?.latestCropInspection?.cropCodeGroups?.find(
                      (el) => el.cropCode === activeCropCode
                    )?.questionWithAnswerList
                  }
                  questionList={cropInspectionData?.cropInspectionQuestions}
                  cropCode={activeCropCode}
                  saveCropInspection={saveCropInspection}
                  readOnly={true}
                  history={history}
                  redirect={`/loans/${data.loan?.id}/crop-inspection/${activeCropCode}/table`}
                />
              </div>
            )}
          />
          <Route
            path={QUESTION_PATH_EDIT}
            render={() => (
              <div className="crop_inspection_page_questions_wrapper">
                <CropInspectionQuestionnaire
                  latestQuestionsAndAnswers={undefined}
                  questionList={cropInspectionData?.cropInspectionQuestions}
                  cropCode={activeCropCode}
                  saveCropInspection={saveCropInspection}
                  readOnly={false}
                  history={history}
                  redirect={`/loans/${data.loan?.id}/crop-inspection/${activeCropCode}/table`}
                />
              </div>
            )}
          />
          <Route
            path={TABLE_PATH}
            render={() => (
              <CropInspectionTable
                cropData={cropTableData}
                newInspection={newInspection}
                questionnaireRoute={`/loans/${data.loan?.id}/crop-inspection/${activeCropCode}/edit/questions`}
                tableRoute={`/loans/${data.loan?.id}/crop-inspection/${activeCropCode}`}
                history={history}
                readOnly={true}
                isWorkingLoan={typeOfLoan === 'WORKING'}
              />
            )}
          />
          <Route
            path={TABLE_PATH_EDIT}
            render={() => (
              <CropInspectionTable
                cropData={cropTableData}
                newInspection={newInspection}
                questionnaireRoute={`/loans/${data.loan?.id}/crop-inspection/${activeCropCode}/edit/questions`}
                tableRoute={`/loans/${data.loan?.id}/crop-inspection/${activeCropCode}`}
                history={history}
                readOnly={false}
                isWorkingLoan={typeOfLoan === 'WORKING'}
              />
            )}
          />
        </Switch>
      </>
    )
  }

  if (error) {
    return <ErrorCard />
  }

  if (loading) {
    return (
      <LoadingIndicatorWrapper>
        <LoadingIndicator />
      </LoadingIndicatorWrapper>
    )
  }

  return (
    <Wrapper>
      {match.path === TABLE_PATH || match.path === QUESTION_PATH ? (
        <CropInspectionBlockContainer>
          {data?.loan && (
            <CropInspectionBlock
              activeCropCode={activeCropCode}
              cropData={data.loan.crops}
              loanId={data.loan.id}
              condenseSize={false}
              redirectTo={'table'}
            />
          )}
        </CropInspectionBlockContainer>
      ) : match.path === TABLE_PATH_EDIT ? (
        <CropInspectionBlockContainer>
          {data?.loan && (
            <CropInspectionBlock
              activeCropCode={activeCropCode}
              cropData={data.loan.crops}
              loanId={data.loan.id}
              condenseSize={true}
              redirectTo={'edit/table'}
            />
          )}
        </CropInspectionBlockContainer>
      ) : (
        <CropInspectionBlockContainer>
          {data?.loan && (
            <CropInspectionBlock
              activeCropCode={activeCropCode}
              cropData={data.loan.crops}
              loanId={data.loan.id}
              condenseSize={true}
              redirectTo={'edit/questions'}
            />
          )}
        </CropInspectionBlockContainer>
      )}

      {GetCropContent(cropInspectionData, cropInspectionError, cropInspectionLoading)}

      {match.path === TABLE_PATH || match.path === QUESTION_PATH ? (
        cropInspectionData && (
          <CropInspectionHistory
            cropInspectionHistories={cropInspectionData.loan?.historicalCropInspections || []}
            error={cropInspectionError}
            loading={cropInspectionLoading}
          />
        )
      ) : (
        <></>
      )}
    </Wrapper>
  )
}
