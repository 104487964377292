import styled from 'styled-components'

export const ModalWrapper = styled.div`
  backface-visibility: hidden;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 4px;
  box-shadow: 0 0 12px 0 rgba(0, 0,0, 0.3);
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.3);
  transition: opacity 150ms cubic-bezier(0, 0, 0, 0),
              transform 150ms cubic-bezier(0.37, 0.2, 0.39, 1.26);
  will-change: opacity, transform;
  z-index: 150;
  width: 85%;
  overflow: scroll;

  ${({ animate }) =>
    animate
      ? `
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    `
      : ``}

  .close {
    font-size: 1.6rem;
    opacity: 0.5;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    will-change: opacity

    &:hover {
      opacity: 1;
    }
  }
`

export const Underlay = styled.div`
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 150ms cubic-bezier(0, 0, 0, 0);
  width: 100%;
  will-change: opacity;
  z-index: 140;
  overflow: scroll;

  ${({ animate }) =>
    animate
      ? `
      opacity: 1;
    `
      : ``}
`
