import styled from 'styled-components'

export const Nav = styled.nav`
  align-items: stretch;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  margin: 0 auto;
  max-width: 50rem;

  .bottom-link {
    display: block;
    height: 5.6rem;
    position: relative;
    text-decoration: none;
  }

  p {
    bottom: 0.4rem;
    color: ${({ theme }) => theme.colors.background};
    font-size: 1.2rem;
    opacity: 0;
    position: absolute;
    text-align: center;
    text-decoration: none;
    transition: opacity 150ms cubic-bezier(0, 0, 0, 0);
    width: 100%;
    will-change: opacity;
  }

  .anticon {
    display: block;
    margin: 0 auto;
    opacity: 0.6;
    padding-top: 0.4rem;
    transition: opacity 150ms cubic-bezier(0, 0, 0, 0), transform 150ms cubic-bezier(0, 0, 0, 0);
    transform: translateY(10px);
    will-change: opacity, transform;
  }

  svg {
    height: 2.6rem;
    fill: ${({ theme }) => theme.colors.background};
    width: 2.6rem;
  }

  .bottom-link.active {
    background-color: ${ ({ theme }) => theme.colors.footerActive };
  }

  .bottom-link.active p {
    opacity: 1;
  }

  .bottom-link.active .anticon {
    opacity: 1;
    transform: translateY(0px);
  }

  @media only screen and (min-device-width: ${({ theme }) =>
      theme.breakpoints.device_landscape}) and (orientation: landscape) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
    row-gap: 2.4rem;
  }
`

export const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.footer};
  bottom: 0;
  height: ${({ standAlone }) => (standAlone ? '7.6rem' : '5.6rem')};
  left: 0;
  padding-bottom: ${({ standAlone }) => (standAlone ? '2rem' : '0')};
  position: fixed;
  right: 0;
  width: 100%;

  @media only screen and (min-device-width: ${({ theme }) =>
      theme.breakpoints.device_landscape}) and (orientation: landscape) {
    height: 100%;
    position: static;
    grid-column: 1/2;
    grid-row: 1/3;
    width: 5.6rem;
  }
`
