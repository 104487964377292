import React, { useState, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { v4 as uuid } from 'uuid'

// styles
import { CommentsWrapper, InputWrapper, ScrollSpacer, Wrapper } from './style'

// components
import DecideComment from '../../components/decideComment'
import DecideMessagebar from '../../components/decideMessagebar'
import EmptyCard from '../../components/emptyCard'
import ErrorCard from '../../components/errorCard'
import LoadingIndicator, { LoadingIndicatorWrapper } from '../../components/loadingIndicator'

// constants
import { EMPTY_LOAN_COMMENTS_MESSAGE } from '../../constants/messages'

// helper
import isStandAlone, { isIos } from '../../helpers/isStandAloneMode'

// queries
import { GET_LOAN_COMMENTS, MARK_COMMENT_READ, ADD_COMMENT } from './queries'

// types
import { DecidePageData } from '../../types/DecidePageData'
import {
  LoanStatus,
  CommitteeVote,
  LoanCommentType,
  LoanCommentEmoji,
} from '../../types/globalTypes'

interface Props {
  data: DecidePageData
  error: boolean
  isOpen: boolean
  loading: boolean
}

const DecidePage: React.FC<Props> = ({ data, error, isOpen, loading }) => {
  const commentRef = useRef<any>(null)

  const [newCommentMessage, setNewCommentMessage] = useState<string>('')
  const [newCommentVote, setNewCommentVote] = useState<CommitteeVote | null>(null)
  const [newCommentType, setNewCommentType] = useState<LoanCommentType>(
    LoanCommentType.PASSIVE_COMMENT
  )

  // state holding if user selected/deselected the comment/vote across all loans
  const [isCrossCollateralApplied, setIsCrossCollateralApplied] = useState<boolean>(false)

  const [markCommentRead] = useMutation(MARK_COMMENT_READ)
  const [addCommentMutation] = useMutation(ADD_COMMENT)

  if (loading && !data?.loan)
    return (
      <LoadingIndicatorWrapper>
        <LoadingIndicator />
      </LoadingIndicatorWrapper>
    )

  if (error) return <ErrorCard />

  const addComment = () => {
    commentRef.current.scrollIntoView()
    setNewCommentMessage('')

    const commentInput = {
      message: newCommentMessage,
      type: newCommentType,
      vote: newCommentVote,
    }

    addCommentMutation({
      variables: {
        applyToCrossCollateral: isCrossCollateralApplied,
        loanId: data.loan?.id,
        comment: commentInput,
      },
      optimisticResponse: getLoanCommentAddOptimisticResponse({
        newCommentMessage,
        newCommentType,
        newCommentVote,
        data,
      }),
      update: (proxy, updateData) => {
        proxy.writeQuery({
          query: GET_LOAN_COMMENTS,
          variables: { loanId: data.loan?.id },
          data: {
            __typename: 'Query',
            me: { ...data.me },
            loan: { ...updateData.data.loanCommentAdd.loan },
          },
        })
      },
    })
  }

  const updateCommentAsRead = (targetComment) => {
    markCommentRead({
      variables: {
        commentId: targetComment.id,
      },
      optimisticResponse: getLoanCommentReadOptimisticResponse({ targetComment, data }),
      update: (proxy, updateData) => {
        proxy.writeQuery({
          query: GET_LOAN_COMMENTS,
          variables: { loanId: updateData.data.loanCommentRead.loan.id },
          data: {
            __typename: 'Query',
            me: { ...data.me },
            loan: { ...updateData.data.loanCommentRead.loan },
          },
        })
      },
    })
  }

  const manageNewCommentType = (type: LoanCommentType, vote: CommitteeVote | null = null) => {
    setNewCommentType(type)
    setNewCommentVote(vote)
  }

  const loan = data.loan
  if (!loan) {
    return null
  }

  const {
    comments = [],
    isCrossCollateral,
    status,
    viewerIsCommitteeMember,
    viewerCommitteeMember,
  } = loan

  const commentsDisabled = status !== LoanStatus.RECOMMENDED
  const viewerVote = viewerCommitteeMember?.vote

  return (
    <Wrapper isOpen={isOpen} standAlone={isStandAlone()} isIos={isIos()}>
      <CommentsWrapper>
        {comments.length === 0 ? (
          <EmptyCard message={EMPTY_LOAN_COMMENTS_MESSAGE} />
        ) : (
          comments.map((comment) => (
            <DecideComment
              borrowerFullName={
                data.loan && data.loan.borrower ? data.loan.borrower.name.fullName : ''
              }
              comment={comment}
              key={comment.id}
              loanFullId={data.loan ? data.loan.displayId : ''}
              needsRead={
                [LoanCommentType.COMMITTEE_COMMENT].includes(comment.type) ||
                ([LoanCommentType.VOTE].includes(comment.type) && !!comment.userMessage)
              }
              updateCommentAsRead={updateCommentAsRead}
            />
          ))
        )}
        <ScrollSpacer isOpen={isOpen} />
        <div className="scroll_target" ref={commentRef} />
      </CommentsWrapper>
      <InputWrapper>
        <DecideMessagebar
          addComment={addComment}
          disabled={commentsDisabled}
          isCrossCollateral={isCrossCollateralApplied}
          isCrossCollateralLoan={isCrossCollateral}
          newCommentMessage={newCommentMessage}
          newCommentType={newCommentType}
          newCommentVote={newCommentVote}
          setIsCrossCollateral={setIsCrossCollateralApplied}
          setNewCommentMessage={setNewCommentMessage}
          setNewCommentType={manageNewCommentType}
          viewerIsCommitteeMember={viewerIsCommitteeMember}
          viewerVote={viewerVote}
        />
      </InputWrapper>
    </Wrapper>
  )
}

function getLoanCommentAddOptimisticResponse({
  newCommentMessage,
  newCommentType,
  newCommentVote,
  data,
}: {
  newCommentMessage: string
  newCommentType: LoanCommentType
  newCommentVote: CommitteeVote | null
  data: any
}) {
  const date = new Date()

  return {
    __typename: 'Mutation',
    loanCommentAdd: {
      __typename: 'LoanMutationPayload',
      me: {
        ...data.me,
      },
      loan: {
        ...data.loan,
        viewerCommitteeMember: {
          __typename: 'LoanCommitteeMember',
          vote: newCommentVote
            ? newCommentVote
            : data.loan.viewerCommitteeMember
            ? data.loan.viewerCommitteeMember.vote
            : null,
        },
        comments: [
          // @ts-ignore
          ...data.loan.comments,
          {
            date: {
              day: date.getDate(),
              hour: date.getHours(),
              minute: date.getMinutes(),
              month: date.getMonth() + 1,
              second: 0,
              year: date.getFullYear(),
              __typename: 'SynthesisDateTime',
            },
            emoji: getOptimisticEmoji(newCommentVote),
            id: uuid(),
            userMessage: newCommentMessage,
            systemMessage: getOptimisticSystemMessage(newCommentVote),
            hasRead: true,
            type: newCommentType,
            author: {
              name: {
                fullName: data.me.name.fullName,
                __typename: 'PersonName',
              },
              __typename: 'User',
            },
            __typename: 'LoanComment',
          },
        ],
      },
    },
  }
}

function getOptimisticEmoji(newCommentVote) {
  switch (newCommentVote) {
    case CommitteeVote.APPROVED:
      return LoanCommentEmoji.THUMBS_UP
    case CommitteeVote.DECLINED:
      return LoanCommentEmoji.THUMBS_DOWN
    case CommitteeVote.NOT_VOTED:
      return LoanCommentEmoji.UNDO
    default:
      return null
  }
}

function getOptimisticSystemMessage(newCommentVote) {
  switch (newCommentVote) {
    case CommitteeVote.APPROVED:
      return 'Approved'
    case CommitteeVote.DECLINED:
      return 'Declined'
    case CommitteeVote.NOT_VOTED:
      return 'Vote Rescinded'
    default:
      return null
  }
}

function getLoanCommentReadOptimisticResponse({ targetComment, data }) {
  return {
    __typename: 'Mutation',
    loanCommentRead: {
      __typename: 'LoanMutationPayload',
      me: { ...data.me },
      loan: {
        __typename: 'Loan',
        ...data.loan,
        comments:
          data.loan &&
          data.loan.comments.map((comment) =>
            comment.id === targetComment.id ? { ...targetComment, hasRead: true } : comment
          ),
      },
    },
  }
}

export default DecidePage
