import React from 'react'

import { PlayCircleOutlined } from '@ant-design/icons'

import { Icon } from './style'

interface Props {

}

export const MediaItems: React.FC<Props> = () => {
  return (
    <Icon>
      <a
        href="https://web.microsoftstream.com/channel/16b19ac5-056b-4a39-b94d-b4e8d6fd5dc9"
        rel="noopener noreferrer"
        target="_blank"
        title="View ARM's channel"
      >
        <PlayCircleOutlined />
      </a>
    </Icon>
  )
}
