import React, { useState, createRef } from 'react'
import { Link } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import { v4 as uuid } from 'uuid'
import { useMutation } from '@apollo/client'

// components
import ErrorCard from '../../components/errorCard'
import LoadingIndicator, { LoadingIndicatorWrapper } from '../../components/loadingIndicator'
import CropInspectionBlock from '../../components/cropInspectionBlock'

// icons
import Circle from '../../icons/Circle'
import Triangle from '../../icons/Triangle'
import { LikeFilled, FilePdfOutlined, ExportOutlined } from '@ant-design/icons'

// helpers
import reportInterpreter from '../../helpers/reportInterpreter'

// types
import { LoanDetailsData } from '../../types/LoanDetailsData'

// queries
import { LOAN_EXCEPTION_SUPPORT, GET_LOAN_DETAILS } from './queries'

//styles
import theme from '../../styles/theme'
import {
  Wrapper,
  Comment,
  CommentText,
  CommentsWrapper,
  Detail,
  DetailActionIcon,
  DetailIcon,
  DetailText,
  DetailsWrapper,
  ExceptionActionContainer,
} from './style'
import EmptyCard from '../../components/emptyCard'
import { EMPTY_LOAN_OFFICER_COMMENT_MESSAGE, EMPTY_DETAILS_MESSAGE } from '../../constants/messages'

interface Props {
  error: boolean
  errorMessage: string
  loading: boolean
  data: LoanDetailsData
  mainHeight: number
}

const MAX_HEIGHT = 100
const MIN_HEIGHT = 0

const DetailsPage: React.FC<Props> = ({ error, errorMessage, data, loading, mainHeight }) => {
  const [commentsHeight, setCommentsHeight] = useState(MAX_HEIGHT / 2)
  const [detailsHeight, setDetailsHeight] = useState(MAX_HEIGHT / 2)

  // state to prevent double downloading
  const [isGeneratingPDF, setIsGeneratingPDF] = useState<boolean>(false)

  const [supportExceptionMutation] = useMutation(LOAN_EXCEPTION_SUPPORT)

  const CommentSwipeHandlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      if (detailsHeight === MIN_HEIGHT) return
      setCommentsHeight(MIN_HEIGHT)
      setDetailsHeight(MAX_HEIGHT)
    },
    onSwipedRight: (eventData) => {
      setDetailsHeight(MAX_HEIGHT / 2)
      setCommentsHeight(MAX_HEIGHT / 2)
    },
  })

  const DetailsSwipeHandlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      if (commentsHeight === MIN_HEIGHT) return
      setDetailsHeight(MIN_HEIGHT)
      setCommentsHeight(MAX_HEIGHT)
    },
    onSwipedRight: (eventData) => {
      setDetailsHeight(MAX_HEIGHT / 2)
      setCommentsHeight(MAX_HEIGHT / 2)
    },
  })

  const supportException = (exception) => {
    if (!exception.viewerCanSupport || exception.isSupported) return false

    const exceptionId = exception.id

    supportExceptionMutation({
      variables: {
        exceptionId: exceptionId,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        loanExceptionSupport: {
          __typename: 'LoanMutationPayload',
          loan: {
            __typename: 'Loan',
            ...data.loan,
            exceptions: data.loan?.exceptions.map((exception) =>
              exception?.id !== exceptionId ? exception : { ...exception, isSupported: true }
            ),
          },
        },
      },
      update: (proxy, updatedData) => {
        proxy.writeQuery({
          query: GET_LOAN_DETAILS,
          variables: {
            loanId: data.loan?.id,
          },
          data: {
            __typename: 'Query',
            loan: {
              ...updatedData.data.loanExceptionSupport.loan,
            },
          },
        })
      },
    })
  }

  if (error) return <ErrorCard message={errorMessage} />

  if (loading || !data.loan)
    return (
      <LoadingIndicatorWrapper>
        <LoadingIndicator />
      </LoadingIndicatorWrapper>
    )

  const {
    loan: {
      loanOfficerComment,
      exceptions = [],
      // @ts-ignore
      validations = [],
      // @ts-ignore
      crops = [],
      isLatest,
    },
  } = data

  // filter out empty states for reports
  const filteredReports = data.reportsByLoanId.filter((rep) => rep.reportFile !== null)

  // create enough ref for the map loop
  const linkRefs = Array(filteredReports.length).fill(createRef())

  // generate and serve up a pdf
  const generatePdf = (index) => {
    // lockdown in case user clicks multiple times
    setIsGeneratingPDF(true)

    const rep = filteredReports[index]

    // create byte array from array of ints
    // @ts-ignore
    const bytes = new Uint8Array(rep.reportFile)
    // create a window.URL readable blob
    const blob = new Blob([bytes], { type: 'application/pdf' })

    // create a download link
    const anchor = document.createElement('a')
    // set URL
    anchor.href = window.URL.createObjectURL(blob)
    // Set filename
    anchor.download = `${reportInterpreter(rep.payloadMessage)}-${data.loan?.displayId}`
    // simulate downloan action
    anchor.click()

    return setIsGeneratingPDF(false)
  }

  return (
    <Wrapper mainHeight={mainHeight}>
      <CommentsWrapper {...CommentSwipeHandlers} commentsHeight={commentsHeight}>
        {!loanOfficerComment ? (
          <EmptyCard message={EMPTY_LOAN_OFFICER_COMMENT_MESSAGE} />
        ) : (
          <Comment>
            <CommentText>{loanOfficerComment}</CommentText>
          </Comment>
        )}
      </CommentsWrapper>
      <DetailsWrapper {...DetailsSwipeHandlers} detailsHeight={detailsHeight}>
        {exceptions.length === 0 && validations.length === 0 && filteredReports.length === 0 ? (
          <EmptyCard message={EMPTY_DETAILS_MESSAGE} />
        ) : (
          <>
            {validations.map((validation) => (
              <Detail key={uuid()}>
                <DetailIcon>
                  <Circle size={12} color={theme.colors.orange} />
                </DetailIcon>
                <DetailText validation>{validation}</DetailText>
              </Detail>
            ))}

            {exceptions.map(
              (exception) =>
                exception && (
                  <Detail key={exception.id}>
                    <DetailIcon>
                      <Triangle size={12} color={theme.colors.red} />
                    </DetailIcon>
                    <DetailText exception>{exception.message}</DetailText>

                    <DetailActionIcon>
                      <ExceptionActionContainer
                        authorized={exception.viewerCanSupport}
                        supported={exception.isSupported}
                        onClick={() => supportException(exception)}
                      >
                        <LikeFilled style={{ color: '#fff' }} />
                      </ExceptionActionContainer>
                    </DetailActionIcon>
                  </Detail>
                )
            )}

            {process.env.TEST_PHOTOS === 'true' && (
              <Detail>
                <Link to="/photos">Preview photo</Link>
              </Detail>
            )}

            {process.env.SHOW_DETAIL_REPORTS === 'true' &&
              filteredReports.map((report, index) =>
                report.reportFile ? (
                  <Detail
                    download
                    key={report.payloadMessage}
                    onClick={(event) => {
                      event.preventDefault()

                      return isGeneratingPDF ? false : generatePdf(index)
                    }}
                    ref={linkRefs[index]}
                  >
                    <DetailIcon>
                      <FilePdfOutlined style={{ fontSize: '25px', color: theme.colors.red }} />
                    </DetailIcon>
                    <DetailText>{reportInterpreter(report.payloadMessage)}</DetailText>
                    <DetailActionIcon>
                      <ExportOutlined
                        rotate={-90}
                        style={{ fontSize: '20px', color: theme.colors.grey }}
                      />
                    </DetailActionIcon>
                  </Detail>
                ) : null
              )}

            {process.env.SHOW_CROP_INSPECTION === 'true' && crops.length >= 0 && isLatest && (
              <CropInspectionBlock
                loanId={data.loan.id}
                cropData={crops}
                condenseSize={false}
                redirectTo={'table'}
              />
            )}
          </>
        )}
      </DetailsWrapper>
    </Wrapper>
  )
}

export default DetailsPage
