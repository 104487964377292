import React from 'react'

import { EmojiWrapper } from './style'

import { LoanCommentEmoji } from '../../types/globalTypes'

const EmojiMap = {
  [LoanCommentEmoji.DIAMOND]: '\u{1F48E}',
  [LoanCommentEmoji.DART_BOARD]: '\u{1F3AF}',
  [LoanCommentEmoji.SILENCED_BELL]: '\u{1F515}',
  [LoanCommentEmoji.FOCUS_ARROWS]: '\u{1F4CD}',
  [LoanCommentEmoji.MAGNIFYING_GLASS]: '\u{1F50D}',
  [LoanCommentEmoji.FAST_SPEDOMETER]: '\u{1F4C8}',
  [LoanCommentEmoji.THERMOMETER]: '\u{1F321}',
  [LoanCommentEmoji.SQUIRREL]: '\u{1F43F}',
  [LoanCommentEmoji.TOILET]: '\u{1F6BD}',
  [LoanCommentEmoji.THUMBS_UP]: '\u{1F44D}',
  [LoanCommentEmoji.THUMBS_DOWN]: '\u{1F44E}',
  [LoanCommentEmoji.QUESTION_MARK]: '\u{2753}',
  [LoanCommentEmoji.UNDO]: '\u{21A9}',
}

interface Props {
  label?: string
  emojiType: LoanCommentEmoji
}

const Emoji: React.FC<Props> = ({ label, emojiType }) => (
  <EmojiWrapper>{EmojiMap[emojiType]}</EmojiWrapper>
)
export default Emoji
