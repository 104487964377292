import styled, { css } from 'styled-components'

export const IconButton = styled.button`
  background: none;
  border: 0;
  color: inherit;
  width: auto;
  outline: none;

  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 300ms ease;

  ${({ color = 'dark' }: { color: 'dark' | 'light' }) =>
    color === 'dark'
      ? css`
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        `
      : css`
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        `}
`
