import React from 'react'
import { GetLatestCropInspection_loan_historicalCropInspections } from '../../types/GetLatestCropInspection'
import { ErrorCard } from '../errorCard/ErrorCard'
import LoadingIndicator, { LoadingIndicatorWrapper } from '../loadingIndicator'
import getFormattedCropInspectionDate from '../../helpers/getFormattedCropInspectionDate';
import {
  Comment,
  CommentAuthorName,
  CommentDate,
  CommentHeader,
  CommentOutline,
  CommentText,
  NoCommentText,
} from './styles'

interface Props {
  cropInspectionHistories: GetLatestCropInspection_loan_historicalCropInspections[]
  error: any
  loading: boolean
}

export const CropInspectionHistory: React.FC<Props> = ({
  cropInspectionHistories,
  error,
  loading,
}) => {
  if (error) {
    return <ErrorCard />
  }

  if (loading) {
    return (
      <LoadingIndicatorWrapper>
        <LoadingIndicator />
      </LoadingIndicatorWrapper>
    )
  }

  if (!cropInspectionHistories.length) {
    return (
        <Comment>
          <CommentOutline>
            <NoCommentText>No crop inspection comments.</NoCommentText>
          </CommentOutline>
        </Comment>
    )
  }

  return (
    <>
      {cropInspectionHistories.map((cih) => {
        return cih.comment !== null ? (
          <Comment key={cih.id}>
            <CommentHeader>
              <CommentAuthorName>{cih.cropInspector.name.fullName}</CommentAuthorName>
              <CommentDate>{getFormattedCropInspectionDate(cih.saveDate)}</CommentDate>
            </CommentHeader>
            <CommentOutline>
              <CommentText>{cih.comment}</CommentText>
            </CommentOutline>
          </Comment>
        ) : null
      })}
    </>
  )
}


