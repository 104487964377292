import styled from 'styled-components'

export const Handle = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.stateDisabled};
  border: 0 none;
  bottom: 0;
  display: grid;
  height: 2.1rem;
  justify-content: center;
  left: 0;
  margin: 0;
  outline: none;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 2;

  svg {
    display: block;
    margin: 0 auto;
  }
`

export const TranslationFrame = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  height: ${({ totalHeight }) => `${totalHeight}px`};
  position: relative;
  transform: translateY(
    ${({ isOpen, totalHeight }) => (isOpen ? '0px' : `-${totalHeight - 21}px`)}
  );
  width: 100%;
`

export const Wrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.header};
  overflow: hidden;
  transition: height 180ms cubic-bezier(0.4, 0.01, 1, 1);
  width: 100%;
`
