import styled from 'styled-components'

export const Image = styled.img`
  display: block;
  height: auto;
  margin: 3.2rem auto 1.8rem;
  max-width: 100%;
`

export const Wrapper = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.colors.header};
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 6.6rem 6.6rem 4rem;
  justify-content: center;
`
