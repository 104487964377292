import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'

export const LoadingIndicatorWrapper = styled.div`
  width: 100%;
  padding: ${({ size }) => (size === 'sml' ? '2rem' : '5rem')};
  display: grid;
  justify-content: center;
`

export const LoadingIcon = styled(LoadingOutlined)`
  font-size: ${({ size }) => (size === 'sml' ? '2rem' : '5rem')};
`
