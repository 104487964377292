import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: repeat(${ ({ count }) => count }, 16px);
  grid-template-rows: 24px;
  justify-content: flex-end;
`
