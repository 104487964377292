import styled from 'styled-components'

// import isStandAloneMode from '../../helpers/isStandAloneMode'

// const standAlone = isStandAloneMode()

export const Video = styled.video`
  display: block;
  max-width: 100vh;
  max-width: 100vw;
  pointer-events: none;

  &::-webkit-media-controls-start-playback-button {
    display: none;
  }
`

export const Wrapper = styled.div`
  background-color: ${ ({ theme }) => theme.colors.header };
  height: 100vh;
  position: relative;
  width: 100vw;
`
