import React, { useEffect, useState } from 'react'

import { CloseOutlined } from '@ant-design/icons'

import { ModalWrapper, Underlay } from './style'

interface Props {
  children: any
  closeModal(): void
  isOpen: boolean
}

export const Modal: React.FC<Props> = ({
  children,
  closeModal,
  isOpen,
  ...restProps
}) => {
  const [ animate, setAnimate ] = useState<boolean>(false)

  useEffect(() => {
    if (!isOpen) {
      return setAnimate(false)
    }

    setTimeout(() => setAnimate(isOpen), 10)
  }, [
    isOpen,
  ])

  return (
    <>
      <Underlay animate={animate} onClick={closeModal} />
      <ModalWrapper animate={animate}>
        <CloseOutlined
          className="close"
          onClick={closeModal}
        />
        {children(restProps)}
      </ModalWrapper>
    </>
  )
}
