import gql from 'graphql-tag'

export const LoanDetailsFields = gql`
  fragment loanFields on Loan {
    id
    displayId
    loanOfficerComment
    exceptions {
      id
      message
      viewerCanSupport
      isSupported
    }
    crops: commodities {
      cropName
      cropCode
      inspectionProgress
      inspectionComplete
    }
    pendingActions {
      escalationLevel
      id
      type
    }
    validations
    isLatest
  }
`

const GET_REPORTS = gql`
  fragment reportsBlob on Query {
    reportsByLoanId(loanId: $loanId) {
      reportFile
      payloadMessage
    }
  }
`

export const LoanDetailsFragment = gql`
  fragment LoanDetailsData on Query {
    loan(id: $loanId) {
      ...loanFields
    }
    reportsByLoanId(loanId: $loanId) {
      reportFile
      payloadMessage
    }
  }
  ${LoanDetailsFields}
`

export const GET_LOAN_DETAILS = gql`
  query LoanDetails($loanId: ID!) {
    loan(id: $loanId) {
      ...loanFields
    }
    ...reportsBlob
  }
  ${LoanDetailsFields}
  ${GET_REPORTS}
`

export const LOAN_EXCEPTION_SUPPORT = gql`
  mutation loanExceptionSupport($exceptionId: ID!) {
    loanExceptionSupport(exceptionId: $exceptionId) {
      loan {
        ...loanFields
      }
    }
  }
  ${LoanDetailsFields}
`
