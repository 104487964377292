import React from 'react'
import gql from 'graphql-tag'

// icons
import { CompassOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons'

// components
import PendingActions from '../pendingActions'
import StarRating from '../starRating'
import LoadingIndicator, { LoadingIndicatorWrapper } from '../loadingIndicator'

// types
import { StaticHeaderData } from '../../types/StaticHeaderData'
import { LoanType } from '../../types/globalTypes'

import {
  BottomBar,
  FarmingBlock,
  FarmingBlocks,
  Header,
  ItalicHeader,
  Location,
  SelectPrompt,
  StaticHeaders,
  Subheader,
  TopBar,
  Wrapper,
} from './style'

export const StaticHeaderFragment = gql`
  fragment StaticHeaderData on Loan {
    borrower: refBorrower {
      name {
        fullName(format: LAST_NAME_FIRST)
      }
    }
    borrowerRating
    displayId
    distributor {
      name
    }
    farmer {
      city
      name {
        fullName(format: LAST_NAME_FIRST)
      }
      stateAbbreviation
      yearsArming
      yearsFarming
    }
    isWithin47Miles
    loanOfficer {
      name {
        fullName
      }
    }
    office {
      city
      stateAbbreviation
    }
    pendingActions {
      escalationLevel
      id
      type
    }
    type
  }
`

interface Props {
  loading: boolean
  loan: StaticHeaderData
}

type Name = {
  fullName: string
}

const sanitizeLoanType = (str: string) => {
  switch(str) {
    case LoanType.AG_INPUT:
      return 'AG Input'

    case LoanType.AG_PRO:
      return 'AG Pro'

    case LoanType.ALL_IN:
      return 'All In'
  }
}

export const StaticHeader: React.FC<Props> = ({ loading, loan }) => {
  if (!loan) {
    return loading ? (
      <LoadingIndicatorWrapper size="sml">
        <LoadingIndicator size="sml" />
      </LoadingIndicatorWrapper>
    ) : (
      <SelectPrompt>
        <p>Please select a loan</p>
      </SelectPrompt>
    )
  }

  const armYears = loan.farmer ? loan.farmer.yearsArming : '-'
  const farmYears = loan.farmer ? loan.farmer.yearsFarming : '-'

  return (
    <Wrapper>
      <TopBar>
        <div />
        <PendingActions pendingActions={loan.pendingActions ? loan.pendingActions : []} />
      </TopBar>
      <BottomBar>
        <StaticHeaders>
          <UserOutlined />
          <Header>{loan.borrower ? loan.borrower.name.fullName : '-'}</Header>

          <UsergroupAddOutlined />
          <Subheader>{loan.farmer ? loan.farmer.name.fullName : '-'}</Subheader>

          <CompassOutlined style={{ opacity: loan.isWithin47Miles ? '0.5' : '1' }} />
          <Location>{`${loan.farmer?.city ? loan.farmer.city : '-'  }, ${loan.farmer?.stateAbbreviation ? loan.farmer.stateAbbreviation : '-'}`}</Location>

          <ItalicHeader>{`${loan.displayId}; ${ sanitizeLoanType(loan.type) } ${
            loan.distributor ? `(${loan.distributor.name})` : ''
          }`}</ItalicHeader>
          <ItalicHeader>
            {loan.office && `${loan.office.city}, ${loan.office.stateAbbreviation}; `}
            {loan.loanOfficer ? loan.loanOfficer.name.fullName : '-'}
          </ItalicHeader>
        </StaticHeaders>
        <div>
          <StarRating outOf={5} rating={loan.borrowerRating || 0} />
          <FarmingBlocks>
            <FarmingBlock header>FARMing</FarmingBlock>
            <FarmingBlock header>ARMing</FarmingBlock>
            <FarmingBlock>{`${farmYears} Yrs`}</FarmingBlock>
            <FarmingBlock>{`${armYears} Yrs`}</FarmingBlock>
          </FarmingBlocks>
        </div>
      </BottomBar>
    </Wrapper>
  )
}
