import styled from 'styled-components'

export const Grid = styled.div`
  display: grid;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  grid-auto-flow: column dense;
  grid-template-columns: ${({ dataCount }) => `10rem repeat(${dataCount}, 13rem)`};
  grid-template-rows: ${({ headerCount }) => `repeat(${headerCount}, 1fr)`};

  &.vertical {
    grid-auto-flow: row dense;
    grid-template-rows: ${({ dataCount }) => `repeat(${1 + dataCount}, 1fr)`};
    grid-template-columns: ${({ headerCount }) => `repeat(${headerCount}, 13rem)`};
  }
`

export const GridCell = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.textPrimary};
  display: grid;
  font-size: 1.2rem;
  height: 3.5rem;
  min-width: 13rem;
  padding: 0.2rem 0.6rem;
`

export const Heading = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.table.headerBackground};
  color: ${({ theme }) => theme.colors.background};
  display: grid;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.heavy};
  height: 3.5rem;
  padding: 0.6rem;
  width: 10rem;

  &.vertical {
    width: auto;
  }

  &.large {
    font-size: 1rem;
  }
`
