import styled from 'styled-components'
import { Link } from 'react-router-dom'

const BAR_ANIMATION_DURATION = 2

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
`

export const BarContainer = styled.div`
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: 15fr 80fr 5fr;
  grid-auto-flow: column;
  align-items: center;
  background-color: ${({ theme, active }) => (active ? theme.colors.greyLight : 'none')};
  padding: 0.5rem 1rem;
`

export const BarTitle = styled.span`
  font-size: 1.2rem;
  color: ${({ active, theme }) => (active ? theme.colors.textPrimary : theme.colors.lightText)};
  font-weight: ${({ active, theme }) => (active ? theme.fontWeight.heavy : 'inherit')};
`

export const BlockTitle = styled.div`
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.heavy};
`

export const ErrorMessage = styled.div`
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
`

export const Bar = styled.div`
  background-color: ${({ theme }) => theme.colors.green};
  height: 1rem;
  width: 100%;
  will-change: transform;
  transition: transform ${BAR_ANIMATION_DURATION}s;
  transform-origin: left center;
  transform: scaleX(${({ width }) => width});

  &.temporary {
    background-color: ${({ theme }) => theme.colors.grey};
  }
`

export const CropLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: #000;
`

export const BarTrack = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.grey};
  position: relative;
  width: 100%;
`

export const CompleteIconContainer = styled.div`
  height: 100%;
  display: grid;
  align-items: center;
  will-change: opacity;
  transition: opacity 1s;
  transition-delay: ${BAR_ANIMATION_DURATION}s;
  opacity: ${({ show }) => (show ? 1 : 0)};
  margin-left: 0.25rem;
`

export const CondensedBlockTitle = styled.div`
  padding: 0.5rem;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.heavy};
  width: auto;
`

export const CondensedCropLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: #000;
`

export const CondensedBarContainer = styled.div`
  display: inline-grid;
  grid-gap: 1rem;
  grid-template-columns: auto;
  grid-auto-flow: column;
  background-color: ${({ theme, active }) => (active ? theme.colors.greyLight : 'none')};
  padding: 0.5rem 1rem;
`

export const CondensedBarTitle = styled.span`
  font-size: 1.2rem;
  color: ${({ active, theme }) => (active ? theme.colors.textPrimary : theme.colors.lightText)};
  font-weight: ${({ active, theme }) => (active ? theme.fontWeight.heavy : 'inherit')};
`

export const CondensedWrapper = styled.div`
  display: flex;
`

export const Select = styled.select`
  width: 150%;
  margin-top: 3px;
  margin-right: 3px;
  margin-left: 1px;
  margin-bottom: 1px;
  text-overflow: ellipsis;
`
