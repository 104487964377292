import React from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'

// pages
import BalancesPage, { BalancesFragment } from './pages/balancesPage'
import DecidePage, { DecidePageFragment } from './pages/decidePage'
import DetailsPage, { LoanDetailsFragment } from './pages/detailsPage'
import HistoryPage, { HistoryFragment } from './pages/historyPage'
import HomePage from './pages/homePage'
import LoginPage from './pages/loginPage'
import PhotosPage from './pages/photosPage'
import CropInspectionPage, { CropInspectionFragment } from './pages/cropInspectionPage'

// components
import Header from './components/header'
import MediaPage from './components/mediaPage'
import FixedNav from './components/fixedNav'
import Callback from './components/auth/callback'
import LogoutSuccess from './components/auth/logoutSuccess'
import { LoanIdProvider } from './components/loanIdHook/LoanIdHook'
import { CropCodeProvider } from './components/cropCodeHook/cropCodeHook'
import { AuthGuard } from '@arm/react-auth'

const { NODE_ENV, SHOW_MEDIA, TEST_PHOTOS } = process.env

// Add GA to router
const history = createBrowserHistory()
if (NODE_ENV === 'production') {
  history.listen((location) => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  })
}

const Routes: React.FC<{}> = () => {
  return (
    <Router history={history}>
      <Header />
      <Switch>
        {/* Default Route */}
        <Route path="/" exact render={() => <Redirect to="/loans" />} />

        {/* Login route */}
        <Route path="/login" exact render={(routeProps) => <LoginPage {...routeProps} />} />

        {/* OAuth callback */}
        <Route path="/auth/callback" exact render={(routeProps) => <Callback {...routeProps} />} />

        <Route path="/auth/logout/success" component={LogoutSuccess} />

        <AuthGuard>
          <Route
            exact
            path="/loans"
            render={(routeProps) => (
              <FixedNav
                hideBottomNav
                pageFragment={{
                  fragmentName: '',
                  fragment: '',
                }}
                {...routeProps}
              >
                <HomePage {...routeProps} />
              </FixedNav>
            )}
          />

          {/* Loan details */}
          <Route
            exact
            path="/loans/:loanId/details"
            render={(routeProps) => (
              <LoanIdProvider value={routeProps.match.params.loanId}>
                <FixedNav
                  pageFragment={{
                    fragmentName: '...LoanDetailsData',
                    fragment: LoanDetailsFragment,
                  }}
                  {...routeProps}
                >
                  <DetailsPage {...routeProps} />
                </FixedNav>
              </LoanIdProvider>
            )}
          />

          {/* Loan balances */}
          <Route
            exact
            path="/loans/:loanId/balances"
            render={(routeProps) => (
              <LoanIdProvider value={routeProps.match.params.loanId}>
                <FixedNav
                  pageFragment={{
                    fragmentName: '...BalancesData',
                    fragment: BalancesFragment,
                  }}
                  {...routeProps}
                >
                  <BalancesPage {...routeProps} />
                </FixedNav>
              </LoanIdProvider>
            )}
          />

          {/* Loan history */}
          <Route
            exact
            path="/loans/:loanId/history"
            render={(routeProps) => (
              <LoanIdProvider value={routeProps.match.params.loanId}>
                <FixedNav
                  pageFragment={{
                    fragmentName: '...HistoryData',
                    fragment: HistoryFragment,
                  }}
                  {...routeProps}
                >
                  <HistoryPage {...routeProps} />
                </FixedNav>
              </LoanIdProvider>
            )}
          />

          {/* Loan decide */}
          <Route
            exact
            path="/loans/:loanId/decide"
            render={(routeProps) => (
              <LoanIdProvider value={routeProps.match.params.loanId}>
                <FixedNav
                  pageFragment={{
                    fragmentName: '...DecidePageData',
                    fragment: DecidePageFragment,
                  }}
                  {...routeProps}
                >
                  <DecidePage {...routeProps} />
                </FixedNav>
              </LoanIdProvider>
            )}
          />

          {process.env.SHOW_CROP_INSPECTION === 'true' && (
            <Route
              path={[
                '/loans/:loanId/crop-inspection/:cropCode/questions',
                '/loans/:loanId/crop-inspection/:cropCode/table',
                '/loans/:loanId/crop-inspection/:cropCode/edit/table',
                '/loans/:loanId/crop-inspection/:cropCode/edit/questions',
              ]}
              render={(routeProps) => (
                <LoanIdProvider value={routeProps.match.params.loanId}>
                  <CropCodeProvider value={routeProps.match.params.cropCode}>
                    <FixedNav
                      pageFragment={{
                        fragmentName: '...cropInspectionPageData',
                        fragment: CropInspectionFragment,
                      }}
                      {...routeProps}
                    >
                      <CropInspectionPage {...routeProps} />
                    </FixedNav>
                  </CropCodeProvider>
                </LoanIdProvider>
              )}
            />
          )}

          {SHOW_MEDIA === 'true' && (
            <Route
              exact
              path="/media/:videoId"
              render={(routeProps) => <MediaPage {...routeProps} />}
            />
          )}

          {TEST_PHOTOS === 'true' && (
            <Route exact path="/photos" render={(routeProps) => <PhotosPage {...routeProps} />} />
          )}
        </AuthGuard>
      </Switch>
    </Router>
  )
}

export default Routes
