import React, { useEffect } from 'react'
import { useAuth } from '@arm/react-auth'
import { Wrapper } from './style'

export const LogoutSuccess: React.FC = () => {
  const { redirectToLogin } = useAuth()

  useEffect(() => {
    localStorage.clear()
    sessionStorage.clear()
  }, [])

  return (
    <Wrapper>
      <h1>Logged out</h1>
      <p>We hope to see you again soon!</p>
      <button onClick={() => redirectToLogin({ loginSuccessRedirectUri: '/' })}>Log Back In</button>
    </Wrapper>
  )
}
