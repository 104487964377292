import React, { useState } from 'react'

import SearchIcon from '../../icons/SearchIcon'

import {
  IconWrapper,
  SearchInput,
  Wrapper,
} from './style'

interface Props {
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
}

export const SearchBar: React.FC<Props> = ({
  searchTerm,
  setSearchTerm,
}) => {
  const [ focused, setFocus ] = useState<boolean>(false)

  return (
    <Wrapper>
      <IconWrapper focused={focused}>
        <SearchIcon />
      </IconWrapper>
      <SearchInput
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        onChange={event => setSearchTerm(event.target.value)}
        placeholder="Search Loans"
        type="search"
        value={searchTerm}
      />
    </Wrapper>
  )
}
