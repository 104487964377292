import React, { useRef, useEffect, useState } from 'react'

// helpers
import currencyFormat from '../../helpers/currencyFormat'
import resizeListener from '../../helpers/resizeListener'


import {
  max,
  scaleLinear,
  stack,
  stackOrderNone,
} from 'd3'


import theme from '../../styles/theme'
import {
  BarLabel,
  Label,
  Legend,
  LegendColor,
  LegendEntry,
  RiskPercentage,
  Wrapper,
} from './style'

const COLORS = theme.colors.commitmentGrid
const LEGEND_KEY = [
  {
    color: COLORS[0],
    label: 'ARM Commit',
  }, {
    color: COLORS[1],
    label: 'Dist Commit',
  }, {
    color: COLORS[2],
    label: 'Risk',
  },
]
const BAR_HEIGHT = 17

interface Props {
  commitments: {
    armCommitment: number
    distributorCommitment: number
  }
  totalRiskAmount: number
  totalRiskPercent: number
}

export const CommitmentRiskChart: React.FC<Props> = ({ commitments, totalRiskAmount, totalRiskPercent }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const svgRef = useRef<SVGSVGElement>(null)
  const dimensions = resizeListener(wrapperRef)

  const [ stageWidth, setStageWidth ] = useState<number>(0)

  useEffect(() => {
    // @ts-ignore
    const { width } = dimensions || wrapperRef.current.getBoundingClientRect()

    setStageWidth(width)
  }, [
    dimensions,
  ])

  const formattedData = [{
    'domainKey': 1,
    totalRiskAmount,
    ...commitments,
  }]

  // stacks / layers
  const stackGenerator = stack()
    .keys([ 'armCommitment', 'distributorCommitment', 'totalRiskAmount' ])
    .order(stackOrderNone)
  const layers = stackGenerator(formattedData)
  const extent = [
    0,
    max(layers, layer => max(layer, sequence => sequence[1])),
  ]

  // scales
  const xScale = scaleLinear()
    .domain(extent)
    .range([0, stageWidth])

  // position of risk percentage label
  const riskLayer = layers.filter(layer => layer.key === 'totalRiskAmount')[0]
  const riskLayerCoords = riskLayer[0] || [0, 0]

  // risk percent calcs
  const endOfBar = xScale(riskLayerCoords[1]) + 6

  return (
    <Wrapper ref={wrapperRef}>
      <svg height={BAR_HEIGHT * 3} ref={svgRef}>
        {layers.map((layer, index) => {
          const sequence = layer[0]

          if (!sequence || sequence[0] === sequence[1]) return null

          // bar calcs
          const isRiskBar = layer.key === 'totalRiskAmount'
          const xPos = xScale(sequence[0])
          const yPos = isRiskBar
            ? BAR_HEIGHT * 2
            : BAR_HEIGHT
          const height = BAR_HEIGHT
          const width = xScale(sequence[1]) - xScale(sequence[0])

          // labels calcs
          const isInsideBar = width > 66
          const centroidX = width / 2 + xPos
          const centroidY = isRiskBar
            ? isInsideBar
              ? BAR_HEIGHT * 3 - BAR_HEIGHT / 2
              : BAR_HEIGHT * 2 - BAR_HEIGHT / 2
            : isInsideBar
              ? BAR_HEIGHT * 2 - BAR_HEIGHT / 2
              : BAR_HEIGHT * 3 - BAR_HEIGHT / 2
          const fill = isRiskBar || !isInsideBar
            ? '#000'
            : '#fff'
          const textAnchor = isRiskBar
            ? 'left'
            : 'middle'

          return (
            <g key={layer.key}>
              <rect
                fill={COLORS[index]}
                height={height}
                width={Math.abs(width)}
                x={width < 0
                  ? xPos + width
                  : xPos
                }
                y={yPos}
              />
              <BarLabel
                dy="3"
                fill={fill}
                textAnchor={textAnchor}
                x={centroidX}
                y={centroidY}
              >
                {currencyFormat(sequence.data[layer.key], true)}
              </BarLabel>
            </g>
          )
        })}

        <RiskPercentage
          dy="13"
          x={endOfBar}
          y={BAR_HEIGHT * 2}
        >
          {`${ totalRiskPercent.toFixed(1) }%`}
        </RiskPercentage>
      </svg>

      <Legend>
        {LEGEND_KEY.map(key => (
          <LegendEntry key={key.label}>
            <LegendColor color={key.color} />
            <Label>{key.label}</Label>
          </LegendEntry>
        ))}
      </Legend>
    </Wrapper>
  )
}
