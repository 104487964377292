import React from 'react'

import { StarFilled } from '@ant-design/icons'

import theme from '../../styles/theme'
import { Wrapper } from './style'

interface StarProps {
  color: string
}

interface Props {
  outOf: number
  rating: number
}

export const StarRating: React.FC<Props> = ({ outOf, rating }) => (
  <Wrapper outOf={outOf}>
    {Array(outOf)
      .fill('')
      .map((_, index) => (
        <div
          key={`star-rating-${index}`}
          style={{ color: index < rating ? theme.colors.primary : theme.colors.stateDisabled }}
        >
          <StarFilled />
        </div>
      ))}
  </Wrapper>
)
