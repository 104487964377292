import React from 'react'

// component
import { LeafIcon } from '../icons/LeafIcon'

import {
  Wrapper
} from './style'
import theme from '../../styles/theme'

export const CropInspectionRater: React.FC<{ rating: number | undefined, onChange: (val: number) => void }> = ({ rating, onChange }) => {
  const numberOfPositive = rating
    ? Array(rating).fill('')
    : []

  const numberOfNegative = rating === 5
      ? []
      : Array(5 - numberOfPositive.length).fill('')

  return (
    <Wrapper>
      {numberOfPositive.map((_, i) => (
        <div key={`leaf-${i}`} onClick={() => { onChange(i + 1) }}>
          <LeafIcon color={theme.colors.green} size="1.5rem"/>
        </div>
      ))}
      {numberOfNegative.map((_, i) => (
        <div key={`leaf-${i + numberOfPositive.length}`} onClick={() => { onChange(numberOfPositive.length + i + 1) }}>
          <LeafIcon color={theme.colors.greyLight} size="1.5rem"/>
        </div>
      ))}
    </Wrapper>
  )
}
