import { useEffect, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'


export default ref => {
  const [ dimensions, setDimensions ] = useState(null)

  useEffect(() => {
    const observeTarget = ref.current

    const rsObserver = new ResizeObserver(currentListeners => {
      currentListeners.forEach(listener => {
        // @ts-ignore
        setDimensions(listener.contentRect)
      })
    })

    rsObserver.observe(observeTarget)

    // cleanup listeners
    return () => {
      rsObserver.unobserve(observeTarget)
    }
  }, [
    ref,
  ])


  return dimensions
}
