import React, { useRef, useState, Dispatch, SetStateAction } from 'react'

// components
import DecideCommentIcon, { LoanCommentIconType } from '.././decideCommentIcon'
import DecideMessagebarButton from '../decideMessagebarButton'

// types
import { CommitteeVote, LoanCommentType } from '../../types/globalTypes'

//styles
import theme from '../../styles/theme'
import {
  AlertBanner,
  CheckBox,
  CrossCollateralInput,
  Messagebar,
  MessagebarInput,
  MessagebarInputContainer,
  Iconbar,
  IconGroup,
  SendArrow,
  SendArrowCircle,
} from './style'

// types and interfaces
interface Props {
  disabled: boolean
  isCrossCollateral: boolean
  isCrossCollateralLoan: boolean
  newCommentType: any
  setNewCommentType: (type: LoanCommentType, vote?: CommitteeVote) => void
  addComment: () => void
  setIsCrossCollateral: Dispatch<SetStateAction<boolean>>
  setNewCommentMessage: (text: string) => void
  newCommentMessage: string
  newCommentVote: CommitteeVote | null
  viewerIsCommitteeMember: boolean
  viewerVote: CommitteeVote | undefined
}

export const DecideMessagebar: React.FC<Props> = ({
  addComment,
  disabled,
  isCrossCollateral,
  isCrossCollateralLoan,
  newCommentMessage,
  newCommentType,
  newCommentVote,
  setIsCrossCollateral,
  setNewCommentMessage,
  setNewCommentType,
  viewerIsCommitteeMember,
  viewerVote = CommitteeVote.NOT_VOTED,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const input = useRef<HTMLInputElement>(null)

  // hide/show for option to apply cross collaterally
  const crossCollateralShown = LoanCommentType.VOTE === newCommentType && isCrossCollateralLoan

  const focusInput = () => {
    if (input.current) {
      input.current.focus()
      setIsFocused(true)
    }
  }

  const onButtonFocus = () => {
    focusInput()
  }

  const onBlur = () => {
    setIsFocused(false)
  }

  const denyCurrentAction =
    [
      LoanCommentType.VOTE,
      LoanCommentType.COMMITTEE_COMMENT,
      LoanCommentType.CONFERENCE_CALL,
    ].includes(newCommentType) && !viewerIsCommitteeMember

  return (
    <>
      {disabled && (
        <AlertBanner>
          <span>The loan must be in the recommended state to make a comment</span>
        </AlertBanner>
      )}
      {denyCurrentAction && (
        <AlertBanner>
          <span>You must be a committee member to make this type of comment</span>
        </AlertBanner>
      )}
      {viewerVote !== CommitteeVote.NOT_VOTED && (
        <AlertBanner success>
          <span>Your vote has been recorded!</span>
        </AlertBanner>
      )}
      <Messagebar>
        <Iconbar>
          <IconGroup>
            {viewerVote === CommitteeVote.NOT_VOTED || viewerVote === CommitteeVote.APPROVED ? (
              <DecideMessagebarButton
                onFocus={onButtonFocus}
                active={
                  newCommentType === LoanCommentType.VOTE &&
                  newCommentVote === CommitteeVote.APPROVED
                }
                disabled={viewerVote === CommitteeVote.APPROVED || disabled}
                onClick={() => setNewCommentType(LoanCommentType.VOTE, CommitteeVote.APPROVED)}
                activeColor={theme.colors.green}
                Icon={
                  <DecideCommentIcon color="#fff" loanCommentType={LoanCommentIconType.APPROVE} />
                }
              />
            ) : (
              <DecideMessagebarButton
                onFocus={onButtonFocus}
                active={
                  newCommentType === LoanCommentType.VOTE &&
                  newCommentVote === CommitteeVote.NOT_VOTED
                }
                disabled={disabled}
                onClick={() => setNewCommentType(LoanCommentType.VOTE, CommitteeVote.NOT_VOTED)}
                activeColor={theme.colors.blue}
                Icon={
                  <DecideCommentIcon color="#fff" loanCommentType={LoanCommentIconType.RESCIND} />
                }
              />
            )}

            {viewerVote === CommitteeVote.NOT_VOTED || viewerVote === CommitteeVote.DECLINED ? (
              <DecideMessagebarButton
                onFocus={onButtonFocus}
                active={
                  newCommentType === LoanCommentType.VOTE &&
                  newCommentVote === CommitteeVote.DECLINED
                }
                disabled={viewerVote === CommitteeVote.DECLINED || disabled}
                onClick={() => setNewCommentType(LoanCommentType.VOTE, CommitteeVote.DECLINED)}
                activeColor={theme.colors.red}
                Icon={
                  <DecideCommentIcon color="#fff" loanCommentType={LoanCommentIconType.DECLINE} />
                }
              />
            ) : (
              <DecideMessagebarButton
                onFocus={onButtonFocus}
                active={
                  newCommentType === LoanCommentType.VOTE &&
                  newCommentVote === CommitteeVote.NOT_VOTED
                }
                disabled={disabled}
                onClick={() => setNewCommentType(LoanCommentType.VOTE, CommitteeVote.NOT_VOTED)}
                activeColor={theme.colors.blue}
                Icon={
                  <DecideCommentIcon color="#fff" loanCommentType={LoanCommentIconType.RESCIND} />
                }
              />
            )}
          </IconGroup>

          <IconGroup>
            <DecideMessagebarButton
              onFocus={onButtonFocus}
              active={LoanCommentType.CONFERENCE_CALL === newCommentType}
              disabled={disabled}
              onClick={() => {
                setIsCrossCollateral(false)
                setNewCommentType(LoanCommentType.CONFERENCE_CALL)
              }}
              activeColor={theme.colors.red}
              Icon={
                <DecideCommentIcon
                  color="#fff"
                  loanCommentType={LoanCommentIconType.CONFERENCE_CALL}
                />
              }
            />

            <DecideMessagebarButton
              onFocus={onButtonFocus}
              active={LoanCommentType.COMMITTEE_COMMENT === newCommentType}
              disabled={disabled}
              onClick={() => {
                setIsCrossCollateral(false)
                setNewCommentType(LoanCommentType.COMMITTEE_COMMENT)
              }}
              activeColor={theme.colors.yellow}
              Icon={
                <DecideCommentIcon
                  color="#fff"
                  loanCommentType={LoanCommentIconType.COMMITTEE_COMMENT}
                />
              }
            />

            <DecideMessagebarButton
              onFocus={onButtonFocus}
              active={LoanCommentType.WATCH_LIST === newCommentType}
              disabled={disabled}
              onClick={() => {
                setIsCrossCollateral(false)
                setNewCommentType(LoanCommentType.WATCH_LIST)
              }}
              activeColor={theme.colors.red}
              Icon={
                <DecideCommentIcon color="#fff" loanCommentType={LoanCommentIconType.WATCH_LIST} />
              }
            />

            <DecideMessagebarButton
              onFocus={onButtonFocus}
              active={LoanCommentType.PASSIVE_COMMENT === newCommentType}
              disabled={disabled}
              onClick={() => {
                setIsCrossCollateral(false)
                setNewCommentType(LoanCommentType.PASSIVE_COMMENT)
              }}
              activeColor={theme.colors.turquoise}
              Icon={
                <DecideCommentIcon
                  color="#fff"
                  loanCommentType={LoanCommentIconType.PASSIVE_COMMENT}
                />
              }
            />

            <DecideMessagebarButton
              onFocus={onButtonFocus}
              active={LoanCommentType.USER_NOTE === newCommentType}
              disabled={disabled}
              onClick={() => {
                setIsCrossCollateral(false)
                setNewCommentType(LoanCommentType.USER_NOTE)
              }}
              activeColor={theme.colors.yellow}
              Icon={
                <DecideCommentIcon color="#fff" loanCommentType={LoanCommentIconType.USER_NOTE} />
              }
            />
          </IconGroup>
        </Iconbar>

        <MessagebarInputContainer onClick={focusInput} isFocused={isFocused}>
          <MessagebarInput
            ref={input}
            disabled={disabled}
            onBlur={onBlur}
            placeholder="type your message..."
            onChange={(e) => {
              if (isCrossCollateralLoan) {
                setIsCrossCollateral(
                  LoanCommentType.VOTE === newCommentType && e.target.value.length > 0
                )
              }

              return setNewCommentMessage(e.target.value)
            }}
            value={newCommentMessage}
          />
          <SendArrowCircle
            onClick={() => !disabled && !denyCurrentAction && addComment()}
            disabled={disabled || denyCurrentAction}
            hasCharacters={input.current && input.current.value.length > 0}
          >
            <SendArrow />
          </SendArrowCircle>
        </MessagebarInputContainer>
      </Messagebar>

      <CrossCollateralInput className={crossCollateralShown ? 'shown' : ''}>
        <CheckBox
          checked={isCrossCollateral}
          className={isCrossCollateral ? 'selected' : ''}
          id="cross-collateral"
          onChange={() => {
            setIsCrossCollateral(!isCrossCollateral)
          }}
          type="checkbox"
        />

        <label htmlFor="cross-collateral">Apply to all collateralized loans</label>
      </CrossCollateralInput>
    </>
  )
}
