import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'

// Router
import Routes from '../../Routes'

// Components
import ErrorBoudary from '../../components/errorBoundary'
import { AuthorizedApolloProvider } from '../../auth/AuthorizedApolloProvider'

// styles
import theme from '../../styles/theme'

// store
import store from '../../store'
import { Main, GlobalStyle } from './style'
import { AuthWrapper } from '../../auth/AuthWrapper'

const App = () => (
  <div className="app">
    <AuthWrapper>
      <AuthorizedApolloProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ErrorBoudary>
              <GlobalStyle />
              <Main>
                <Routes />
              </Main>
            </ErrorBoudary>
          </ThemeProvider>
        </Provider>
      </AuthorizedApolloProvider>
    </AuthWrapper>
  </div>
)

export default App
