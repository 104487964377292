import styled from 'styled-components'

export const PercentageDisplay = styled.text`
  fill: ${ ({ theme }) => theme.colors.grey };
  font-size: 1rem;
`

export const Wrapper = styled.div`
  grid-column: 1/5;
  margin-top: 0.8rem;
`
