import React, { useRef, useEffect, useState } from 'react'

import resizeListener from '../../helpers/resizeListener'

import {
  max,
  scaleLinear,
  stack,
  stackOrderNone,
} from 'd3'

import theme from '../../styles/theme'
import { PercentageDisplay, Wrapper } from './style'

const BAR_HEIGHT = 17
const COLORS = theme.colors.riskChart

interface Props {
  armDiscountedRiskPercent: number
  estimatedReturnPercent: number
  returnScale: Array<number>
  targetReturn: number
}


export const RiskChart: React.FC<Props> = ({
  armDiscountedRiskPercent,
  estimatedReturnPercent,
  returnScale,
  targetReturn,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const svgRef = useRef<SVGSVGElement>(null)
  const dimensions = resizeListener(wrapperRef)

  const [ stageWidth, setStageWidth ] = useState<number>(0)

  useEffect(() => {
    // @ts-ignore
    const { width } = dimensions || wrapperRef.current.getBoundingClientRect()

    setStageWidth(width)
  }, [
    dimensions,
  ])

  // need multi-dimensional for .stack()
  // TODO | BK | Format this properly in graphql
  const filteredData = returnScale.filter(num => num !== 0)
  const formattedData = [{
    'domainKey': 1,
    'left': filteredData[0],
    'midLeft': filteredData[1],
    'midRight': filteredData[2],
    'right': filteredData[3],
  }]

  // stacks / layers
  const stackGenerator = stack()
    .keys(['left', 'midLeft', 'midRight', 'right'])
    .order(stackOrderNone)
  const layers = stackGenerator(formattedData)

  const extent = [
    0,
    max(layers, layer => max(layer, sequence => sequence[1])),
  ]

  // scales
  const xScale = scaleLinear()
    .domain(extent)
    .range([0, stageWidth])
  const topScale = scaleLinear()
    .domain([ 0, 10 ])
    .range([ 0, stageWidth ])

  // percentage labels
  const roundToNearestHundredth = (val: number) => Math.round(100 * val) / 100

  // diamonds
  const diamondWidth = 20
  const bottomDiamondXPos = Math.min(xScale(estimatedReturnPercent) - (diamondWidth / 2), (stageWidth - (diamondWidth / 2)))
  const topDiamondXPos = Math.min(topScale(targetReturn) - (diamondWidth / 2), (stageWidth - (diamondWidth / 2)))

  return (
    <Wrapper ref={wrapperRef}>
      <svg height={BAR_HEIGHT * 3} ref={svgRef} width="100%">
        <PercentageDisplay
          dy="-3"
          x="0"
          y={BAR_HEIGHT}
        >
          {`${ roundToNearestHundredth(armDiscountedRiskPercent) }%`}
        </PercentageDisplay>
        <path
          d="M4.243 0l4.242 4.243-4.242 4.243L0 4.243z"
          fill="#fc0006"
          fillRule="evenodd"
          transform={`
            translate(${ topDiamondXPos }, 5)
          `}
        />

        <PercentageDisplay
          dy="-3"
          x="0"
          y={BAR_HEIGHT * 3}
        >
          {`${ roundToNearestHundredth(targetReturn) }%`}
        </PercentageDisplay>
        <path
          d="M4.243 0l4.242 4.243-4.242 4.243L0 4.243z"
          fill="#000"
          fillRule="evenodd"
          transform={`
            translate(${ bottomDiamondXPos }, ${ BAR_HEIGHT * 2 + 5 })
          `}
        />

        {layers.map((layer, index) => {
          const sequence = layer[0]

          if (!sequence || sequence[0] === sequence[1]) return null

          // For scaled bar
          // const width = xScale(sequence[1]) - xScale(sequence[0])
          // const xPos = xScale(sequence[0])

          // For non-scaled bar
          const width = stageWidth / 4
          const xPos = (stageWidth / 4) * index
          const yPos = BAR_HEIGHT

          return (
            <rect
              fill={COLORS[index]}
              key={layer.key}
              height={BAR_HEIGHT}
              width={width}
              x={xPos}
              y={yPos}
            />
          )
        })}
      </svg>
    </Wrapper>
  )
}
