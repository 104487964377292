import styled from 'styled-components'

export const Icon = styled.div`
  font-size: 2rem;
  height: 3rem;
  width: 3rem;

  a {
    padding: 0.5rem;
  }

  span, a {
    color: ${ ({ theme }) => theme.colors.background };
    display: block;
  }
`
