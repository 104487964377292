import styled from 'styled-components'
import { IconButton } from '../iconButton/IconButton'

export const HeaderWrap = styled.header`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.header};
  display: grid;
  grid-template-columns: 3.3rem 1fr 3.3rem;
  height: 4rem;
  justify-content: center;
  width: 100%;
  padding: 0 0.8rem 0 1.6rem;
  position: relative;
`

export const Logout = styled(IconButton)`
  color: ${({ theme }) => theme.colors.textLight};
  font-size: 2rem;
`

export const Image = styled.img`
  display: block;
  margin: 0 auto;
  max-height: 3rem;
  width: auto;
`
