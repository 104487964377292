import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  width: 100vw;
  justify-content: center;
  padding: 1rem;
  width: 100vw;
`

export const MessageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
  padding: 2rem;
  color: white;
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSize.med};
`
