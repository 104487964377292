import styled from 'styled-components'

export const Button = styled.button`
  appearance: none;
  border: 0 none;
  border-radius: 5px;

  background-color: ${({ needsMarked, theme }) => {
    return needsMarked ? theme.colors.bluelight : 'transparent'
  }};
  color: ${({ needsMarked, theme }) =>
    needsMarked ? theme.colors.textPrimary : theme.colors.white};

  display: block;
  font-size: 1.2rem;
  text-decoration: underline;
  margin: 1rem auto;
  outline: none;

  transform: translateY(0);
  user-select: none;
  will-change: opacity, transform;

  padding: 4px 8px;

  &:disabled {
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
  }
`

export const NavButton = styled.button`
  appearance: none;
  border: 0 none;
  background-color: #5dbc9c;
  color: #ffffff;
  display: block;
  font-size: 2rem;
  border-radius: 5px;
  text-align: center;
  opacity: 1;
  outline: none;
  padding: 0.4rem 2rem;
  transition: opacity 150ms cubic-bezier(0, 0, 0, 0);
  user-select: none;
  will-change: opacity;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const CancelButton = styled.button`
  appearance: none;
  border: 0 none;
  color: #000000;
  display: block;
  font-size: 2rem;
  text-decoration: underline;
  opacity: 1;
  outline: none;
  padding: 0.4rem 2rem;
  transition: opacity 150ms cubic-bezier(0, 0, 0, 0);
  user-select: none;
  will-change: opacity;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const Wrapper = styled.div`
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 70fr 30fr;
  background: #f2f2f2
  padding-left: ${({ indent }) => (indent ? '4rem' : '0.5rem')};
`

export const ValueCircle = styled.div`
  width: 2.3rem;
  height: 2.3rem;
  background: ${({ theme, value }) =>
    value === null ? theme.colors.grey : value ? theme.colors.green : theme.colors.red};
  border-radius: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
`

export const QuestionContainer = styled.p`
  align-items: start;
  color: ${({ theme }) => theme.colors.textPrimary};
  display: grid;
  font-size: 1.2rem;
`

export const ValueChar = styled.span`
  color: #fff;
  font-size: 1.5rem;
`

export const ResponseText = styled.text`
  text-color: ${({ theme }) => theme.colors.primaryDark};
`

export const CommentField = styled.textarea`
  width: 90%;
  justify-content: center;
  display: block;
  margin: 5%;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

export const CancellationModal = styled.div`
  border-collapse: separate;
  display: block;
  border-radius: 40px;
  font-size: 1.5rem;
  padding: 2rem;
`

export const RatingCounter = styled.div`
  font-style: italic;
  text-align: center;
  padding-bottom: 10px;
  font-size: 1.7rem;
`

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CommentHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.heavy};
  font-size: ${({ theme }) => theme.fontSize.med};
`
