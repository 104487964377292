import React from 'react'

const Paid: React.FC = ({ ...other }) => {
  return (
    <svg {...other} version="1.1" id="Paid" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 183.25 125.38" xmlSpace="preserve">
      <path fill="currentColor" d="M156.31 3c.79 0 1.55.48 1.86 1.26l21.94 54.77c.41 1.02-.09 2.19-1.11 2.6L27.68 122.24a2.01 2.01 0 01-2.6-1.12L3.14 66.36c-.41-1.02.09-2.19 1.11-2.6L155.57 3.14c.24-.09.49-.14.74-.14m0-3c-.64 0-1.26.12-1.85.36L3.14 60.97C.58 62-.67 64.91.36 67.47l21.94 54.77a4.975 4.975 0 004.65 3.14c.64 0 1.26-.12 1.85-.36L180.11 64.4c2.56-1.03 3.81-3.94 2.78-6.5L160.95 3.14A4.972 4.972 0 00156.31 0z" id="Stroke" />
      <g id="Fill">
        <path fill="currentColor" d="M136.79 42.5c-1.28-3.19-2.85-5.34-4.72-6.47-1.87-1.13-3.98-1.22-6.32-.28l-2.67 1.07 8.31 20.74 2.76-1.11c2.28-.91 3.71-2.43 4.28-4.54.57-2.11.22-4.77-1.07-7.98l-.57-1.43zM75.7 72.69l7.09-2.84-8.14-10.02 1.05 12.86zm-29.22-3.55c-1.2-.59-2.52-.6-3.96-.04l-5.3 2.12 3.81 9.52 5.21-2.09c1.54-.62 2.56-1.47 3.06-2.57.5-1.09.47-2.34-.09-3.75-.62-1.54-1.53-2.6-2.73-3.19zm131.03-11.23L157.05 6.86a2.005 2.005 0 00-2.6-1.11L6.86 64.87a2.005 2.005 0 00-1.11 2.6l20.45 51.06a2.005 2.005 0 002.6 1.11l147.6-59.13a2.005 2.005 0 001.11-2.6zM56.89 78.15c-1.53 2.85-4.31 5.08-8.33 6.69l-5.08 2.03 4.48 11.2-7.96 3.19-13.22-33 13.17-5.28c2.52-1.01 4.94-1.44 7.25-1.29s4.35.87 6.1 2.16 3.06 3.02 3.94 5.2c1.31 3.22 1.18 6.26-.35 9.1zm34.66 2.47l-4.4-5.39-10.9 4.37.57 6.93-8.5 3.4-1.12-37.85 7.48-3 25.41 28.11-8.54 3.43zm16.18-6.49l-13.22-33 7.93-3.18 13.22 33-7.93 3.18zm39.09-24.56c-.32 2.91-1.36 5.5-3.14 7.78s-4.09 4-6.94 5.16l-10.86 4.35-13.22-33 10.63-4.26c2.92-1.17 5.8-1.56 8.66-1.17 2.86.39 5.42 1.53 7.69 3.41 2.27 1.89 4.02 4.32 5.23 7.31l.61 1.52c1.21 3.03 1.65 6 1.34 8.9z" />
        <path d="M136.79 42.5c-1.28-3.19-2.85-5.34-4.72-6.47-1.87-1.13-3.98-1.22-6.32-.28l-2.67 1.07 8.31 20.74 2.76-1.11c2.28-.91 3.71-2.43 4.28-4.54.57-2.11.22-4.77-1.07-7.98l-.57-1.43zM75.7 72.69l7.09-2.84-8.14-10.02 1.05 12.86zm-29.22-3.55c-1.2-.59-2.52-.6-3.96-.04l-5.3 2.12 3.81 9.52 5.21-2.09c1.54-.62 2.56-1.47 3.06-2.57.5-1.09.47-2.34-.09-3.75-.62-1.54-1.53-2.6-2.73-3.19zm131.03-11.23L157.05 6.86a2.005 2.005 0 00-2.6-1.11L6.86 64.87a2.005 2.005 0 00-1.11 2.6l20.45 51.06a2.005 2.005 0 002.6 1.11l147.6-59.13a2.005 2.005 0 001.11-2.6zM56.89 78.15c-1.53 2.85-4.31 5.08-8.33 6.69l-5.08 2.03 4.48 11.2-7.96 3.19-13.22-33 13.17-5.28c2.52-1.01 4.94-1.44 7.25-1.29s4.35.87 6.1 2.16 3.06 3.02 3.94 5.2c1.31 3.22 1.18 6.26-.35 9.1zm34.66 2.47l-4.4-5.39-10.9 4.37.57 6.93-8.5 3.4-1.12-37.85 7.48-3 25.41 28.11-8.54 3.43zm16.18-6.49l-13.22-33 7.93-3.18 13.22 33-7.93 3.18zm39.09-24.56c-.32 2.91-1.36 5.5-3.14 7.78s-4.09 4-6.94 5.16l-10.86 4.35-13.22-33 10.63-4.26c2.92-1.17 5.8-1.56 8.66-1.17 2.86.39 5.42 1.53 7.69 3.41 2.27 1.89 4.02 4.32 5.23 7.31l.61 1.52c1.21 3.03 1.65 6 1.34 8.9z" fill="none" stroke="currentColor" strokeMiterlimit="10" />
      </g>
    </svg>
  )
}

export default Paid