const currencyFormat = (
  val: number,
  removeDecimals: boolean = false,
  locale: string = 'en-US',
) => {
  const currency = new Intl.NumberFormat(locale, { style: 'currency', currency: 'USD' }).format(val)

  // NOTE | BK | I am aware this is not ideal
  if (removeDecimals) {
    return currency.substring(0, currency.length - 3)
  }

  return currency
}

export default currencyFormat
