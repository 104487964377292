import styled from 'styled-components'
import { UserOutlined, CheckSquareOutlined } from '@ant-design/icons'

export const UserIcon = styled(UserOutlined)`
  color: ${({ theme }) => theme.colors.background};
  font-size: 1.8rem;
`

export const ReadIcon = styled(CheckSquareOutlined)`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.stateDisabled};
`

export const Comment = styled.div`
  padding: 1rem 0rem;
`

export const CommentHeader = styled.div`
  display: grid;
  grid-template-columns: 30px 2fr 30px;
  align-items: center;
  height: 2rem;
`

export const CommentHeaderText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.med};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-weight: ${({ theme }) => theme.fontWeight.heavy};
  padding-left: 1rem;
`

export const CommentIcon = styled.div`
  display: grid;
  justify-content: ${({ align }) => align};
`

export const CommentIdentifier = styled.div`
  align-items: baseline;
  display: grid;
  grid-template-columns: 8fr 1fr;
  padding: 0rem 0.25rem;
`

export const CommentIdentifierText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sml};
`

export const CommentOutline = styled.div`
  background-color: #d1ebf7;
  border: 2px solid #bfd7ed;
  display: grid;
  grid-template-rows: auto auto auto;
  padding: 4px;
  position: relative;

  &::before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 20px;
    bottom: -12px;
    transform: rotate(225deg);
    border: 12px solid;
    border-color: #bfd7ed transparent transparent #bfd7ed;
  }

  &::after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 20px;
    bottom: -9px;
    transform: rotate(225deg);
    border: 12px solid;
    border-color: #d1ebf7 transparent transparent #d1ebf7;
  }
`

export const CommentText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.med};
  padding: 0.25rem calc(30px + 1rem);
`

export const SystemMessage = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.heavy};
  color: ${({ theme }) => theme.colors.primaryDark};
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
`

export const UserMessage = styled.p``

export const CommentTimestamp = styled.div`
  text-align: right;
  font-size: ${({ theme }) => theme.fontSize.sml};
`

export const IconCircle = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${({ color, theme }) => (color ? color : theme.colors.blue)};
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
`
