import React from 'react'

// components
import Button from '../../components/button'

// constants
import { REDIRECT_URI } from '../../constants/auth'

import * as loginBackground from '../../assets/backgrounds/login-screen.png'

// styles
import { Image, Wrapper } from './style'
import { useAuth } from '@arm/react-auth'

const LoginPage: React.FC<{}> = () => {
  const { redirectToLogin } = useAuth()

  localStorage.setItem(REDIRECT_URI, '/loans')
  return (
    <Wrapper>
      <Image alt="Seedling plant" src={loginBackground} />

      <Button disabled={false} name="sign-in" onClickFn={() => redirectToLogin()}>
        Sign In
      </Button>
    </Wrapper>
  )
}

export default LoginPage
