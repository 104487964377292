import styled from 'styled-components'

export const LabelValue = styled.div`
  font-size: 1rem;
  font-weight: 700;
`

export const Section = styled.section`
  align-items: center;
  display: grid;
  height: 8rem;
  margin-bottom: 2rem;
  width: 100%;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.header};
    height: 11rem;
    margin-bottom: 0;
  }
`

export const TopGrid = styled.section`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  padding: 0.4rem 1.2rem;
  position: relative;
`

export const Wrapper = styled.div`
  align-items: center;
  display: grid;
  height: 19rem;
  grid-template-rows: 1fr 1fr;
  width: 100%;
`
