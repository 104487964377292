import React, { useEffect, useState } from 'react'
import { CheckedSquare } from '../icons/CheckedSquare'
import { useHistory } from 'react-router-dom'

import {
  Bar,
  BarContainer,
  BarTitle,
  ErrorMessage,
  BarTrack,
  BlockTitle,
  CropLink,
  CompleteIconContainer,
  Wrapper,
  CondensedBlockTitle,
  CondensedWrapper,
  Select,
} from './styles'

//types
import { LoanDetailsData_loan_crops } from '../../types/LoanDetailsData'

interface Props {
  cropData: (LoanDetailsData_loan_crops | null)[]
  loanId: string
  activeCropCode?: string
  condenseSize: boolean
  redirectTo: string
}

export const CropInspectionBlock: React.FC<Props> = ({
  cropData,
  loanId,
  activeCropCode,
  condenseSize,
  redirectTo,
}) => {
  const [showWidth, setShowWidth] = useState(false)
  useEffect(() => {
    setShowWidth(true)
  }, [cropData])
  const history = useHistory()
  const redirectToTable = redirectTo.match('table')
  let selectedCrop = activeCropCode

  const handleChangeToTable = (e) => {
    history.replace(`/loans/${loanId}/crop-inspection/${e.target.value}/${'edit/table'}`)
  }

  const handleChangeToQuestions = (e) => {
    history.replace(`/loans/${loanId}/crop-inspection/${e.target.value}/${'edit/questions'}`)
  }

  const displayEmptyCropError = () => {
    if (cropData.length === 0) {
      return <ErrorMessage>No crops found with allocated acres.</ErrorMessage>
    }
  }
  return (
    <>
      {condenseSize ? (
        <CondensedWrapper>
          <CondensedBlockTitle>Crop Inspection: </CondensedBlockTitle>
          {redirectToTable ? (
            <Select value={selectedCrop} onChange={handleChangeToTable}>
              {cropData.map(
                (datum) =>
                  datum && (
                    <option key={datum.cropName} value={datum.cropCode}>
                      {(selectedCrop = datum.cropName)}
                    </option>
                  )
              )}
            </Select>
          ) : (
            <Select value={selectedCrop} onChange={handleChangeToQuestions}>
              {cropData.map(
                (datum) =>
                  datum && (
                    <option key={datum.cropName} value={datum.cropCode}>
                      {(selectedCrop = datum.cropName)}
                    </option>
                  )
              )}
            </Select>
          )}
          {displayEmptyCropError()}
        </CondensedWrapper>
      ) : (
        <Wrapper>
          <BlockTitle>Crop Inspection Progress</BlockTitle>
          {cropData.map(
            (datum) =>
              datum && (
                <CropLink
                  key={datum.cropCode}
                  to={`/loans/${loanId}/crop-inspection/${datum.cropCode}/${'table'}`}
                >
                  <BarContainer active={activeCropCode === datum.cropCode}>
                    <BarTitle active={activeCropCode === datum.cropCode}>{datum.cropName}</BarTitle>
                    <BarTrack>
                      <Bar width={showWidth ? datum.inspectionProgress : 0} />
                    </BarTrack>
                    {datum.inspectionComplete && (
                      <CompleteIconContainer show={showWidth && datum.inspectionComplete}>
                        <CheckedSquare size="1.5rem" color="#000" />
                      </CompleteIconContainer>
                    )}
                  </BarContainer>
                </CropLink>
              )
          )}
          {displayEmptyCropError()}
        </Wrapper>
      )}
    </>
  )
}
