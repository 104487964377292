import React from 'react'

import { LoanStatus } from '../../types/globalTypes'
import {
  WorkingIcon,
  RecommendedIcon,
  ApprovedIcon,
  UploadedIcon,
  DeclinedIcon,
  WithdrawnIcon,
  HistoricalIcon,
  PaidWrapper,
  PaidIcon,
  WrittenOffIcon,
  VoidedIcon,
} from './style'

interface Props {
  loanStatus: LoanStatus
}

export const LoanStatusIcon: React.FC<Props> = ({ loanStatus }) => {
  switch (loanStatus) {
    case LoanStatus.WORKING:
      return <WorkingIcon title="Working" />
    case LoanStatus.RECOMMENDED:
      return <RecommendedIcon title="Recommended" />
    case LoanStatus.APPROVED:
      return <ApprovedIcon title="Approved" />
    case LoanStatus.UPLOADED:
      return <UploadedIcon title="Uploaded" />
    case LoanStatus.DECLINED:
      return <DeclinedIcon title="Declined" />
    case LoanStatus.WITHDRAWN:
      return <WithdrawnIcon title="Withdrawn" />
    case LoanStatus.HISTORICAL:
      return <HistoricalIcon title="Historical" />
    case LoanStatus.PAID_OFF:
      return (
        <PaidWrapper>
          <PaidIcon title="Paid" />
        </PaidWrapper>
      )
    case LoanStatus.WRITTEN_OFF:
      return <WrittenOffIcon title="Written Off" />
    case LoanStatus.VOIDED:
      return <VoidedIcon title="Voided" />
    default:
      return null
  }
}
