import React, { useState, useEffect } from 'react'
import { nanoid } from 'nanoid'
import { connect } from 'react-redux'

import {
  QuestionContainer,
  Button,
  Wrapper,
  CommentField,
  NavButton,
  CancelButton,
  ButtonContainer,
  CancellationModal,
  RatingCounter,
  CommentHeader,
  CommentWrapper,
} from './style'

// types and interfaces
import {
  GetLatestCropInspection_loan_latestCropInspection_cropCodeGroups_questionWithAnswerList,
  GetLatestCropInspection_cropInspectionQuestions,
} from '../../types/GetLatestCropInspection'

import { CropInspectionSaveInput, SaveCropInspectionAnswerInput } from '../../types/globalTypes'

import Modal from '../modal'
import { CLEAR_INSPECTION_DATA, UPDATE_ANSWERS } from '../../constants/cropInspections'

interface Props {
  latestQuestionsAndAnswers?: GetLatestCropInspection_loan_latestCropInspection_cropCodeGroups_questionWithAnswerList[]
  questionList?: GetLatestCropInspection_cropInspectionQuestions[]
  cropCode: string
  saveCropInspection: (input: CropInspectionSaveInput) => void
  readOnly: boolean
  history: {
    push: (url: string) => void
  }
  redirect: string
  loanNumber: number
  ratings: any[]
  answers: any[]
  numCrops: number
  updateAnswers: (newAnswers: any[]) => void
  clearInspectionData: () => any
}

const CropInspectionQuestionnaire: React.FC<Props> = ({
  latestQuestionsAndAnswers,
  questionList,
  cropCode,
  saveCropInspection,
  readOnly,
  history,
  redirect,
  loanNumber,
  ratings,
  answers,
  numCrops,
  updateAnswers,
  clearInspectionData,
}) => {
  const [rootQuestions, setRootQuestions] = useState<any>([])
  const [yesNoHighlight, setYesNoHighlight] = useState<{ [key: number]: string }>({})
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false)
  const [reRender, setReRender] = useState<boolean>(true)

  let comment = null

  useEffect(() => {
    if (!questionList) return
    setRootQuestions(questionList)

    if (readOnly && latestQuestionsAndAnswers) {
      const buttonHighlight = latestQuestionsAndAnswers.reduce((accum, qAndA) => {
        return {
          ...accum,
          [qAndA.question.id]: qAndA.answer ? 'yes' : qAndA.answer === null ? 'none' : 'no',
        }
      }, {})
      setYesNoHighlight(buttonHighlight)
    } else if (!readOnly) {
      const buttonHighlight = questionList.reduce((accum, q) => {
        return {
          ...accum,
          [q.id]: 'none',
        }
      }, {})
      setYesNoHighlight(buttonHighlight)
    }
  }, [latestQuestionsAndAnswers, questionList, readOnly])

  const saveClick = () => {
    const answersToSave: SaveCropInspectionAnswerInput[] = answers.filter(
      (ans) => ans.answer !== null
    )
    const ratingsToSave = ratings.filter((rat) => rat.inspectedAcres !== null)

    const toSave: CropInspectionSaveInput = {
      comment: comment,
      cropInspectionAnswers: answersToSave,
      cropInspectionId: nanoid(),
      cropInspectionRatings: ratingsToSave.map((rat) => {
        delete rat.cropCode
        return { ...rat, loanCropUnitId: Number(rat.loanCropUnitId) }
      }),
      loanNumber: loanNumber,
    }
    saveCropInspection(toSave)

    clearInspectionData()
    history.push(redirect)
  }

  const buttonTypeToBoolMap = { yes: true, no: false, none: null }

  const recordYesNo = (type: string, storedRatingIdx) => {
    if (!readOnly && storedRatingIdx !== -1) {
      let newAnswers = answers
      const currAnswer = newAnswers[storedRatingIdx].answer

      if (currAnswer !== buttonTypeToBoolMap[type]) {
        newAnswers[storedRatingIdx].answer = buttonTypeToBoolMap[type]
      } else {
        newAnswers[storedRatingIdx].answer = null
      }

      updateAnswers(newAnswers)
      setReRender(!reRender)
    }
  }

  const isHighlighted = (type: string, storedRatingIdx: number, questionId: number) => {
    if (readOnly) {
      return yesNoHighlight[questionId] === type
    } else {
      return storedRatingIdx !== -1
        ? answers[storedRatingIdx].answer === buttonTypeToBoolMap[type]
        : false
    }
  }

  const getNumCropsWithResponses = () => {
    const cropCodesWithActions = new Set()

    answers.forEach((cur) => {
      if (cur.answer !== null) {
        cropCodesWithActions.add(cur.cropCode)
      }

      return cropCodesWithActions
    })

    ratings.forEach((cur) => {
      if (cur.inspectedAcres !== null) {
        cropCodesWithActions.add(cur.cropCode)
      }

      return cropCodesWithActions
    })

    return cropCodesWithActions.size
  }

  return (
    <>
      {rootQuestions.map((question) => {
        /*const followupQuestions =
          followupMap[question.id] && response?.responseText === question.negativeAnswer
            ? followupMap[question.id].map((qr) => renderQuestion(qr.question, qr.response, true))
            : null

        return [rootQuestion, followupQuestions]*/
        const storedRatingIdx = answers.findIndex(
          (cia) => cia.cropCode === cropCode && cia.refQuestionId === question.id
        )
        return (
          <Wrapper indent={false} key={question.id}>
            <QuestionContainer>{question.question}</QuestionContainer>
            <ButtonContainer>
              <Button
                isYesButton={true}
                needsMarked={isHighlighted('yes', storedRatingIdx, question.id)}
                onClick={() => recordYesNo('yes', storedRatingIdx)}
              >
                Yes
              </Button>
              <Button
                isYesButton={false}
                needsMarked={isHighlighted('no', storedRatingIdx, question.id)}
                onClick={() => recordYesNo('no', storedRatingIdx)}
              >
                No
              </Button>
            </ButtonContainer>
          </Wrapper>
        )
      })}

      {!readOnly ? (
        <CommentWrapper>
          <CommentHeader> &ensp; Comments</CommentHeader>
          <CommentField
            rows={6}
            onChange={(e) => {
              comment = e.target.value
            }}
          />
          <ButtonContainer>
            <CancelButton onClick={() => setCancelModalOpen(true)}>Cancel</CancelButton>
            <NavButton
              onClick={() => {
                saveClick()
              }}
            >
              Save
            </NavButton>
          </ButtonContainer>
          {!readOnly ? (
            <RatingCounter>
              {`Crops rated: ${getNumCropsWithResponses()} / ${numCrops}`}
            </RatingCounter>
          ) : null}
        </CommentWrapper>
      ) : null}

      {cancelModalOpen ? (
        <Modal
          closeModal={() => setCancelModalOpen(false)}
          isOpen={cancelModalOpen}
          children={() => (
            <CancellationModal>
              <div style={{ padding: '10px' }}>
                <p>Are you sure you want to cancel? </p>
                <p>Your responses will not be saved.</p>
                <ButtonContainer>
                  <Button
                    needsMarked={false}
                    onClick={() => {
                      setCancelModalOpen(false)
                      clearInspectionData()
                      history.push(redirect)
                    }}
                  >
                    Yes
                  </Button>
                  <Button needsMarked={false} onClick={() => setCancelModalOpen(false)}>
                    No
                  </Button>
                </ButtonContainer>
              </div>
            </CancellationModal>
          )}
        />
      ) : null}
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  loanNumber: state.inspection.loanNumber,
  ratings: state.inspection.cropInspectionRatings,
  answers: state.inspection.cropInspectionAnswers,
  numCrops: state.inspection.numCrops,
})

const mapDispatchToProps = (dispatch) => ({
  updateAnswers: (updatedAnswers) =>
    dispatch({
      type: UPDATE_ANSWERS,
      updatedAnswers: updatedAnswers,
    }),
  clearInspectionData: () => dispatch({ type: CLEAR_INSPECTION_DATA }),
})

export default connect(mapStateToProps, mapDispatchToProps)(CropInspectionQuestionnaire)
