import React from 'react'
import gql from 'graphql-tag'

// components
import CommitmentRiskChart from '../commitmentRiskChart'
import Exceptions from '../exceptions'
import RiskChart from '../riskChart'

// types
import { DrawerContentData } from '../../types/DrawerContentData'

// helpers
import currencyFormat from '../../helpers/currencyFormat'

import {
  LabelValue,
  Section,
  TopGrid,
  Wrapper,
} from './style'

export const DrawerContentFragment = gql`
  fragment DrawerContentData on Loan {
    commitment {
      armCommitment
      distributorCommitment
      totalCommitment
    }
    estimatedInterest {
      estimatedReturnPercent {
        percentage
      }
    }
    exceptions {
      id
      message
    }
    pricingSuggestion {
      returnScale
      targetReturn {
        percentage
      }
    }
    risk {
      armDiscountedRisk
      armDiscountedRiskPercent {
        percentage
      }
      totalRisk
      totalRiskPercent {
        percentage
      }
    }
  }
`

interface Props {
  loan: DrawerContentData
}

export const DrawerContent: React.FC<Props> = ({
  loan,
}) => (
  <Wrapper>
    <Section>
      <TopGrid>
        <LabelValue>
          <h6>Total Commit</h6>
          <p>
            {loan.commitment
              ? currencyFormat(loan.commitment.totalCommitment, true)
              : '-'
            }
          </p>
        </LabelValue>

        <LabelValue>
          <h6>Risk</h6>
          <p>
            {loan.risk.totalRisk
              ? currencyFormat(loan.risk.totalRisk, true)
              : '-'
            }
          </p>
        </LabelValue>

        <LabelValue>
          <h6>ARM Commit</h6>
          <p>
            {loan.commitment
              ? currencyFormat(loan.commitment.armCommitment, true)
              : '-'
            }
          </p>
        </LabelValue>

        <LabelValue>
          <h6>Disc Risk</h6>
          <p>
            {loan.risk.armDiscountedRisk
              ? currencyFormat(loan.risk.armDiscountedRisk, true)
              : '-'
            }
          </p>
        </LabelValue>

        <RiskChart
          armDiscountedRiskPercent={loan.risk.armDiscountedRiskPercent.percentage}
          estimatedReturnPercent={loan.estimatedInterest.estimatedReturnPercent.percentage}
          returnScale={loan.pricingSuggestion.returnScale}
          targetReturn={loan.pricingSuggestion.targetReturn.percentage}
        />
      </TopGrid>
    </Section>
    <Section>
      <TopGrid>
          <CommitmentRiskChart
            commitments={loan.commitment}
            totalRiskAmount={loan.risk.totalRisk}
            totalRiskPercent={loan.risk.totalRiskPercent.percentage}
          />
          <Exceptions
            exceptions={loan.exceptions}
          />
      </TopGrid>
    </Section>
  </Wrapper>
)
