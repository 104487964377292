import styled, { css } from 'styled-components'
// icons
import {
  PhoneOutlined,
  UsergroupAddOutlined,
  EditOutlined,
  LikeFilled,
  EyeFilled,
  DislikeFilled,
  UndoOutlined,
  QuestionCircleFilled,
  FullscreenExitOutlined,
  SearchOutlined,
} from '@ant-design/icons'

const IconStyle = css`
  font-size: 2rem;
`

export const ApproveIcon = styled(LikeFilled)`
  ${IconStyle}
  color: ${({ theme, color }) => (color ? color : theme.colors.green)};
`

export const DeclineIcon = styled(DislikeFilled)`
  ${IconStyle}
  color: ${({ theme, color }) => (color ? color : theme.colors.red)};
`

export const ConferenceCallIcon = styled(PhoneOutlined)`
  ${IconStyle}
  color: ${({ theme, color }) => (color ? color : theme.colors.red)};
`

export const ComitteeCommentIcon = styled(UsergroupAddOutlined)`
  ${IconStyle}
  color: ${({ theme, color }) => (color ? color : theme.colors.yellow)};
`

export const PassiveCommentIcon = styled(UsergroupAddOutlined)`
  ${IconStyle}
  color: ${({ theme, color }) => (color ? color : theme.colors.turquoise)};
`

export const UserNoteIcon = styled(EditOutlined)`
  ${IconStyle}
  color: ${({ theme, color }) => (color ? color : theme.colors.yellow)};
`

export const WatchListIcon = styled(EyeFilled)`
  ${IconStyle}
  color: ${({ theme, color }) => (color ? color : theme.colors.red)};
`

export const RescindIcon = styled(UndoOutlined)`
  ${IconStyle}
  color: ${({ theme, color }) => (color ? color : theme.colors.blue)};
`

export const FocusArrowsIcon = styled(FullscreenExitOutlined)`
  ${IconStyle}
  color: ${({ theme, color }) => (color ? color : theme.colors.grey)};
`

export const MagnifyingGlassIcon = styled(SearchOutlined)`
  ${IconStyle}
  color: ${({ theme, color }) => (color ? color : theme.colors.grey)};
`

export const QuestionMarkIcon = styled(QuestionCircleFilled)`
  ${IconStyle}
  color: ${({ theme, color }) => (color ? color : theme.colors.grey)};
`

export const ThumbsUpIcon = styled(LikeFilled)`
  ${IconStyle}
  color: ${({ theme, color }) => (color ? color : theme.colors.green)};
`

export const ThumbsDownIcon = styled(LikeFilled)`
  ${IconStyle}
  color: ${({ theme, color }) => (color ? color : theme.colors.red)};
`
