import React from 'react'
import { Link } from 'react-router-dom'

// helpers
import isInStandaloneMode from '../../helpers/isStandAloneMode'

// icons
import {
  BankOutlined,
  BulbOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
  MenuOutlined,
} from '@ant-design/icons'

import { Nav, Wrapper } from './style'
import useLoanId from '../loanIdHook/LoanIdHook'

interface FooterLinkProps {
  children: React.ReactNode
  disabled?: boolean
  href: string
  match: {
    url: string
  }
  title: string
}

interface Props {
  hideBottomNav: boolean
  match: {
    params: {
      loanId: string | undefined
    }
    url: string
  }
}

export const BottomNavigation: React.FC<Props> = ({ hideBottomNav, match }) => {

  const standAlone = isInStandaloneMode()

  const loanId = useLoanId()

  if (hideBottomNav) return null

  return (
    <Wrapper standAlone={standAlone}>
      <Nav>
        <Link
          className={`bottom-link${match.url === '/loans' ? ' active' : ''}`}
          to="/loans"
          title="Loans"
        >
          <MenuOutlined />
          <p>Loans</p>
        </Link>

        <Link
          className={`bottom-link${match.url === `/loans/${loanId}/details` ? ' active' : ''}`}
          to={`/loans/${loanId}/details`}
          title="Loans Details"
        >
          <InfoCircleOutlined />
          <p>Details</p>
        </Link>

        <Link
          className={`bottom-link${match.url === `/loans/${loanId}/balances` ? ' active' : ''}`}
          to={`/loans/${loanId}/balances`}
          title="Loan balances"
        >
          <BankOutlined />
          <p>Balances</p>
        </Link>

        <Link
          className={`bottom-link${match.url === `/loans/${loanId}/history` ? ' active' : ''}`}
          to={`/loans/${loanId}/history`}
          title="Loan history"
        >
          <CalendarOutlined />
          <p>History</p>
        </Link>

        <Link
          className={`bottom-link${match.url === `/loans/${loanId}/decide` ? ' active' : ''}`}
          to={`/loans/${loanId}/decide`}
          title="Loan desicion"
        >
          <BulbOutlined />
          <p>Decide</p>
        </Link>
      </Nav>
    </Wrapper>
  )
}
