import styled from 'styled-components'

export const CameraControls = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  position: absolute;
  z-index: 20;

  > div {
    display: grid;
  }

  @media only screen and (orientation: landscape) {
    height: 100%;
    right: 0;
    top: 0;
    width: 12rem;

    > div {
      height: 100%;
      grid-template-rows: 1fr 8rem 1fr;
    }
  }

  @media only screen and (orientation: portrait) {
    bottom: 0;
    height: 12rem;
    left: 0;
    width: 100%;

    > div {
      grid-template-columns: 1fr 8rem 1fr;
      width: 100%;
    }
  }
`

export const CancelButton = styled.button`
  background-color: transparent;
  border: 0 none;
  color: ${ ({ theme }) => theme.colors.yellow };
  font-size: 1.8rem;
  outline: none;
  text-align: center;
`

export const CaptureButton = styled.button`
  background-color: ${ ({ theme }) => theme.colors.background };
  border: 3px solid ${ ({ theme }) => theme.colors.header };
  border-radius: 50%;
  box-shadow: 0 0 0 0.5rem ${ ({ theme }) => theme.colors.background };
  height: 8rem;
  margin: 0 auto;
  outline: none;
  padding: 0;
  transition: border-width 100ms cubic-bezier(0, 0, 0, 0);
  width: 8rem;
  z-index: 60;

  &:active {
    border-width: 8px;
  }
`
