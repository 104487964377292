import styled from 'styled-components'

export const SearchInput = styled.input`
  appearance: none;
  background-color: ${ ({ theme }) => theme.colors.background };
  border: 1px solid ${ ({ theme }) => theme.colors.grey };
  border-radius: 1rem;
  color: ${ ({ theme }) => theme.colors.textPrimary };
  font-size: 1.6rem;
  padding: 0.6rem 1.6rem 0.6rem 3.2rem;
  width: 100%;

  ::placeholder {
    color: ${ ({ theme }) => theme.colors.textPrimary };
  }
`

export const Wrapper = styled.nav`
  padding: 0.8rem 0.8rem;
  position: relative;
`


export const IconWrapper = styled.figure`
  height: 1.8rem;
  left: 1.6rem;
  opacity: ${ ({ focused }) => focused ? '1' : '0.4' };
  position: absolute;
  top: 1.6rem;
  transition: 200ms opacity cubic-bezier(0, 0, 0 ,0);
  width: 1.8rem;
  will-change: opacity;
  z-index: 2
`
