import React from 'react'
import gql from 'graphql-tag'

// components
import LoadingIndicator, { LoadingIndicatorWrapper } from '../../components/loadingIndicator'
import { LoanStatusIcon } from '../../components/loanStatusIcon/LoanStatusIcon'
import ErrorCard from '../../components/errorCard'
import EmptyCard from '../../components/emptyCard'

//styles
import {
  Wrapper,
  
  StatusIconWrapper,
} from './style'

import {
  TableHeader,
  TableHeaders,
  TableWrapper,
  TableValue,
  TableValues,
} from '../../components/gridTable/GridTable'

//constants
import { EMPTY_HISTORY_MESSAGE } from '../../constants/messages'

//helpers
import currencyFormat from '../../helpers/currencyFormat'

import { HistoryData } from '../../types/HistoryData'

//types and interfaces
type AccountDetails = {
  access_token: string
  email: string
  expires_at: string
  id_token: string
  name: string
}

interface Props {
  data: HistoryData
  error: boolean
  loading: boolean
}

export const HistoryFragment = gql`
  fragment HistoryData on Query {
    loan(id: $loanId) {
      id
      history(includeSelf: true) {
        acres {
          total
        }
        applicationDate {
          day
          month
          year
        }
        borrower: refBorrower {
          name {
            fullName
          }
        }
        commitment {
          armCommitment
          distributorCommitment
          totalCommitment
        }
        displayId
        id
        terms {
          estimatedCloseDate {
            day
            month
            year
          }
          maturityDate {
            day
            month
            year
          }
        }
        status
      }
    }
  }
`

const BalancesPage: React.FC<Props> = ({ data, error, loading }) => {
  if (loading)
    return (
      <Wrapper>
        <LoadingIndicatorWrapper>
          <LoadingIndicator />
        </LoadingIndicatorWrapper>
      </Wrapper>
    )

  if (error || !data.loan) return <ErrorCard />

  if (data?.loan?.history.length === 0) return <EmptyCard message={EMPTY_HISTORY_MESSAGE} />

  return (
    <Wrapper>
      <TableWrapper role="table" ariaLabel="History">
        <TableHeaders role="row">
          <TableHeader role="columnheader">Loan ID</TableHeader>
          <TableHeader role="columnheader">Borrower</TableHeader>
          <TableHeader role="columnheader">Status</TableHeader>
          <TableHeader role="columnheader">Apply Date</TableHeader>
          <TableHeader role="columnheader">Close Date</TableHeader>
          <TableHeader role="columnheader">Maturity Date</TableHeader>
          <TableHeader role="columnheader">Acres</TableHeader>
          <TableHeader role="columnheader">ARM Commit</TableHeader>
          <TableHeader role="columnheader">Dist Commit</TableHeader>
          <TableHeader role="columnheader">Total Commit</TableHeader>
        </TableHeaders>

        {data.loan.history.map((loan) => (
          <TableValues role="row" key={loan.id}>
            <TableValue role="cell" top>{loan.displayId}</TableValue>
            <TableValue role="cell" top>
              {loan.borrower && loan.borrower.name.fullName.length > 15
                ? `${loan.borrower.name.fullName.slice(0, 15)}...`
                : loan.borrower
                ? loan.borrower.name.fullName
                : null}
            </TableValue>
            <TableValue role="cell">
              <StatusIconWrapper>
                <LoanStatusIcon loanStatus={loan.status} />
              </StatusIconWrapper>
            </TableValue>
            <TableValue role="cell">{`${loan.applicationDate.month}/${loan.applicationDate.day}/${loan.applicationDate.year}`}</TableValue>
            <TableValue role="cell">{`${loan.terms.estimatedCloseDate.month}/${loan.terms.estimatedCloseDate.day}/${loan.terms.estimatedCloseDate.year}`}</TableValue>
            <TableValue role="cell">{`${loan.terms.maturityDate.month}/${loan.terms.maturityDate.day}/${loan.terms.maturityDate.year}`}</TableValue>
            <TableValue role="cell">{loan.acres?.total || 0}</TableValue>
            <TableValue role="cell">{currencyFormat(loan.commitment.armCommitment)}</TableValue>
            <TableValue role="cell">{currencyFormat(loan.commitment.distributorCommitment)}</TableValue>
            <TableValue role="cell">{currencyFormat(loan.commitment.totalCommitment)}</TableValue>
          </TableValues>
        ))}
      </TableWrapper>
    </Wrapper>
  )
}

export default BalancesPage
