import React from 'react'

// style
import { Grid, GridCell, Heading } from './style'

type formatObjectType = {
  dataKey: string
  formatValue?(val: string | number): string
  header: string
  renderComponent?(id: string | number, val: any): React.ReactNode
}

interface Props {
  data: any[]
  format: formatObjectType[]
  identifier: string | number
  vertical?: boolean
}

export const TableGrid: React.FC<Props> = ({ data, format, identifier, vertical = false }) => {
  const formmatRow = (dataPoint) =>
    format.map((f) => {
      const value = dataPoint[f.dataKey] || null

      if (f.formatValue) {
        return (
          <GridCell key={`${dataPoint[identifier]}-${f.dataKey}`} role="gridcell">
            <p>{f.formatValue(value)}</p>
          </GridCell>
        )
      }

      if (f.renderComponent) {
        return (
          <GridCell key={`${dataPoint[identifier]}-${f.dataKey}`} role="gridcell">
            {f.renderComponent(dataPoint[identifier], value)}
          </GridCell>
        )
      }

      return (
        <GridCell key={`${dataPoint[identifier]}-${f.dataKey}`} role="gridcell">
          <p>{value}</p>
        </GridCell>
      )
    })
  return (
    <Grid
      className={`${vertical ? 'vertical' : ''}`}
      dataCount={data.length}
      headerCount={format.length}
      role="grid"
    >
      {format.map((f) => (
        <Heading
          className={`${vertical ? 'vertical' : ''}${
            !vertical && f.header.includes('Acres Inspected') ? ' large' : ''
          }`}
          key={`${f.dataKey}-heading`}
          role="heading"
        >
          <p>{f.header}</p>
        </Heading>
      ))}

      {data.map((dataPoint) => formmatRow(dataPoint))}
    </Grid>
  )
}
