import styled from 'styled-components'

export const MessagebarIcon = styled.button`
  width: 30px;
  margin: 0px;
  padding: 0px;
  width: 30px;
  background-color: ${({ color, theme }) => (color ? color : theme.colors.blue)};
  height: 30px;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border-width: 0;
  display: grid;
  row: 1;
  border: none;
  transition: border-width 100ms cubic-bezier(0, 0, 0, 0);

  &:focus {
    outline: none;
  }

  ${ ({ active, theme }) => active
    ? `
      border: 2px solid ${ theme.colors.textPrimary };
    `
    : ''
  }
`
