import React from 'react'

// styles
import { MessageContainer, Wrapper } from './style'

// constants
import { ERROR_DEFAULT_MESSAGE } from '../../constants/messages'

interface Props {
  message?: string
}

export const ErrorCard: React.FC<Props> = ({ message }) => (
  <Wrapper>
    <MessageContainer>{message ? message : ERROR_DEFAULT_MESSAGE}</MessageContainer>
  </Wrapper>
)
