import React from 'react'

type Props = {
  size: number
  color?: string
}

const circle: React.FC<Props> = ({ size, color = '#000' }) => {
  return (
    <svg viewBox="0 0 100 100" width={size} height={size}>
      <polygon points="50,0 0,100 100,100" fill={color} />
    </svg>
  )
}

export default circle
