import React from 'react'

export default ({ color }) => (
  <svg width="61" height="10" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M58.14 0H2.005C.32 0-.62.49.423.826L28.49 9.807c.804.257 2.35.257 3.163 0L59.722.826C60.765.49 59.824 0 58.14 0z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
)
