import React from 'react'

import { CaretLeftOutlined, PlayCircleOutlined } from '@ant-design/icons'

import {
  BackButton,
  Header,
  Header1,
  List,
  ListItem,
  ScrollWrapper,
  Wrapper,
} from './style'

const FAKE_VID = {
  title: 'Video',
}

interface Props {
  history: {
    goBack(): void
  }
}

export const MediaPage: React.FC<Props> = ({
  history,
}) => {
  return (
    <Wrapper>
      <Header>
        <BackButton onClick={() => history.goBack()}>
          <CaretLeftOutlined />
          <p>Back</p>
        </BackButton>

        <Header1>Media</Header1>
      </Header>
      <ScrollWrapper>
        <List>
          {Array(20).fill([]).map((_, index) => (
            <ListItem key={`media-${ index }`}>
              <PlayCircleOutlined />
              <p>{`${ FAKE_VID.title } ${ index + 1 }`}</p>
              {(Math.random() >= 0.5) && <figure />}
            </ListItem>
          ))}
        </List>
      </ScrollWrapper>
    </Wrapper>
  )
}
