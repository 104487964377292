export default {
  sm: `576px`,
  md: `768px`,
  lg: `992px`,
  xl: `1200px`,
  xxl: `1500px`,
  ultrawide: `2000px`,

  device_landscape: '1024px',
  device_portrait: '768px',
}
