import styled from 'styled-components'

export const ScrollWrapper = styled.main`
overflow-x: hidden;
overflow-y: auto;
-webkit-overflow-scrolling: touch;

${ ({ hideBottomNav, isLandscapeMode }) => isLandscapeMode
? `
height: 100%;
grid-column: ${ hideBottomNav ? '2/3' : '3/4' };
`
: ''}
`

export const SwipeWrapper = styled.div`
${ ({ hideBottomNav, isLandscapeMode }) => isLandscapeMode
? `
height: 100%;
grid-column: ${ hideBottomNav ? '1/2' : '2/3' };
`
: ''}
`

export const Wrapper = styled.div`
display: grid;
grid-template-rows: ${ ({ isOpen, isSearch }) => isSearch
? '4.8rem'
: isOpen ? '33.3rem' : '14.3rem' } 1fr;
height: calc(100vh - ${ ({ hideBottomNav }) => hideBottomNav ? '4rem' : '9.6rem' });
position: relative;
width: 100vw;

${ ({ hideBottomNav, isLandscapeMode }) => {
  if (isLandscapeMode) {
    return `
    display: grid;
    height: calc(100vh - 4rem);
    column-gap: 2rem;
    grid-template-columns: ${ hideBottomNav ? '0.6fr 1fr' : '5.6rem 0.6fr 1fr' };
    grid-template-rows: 1fr;
    `
  }
  
  return ''
}
}`