import styled from 'styled-components'
import { CameraOutlined } from '@ant-design/icons'

export const AcresP = styled.p`
  align-items: center;
  display: grid;
  min-height: 3rem;
  width: 100%;
`

export const AcresVerified = styled.div`
  p {
    display: inline-block;
    font-size: 1.3rem;
  }

  figure {
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.green};
    display: inline-block;
    font-size: 1.3rem;
    margin-left: 1rem;
  }
`

export const Box = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 4px;
  height: 2rem;
  transition: background-color 150ms cubic-bezier(0, 0, 0, 0),
    border-color 150ms cubic-bezier(0, 0, 0, 0);
  width: 2rem;

  &.selected {
    background-color: ${({ theme }) => theme.colors.blue};
    border-color: ${({ theme }) => theme.colors.blue};
  }
`

export const Button = styled.button`
  appearance: none;
  background-color: #9dc3e6;
  border: 0 none;
  color: ${({ theme }) => theme.colors.footer};
  display: block;
  font-size: 1.4rem;
  opacity: 1;
  outline: none;
  padding: 0.4rem 1.2rem;
  transition: opacity 150ms cubic-bezier(0, 0, 0, 0);
  user-select: none;
  will-change: opacity;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.apply {
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.background};
    margin: 0 auto;
    padding: 0.8rem 1.2rem;
  }
`

export const ButtonRateAll = styled.button`
  appearance: none;
  background-color: ${({ theme }) => theme.colors.accent};
  border: 0 none;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.background};
  display: block;
  font-size: 1.4rem;
  opacity: 1;
  outline: none;
  padding: 0.8rem 1.2rem;
  transition: opacity 150ms cubic-bezier(0, 0, 0, 0);
  user-select: none;
  will-change: opacity;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`
export const AddNewButton = styled.button`
  appearance: none;
  border: 0 none;
  //color: white;
  background-color: transparent;
  opacity: 1;
  outline: none;
  user-select: none;
  width: 20%;
  height: 20px;
  will-change: opacity;

  position: absolute;
  right: 0px;
  bottom: 60px;
  align-items: end;
`

export const CancelButton = styled.button`
  appearance: none;
  border: 0 none;
  color: #000000;
  display: block;
  font-size: 1.4rem;
  text-decoration: underline;
  opacity: 1;
  outline: none;
  padding: 0.4rem 1.2rem;
  transition: opacity 150ms cubic-bezier(0, 0, 0, 0);
  user-select: none;
  will-change: opacity;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const NextButton = styled.button`
  appearance: none;
  border: 0 none;
  background-color: #4075b1;
  color: #ffffff;
  display: block;
  font-size: 1.4rem;
  width: 50%;
  border-radius: 5px;
  text-align: center;
  opacity: 1;
  outline: none;
  padding: 0.4rem 1.2rem;
  transition: opacity 150ms cubic-bezier(0, 0, 0, 0);
  user-select: none;
  will-change: opacity;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const CameraIcon = styled(CameraOutlined)`
  font-size: 2rem;
  color: ${({ theme, active }) => (active ? theme.colors.blue : theme.colors.greyLight)};
`

export const CameraIconContainer = styled.div`
  width: 2rem;
  height: 2rem;
`

export const InputTel = styled.input`
  appearance: none;
  border: 0 none;
  border-radius: 4px;
  font-size: 1.6rem;
  padding: 0.8rem 1.2rem;
  text-align: center;
  width: 10rem;
`

export const ModalContent = styled.div`
  padding: 1.6rem 3.2rem;
`

export const AppliedModalContent = styled.div`
  padding: 1.6rem 3.2rem;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const RateAllWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  display: grid;
  justify-content: center;
  padding: 0.8rem 0;
  width: 100%;
`

export const RatingGrid = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 1.6rem;
  grid-template-columns: 0.4fr 1fr;
  padding: 1.2rem 0;

  &.three-wide {
    grid-gap: 0.8rem;
    grid-template-columns: 0.4fr 1fr 0.4fr;
  }

  &.horizontal-break {
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyLight};
    border-top: 1px solid ${({ theme }) => theme.colors.greyLight};
  }

  p.stretch {
    grid-column: 1/3;
  }

  p,
  label {
    font-size: 1.2rem;
  }

  label {
    font-weight: ${({ theme }) => theme.fontWeight.heavy};
  }
`

export const RowGrid = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 1.2rem;
  grid-template-columns: 1fr 1fr;
`

export const RowSelectWrap = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 1.2rem;
  grid-template-columns: 2rem 1fr;
  margin-bottom: 0.4rem;

  p {
    font-size: 1.3rem;
    min-width: 10rem;
  }
`

export const TableButtons = styled.div`
  display: grid;
  grid-gap: 0.4rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 0.4rem;
`
