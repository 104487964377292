import styled from 'styled-components'

export const ErrorWrapper = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  padding-top: 30%;
`

export const MessageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 50px;
`
