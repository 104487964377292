import styled, { css } from 'styled-components'
import Table, { Td } from '../../components/table/Table'
import ActionIcon from '../../components/actionIcon'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

export const LoanListTable = styled(Table)`
  tr {
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.table.valueHighlightBackground};
    }

    th,
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    }
  }
`

export const LoanListTr = styled.tr`
  line-height: 2rem;

  ${({ isActive }: { isActive: boolean }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.table.valueHighlightBackground};
    `}
`

export const BorrowerTd = styled(Td)`
  max-width: 150px;
`
export const LoanListPAIcon = styled(ActionIcon)`
  font-size: 2rem;
`

export const LeftArrow = styled(LeftOutlined)`
  color: ${({ disabled, theme }) => (disabled ? theme.colors.grey : theme.colors.disabled)};
  font-size: 1.9rem;
`

export const RightArrow = styled(RightOutlined)`
  color: ${({ disabled, theme }) => (disabled ? theme.colors.grey : theme.colors.disabled)};
  font-size: 1.9rem;
`

export const PaginationWrapper = styled.div`
  padding: 1rem;
  padding-bottom: 3rem;
  display: grid;
  justify-content: center;
  grid-template-rows: 30px;
`
export const PaginationButton = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-row: 1;
`

export const PaginationInfo = styled.div`
  grid-row: 1;
  display: grid;
  justify-content: center;
  align-items: center;
  min-width: 100px;
`
