import breakpoints from './breakpoints'

const colors = {
  // Major Elements
  background: '#f2f2f2',
  header: '#000000',
  footer: '#2e75b6',
  footerActive: '#5292cc',

  // Font colors
  textPrimary: '#0b0b0b',
  textLight: '#fafafa',

  // UI Colors
  primary: '#2e75b6',
  primaryDark: '#1f4e79',
  accent: '#28c09a',

  red: '#fc0006',
  orange: '#ed9942',
  green: '#37ab59',
  blue: '#326da7',
  bluelight: '#9dc3e6',
  greyLight: '#d0d0d0',
  grey: '#7d7f80',
  yellow: '#FEBF05',
  turquoise: '#40E0D0',

  // states
  stateDisabled: '#a6a6a6',

  // charts
  commitmentGrid: ['#339933', '#0070c0', '#ffc000'],

  riskChart: ['#ffc000', '#ffff00', '#92d050', '#339933'],

  // pending actions icons
  pendingActions: {
    blue: '#2d70ba',
    red: '#b12318',
    yellow: '#f1b042',
    green: '#28a745',
  },
}

export default {
  breakpoints,
  colors,
  fontWeight: {
    // only 400 & 700 weights are imported
    normal: 400,
    heavy: 700,
  },
  fontSize: {
    med: '1.3rem',
    sml: '1rem',
  },
  table: {
    headerTextColor: colors.textLight,
    headerBackground: colors.accent,
    valueHighlightBackground: '#CDE7F0',
    headerFontSize: '1.2rem',
  },
}
