import React from 'react'

import { ButtonWrap } from './style'

type Props = {
  children: React.ReactNode
  disabled: boolean
  name: string
  onClickFn: (event: React.ChangeEvent) => void
}

const Button: React.FC<Props> = ({ children, disabled, name, onClickFn }) => {
  return (
    <ButtonWrap disabled={disabled} onClick={onClickFn} name={name}>
      {children}
    </ButtonWrap>
  )
}

export default Button
