import React from 'react'
import styled from 'styled-components'

// types
import { DrawerContentData_exceptions } from '../../types/DrawerContentData'

interface Props {
  exceptions: (DrawerContentData_exceptions | null)[]
}


const Positioner = styled.div`
  bottom: 0;
  position: absolute;
  right: 1.2rem;
`

const Text = styled.text`
  font-size: 1.2rem;
`

export const Exceptions: React.FC<Props> = ({
  exceptions,
}) => {
  if (exceptions.length === 0) return null

  return (
    <Positioner>
      <svg height="16" width="16" viewBox="0 0 16 16">
        <circle
          cx="8"
          cy="8"
          fill="#fc0006"
          r="8"
        />
        {exceptions.length > 1 && (
          <Text x="8" y="12" textAnchor="middle" fill="#fff">{exceptions.length}</Text>
        )}
      </svg>
    </Positioner>
  )
}
