import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.header};
  color: white;

  pre {
    white-space: pre-wrap;
  }
`
