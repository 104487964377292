import React from 'react'

// styles
import { MessagebarIcon } from './style'

interface Props {
  active?: boolean
  activeColor: string
  disabled?: boolean
  Icon: React.ReactNode
  onFocus: () => void
  onClick: () => void
}

export const DecideMessagebarButton: React.FC<Props> = ({
  active,
  activeColor,
  disabled,
  onFocus,
  onClick,
  Icon,
}) =>  {

  const handleClick = () => {
     onFocus()
     if (disabled) {
       return
     }
     onClick()
  }

  return (
    <MessagebarIcon
      active={active}
      aria-pressed={active}
      aria-disabled={disabled}
      onClick={handleClick}
      color={activeColor}
    >
      {Icon}
    </MessagebarIcon>
  )
}
