import { createStore } from 'redux'

import appReducer from '../reducers'

const store = createStore(
  appReducer,
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
