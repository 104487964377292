import React from 'react'

// components
import DecideCommentIcon, { LoanCommentIconType } from '../decideCommentIcon'
import CommentEmoji from '../commentEmoji/CommentEmoji'

// icons
import { UserOutlined } from '@ant-design/icons'
import { UncheckedSquare } from '../icons/UncheckedSquare'
import { CheckedSquare } from '../icons/CheckedSquare'

//styles
import theme from '../../styles/theme'
import {
  Comment,
  CommentHeader,
  CommentHeaderText,
  CommentIcon,
  CommentIdentifier,
  CommentIdentifierText,
  CommentOutline,
  CommentText,
  CommentTimestamp,
  IconCircle,
  SystemMessage,
  UserMessage,
} from './style'

// types and interfaces
import { LoanComments_loan_comments } from '../../types/LoanComments'
import { LoanCommentEmoji } from '../../types/globalTypes'

interface Props {
  comment: LoanComments_loan_comments
  borrowerFullName: string
  loanFullId: string
  needsRead: boolean
  updateCommentAsRead: (targetComment: LoanComments_loan_comments) => void
}

export const DecideComment: React.FC<Props> = ({
  borrowerFullName,
  comment,
  loanFullId,
  needsRead,
  updateCommentAsRead,
}) => {
  return (
    <Comment>
      <CommentIdentifier>
        <CommentIdentifierText>
          {loanFullId} {borrowerFullName}
        </CommentIdentifierText>
        <CommentIcon align="right">
          {comment.type && (
            <DecideCommentIcon loanCommentType={LoanCommentIconType[comment.type]} />
          )}
        </CommentIcon>
      </CommentIdentifier>
      <CommentOutline>
        <CommentHeader>
          <CommentIcon>
            <IconCircle color={theme.colors.blue}>
              <UserOutlined style={{ fontSize: '20px', color: '#FFF' }} />
            </IconCircle>
          </CommentIcon>
          {comment.author && <CommentHeaderText>{comment.author.name.fullName}</CommentHeaderText>}
          <CommentIcon
            align="right"
            onClick={() => !comment.hasRead && updateCommentAsRead(comment)}
          >
            {needsRead
              ? comment.hasRead
                ? <CheckedSquare />
                : <UncheckedSquare color={theme.colors.stateDisabled} />
              : null
            }
          </CommentIcon>
        </CommentHeader>

        <CommentText>
          {comment.systemMessage && (
            <SystemMessage>
              {comment.emoji && <CommentEmoji emojiType={LoanCommentEmoji[comment.emoji]} />}
              <span>{comment.systemMessage}</span>
            </SystemMessage>
          )}
          {comment.userMessage && <UserMessage>{comment.userMessage}</UserMessage>}
        </CommentText>

        {comment.date && (
          <CommentTimestamp>{`
            ${comment?.date.month}/${comment?.date.day}/${comment?.date.year}
            ${comment?.date.hour}:${comment?.date.minute}
          `}</CommentTimestamp>
        )}
      </CommentOutline>
    </Comment>
  )
}
