import { useEffect, useState } from 'react'

const useDebounce = (
  value: any,
  delay: number = 1000, // in milliseconds
) => {
  const [ debouncedValue, setDebouncedValue ] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [
    delay,
    value,
  ])

  return debouncedValue
}

export default useDebounce
