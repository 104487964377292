import React from 'react'

export const UncheckedSquare: React.FC<{ color?: string | undefined; size?: number | string }> = ({
  color = '#000',
  size = 25,
}) => (
  <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.4844 2.73438H3.51562C3.0835 2.73438 2.73438 3.0835 2.73438 3.51562V21.4844C2.73438 21.9165 3.0835 22.2656 3.51562 22.2656H21.4844C21.9165 22.2656 22.2656 21.9165 22.2656 21.4844V3.51562C22.2656 3.0835 21.9165 2.73438 21.4844 2.73438ZM20.5078 20.5078H4.49219V4.49219H20.5078V20.5078Z"
      fill={color}
    />
  </svg>
)
