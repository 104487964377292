import gql from 'graphql-tag'

export const LoanCrops = gql`
  fragment cropInspectionLoanCrops on Loan {
    id
    crops: commodities {
      cropName
      cropCode
      inspectionProgress
      inspectionComplete
    }
  }
`

export const CropInspectionFragment = gql`
  fragment cropInspectionPageData on Query {
    loan(id: $loanId) {
      ...cropInspectionLoanCrops
    }
  }
  ${LoanCrops}
`

// Crop inspection v2 API calls
export const SAVE_CROP_INSPECTION = gql`
  mutation SaveCropInspection($input: CropInspectionSaveInput!) {
    saveCropInspection(input: $input) {
      userErrors {
        message
      }
    }
  }
`

// Get Landlord Relationship Data
export const GET_LANDLORD_RELATIONSHIP = gql`
  query GetLandlordsByFarmId($farmId: ID!) {
    landlordsByFarmId(farmId: $farmId) {
      landlordId
      farmId
      loanFullId
    }
  }
`

// Note: the input param $loanId is loan master id
export const GET_LATEST_CROP_INSPECTION = gql`
  query GetLatestCropInspection($loanId: ID!) {
    loan(id: $loanId) {
      id
      status
      crops: commodities {
        cropCode
        cropName
        inspectionComplete
        inspectionProgress
      }
      latestCropInspection {
        cropCodeGroups {
          cropCode
          loanCropUnits {
            id
            isAipVerified
            isFsaVerified
            farm {
              id
              state {
                abbreviation
              }
              county {
                name
              }
              percentProd {
                percentage
              }
              landowner
              fsn
              section
            }
            crop {
              cropName
              cropType
              irrPrac
              cropPrac
            }
            acres
            inspectedAcres
            cropRating
          }
          questionWithAnswerList {
            answer
            question {
              id
              question
            }
          }
        }
      }
      historicalCropInspections {
        id
        cropInspector {
          name {
            fullName(format: FIRST_NAME_FIRST)
          }
        }
        comment
        saveDate
      }
    }
    cropInspectionQuestions {
      id
      question
    }
  }
`
