import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  height: ${({standAlone, isIos}) => (isIos && !standAlone) ? '80%' : '100%' };
  grid-template-rows: 1fr 12rem;
  background-color: #fff;
`

export const CommentsWrapper = styled.div`
  padding: 0.625rem;
  overflow-y: scroll;
  padding-bottom: 1rem;
  background-color: ${({theme}) => theme.colors.background};
`

export const InputWrapper = styled.div`
  width: 100%;
  height: 11rem;
  position: relative;
`

export const ScrollSpacer = styled.div`
  height: 12rem;
`
