import React from 'react'

export const CheckedSquare: React.FC<{ color?: string | undefined; size?: number | string }> = ({
  color = '#000',
  size = 25,
}) => (
  <svg width={size} height={size} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5738 16.0571C10.6457 16.1574 10.7404 16.2392 10.8501 16.2955C10.9599 16.3519 11.0815 16.3813 11.2049 16.3813C11.3283 16.3813 11.4499 16.3519 11.5596 16.2955C11.6694 16.2392 11.7641 16.1574 11.836 16.0571L16.9776 8.92822C17.0704 8.79883 16.9776 8.61816 16.8189 8.61816H15.6739C15.4249 8.61816 15.188 8.73779 15.0416 8.94287L11.2061 14.2651L9.46784 11.853C9.32135 11.6504 9.08698 11.5283 8.83551 11.5283H7.69049C7.5318 11.5283 7.43903 11.709 7.5318 11.8384L10.5738 16.0571Z"
      fill={color}
    />
    <path
      d="M21.4844 2.73438H3.51562C3.0835 2.73438 2.73438 3.0835 2.73438 3.51562V21.4844C2.73438 21.9165 3.0835 22.2656 3.51562 22.2656H21.4844C21.9165 22.2656 22.2656 21.9165 22.2656 21.4844V3.51562C22.2656 3.0835 21.9165 2.73438 21.4844 2.73438ZM20.5078 20.5078H4.49219V4.49219H20.5078V20.5078Z"
      fill={color}
    />
  </svg>
)
