import styled from 'styled-components'

export const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: 1fr;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`

export const TableHeaders = styled.div`
  display: grid;
  grid-row: 1;
  width: 100px;
`

export const TableHeader = styled.header`
  align-items: center;
  background-color:${({ theme }) => theme.table.headerBackground};
  color: #ffff;
  display: grid;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.heavy};
  height: 35px;
  padding-left: .25rem;
`

export const TableValues = styled.div`
  grid-row: 1;
`

export const TableValue = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: start;
  height: 35px;
  min-width: 130px;
  background-color: ${({ top, theme }) =>
    top ? theme.table.valueHighlightBackground : theme.colors.background};
  color: ${({ theme }) => theme.textPrimary};
  font-size: ${({ theme }) => theme.table.headerFontSize};
  padding-left: 0.5rem;

  &.selected {
    border: 1px solid ${ ({ theme }) => theme.table.headerBackground };
  }
`
