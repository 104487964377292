import styled, { css } from 'styled-components'
// icons
import {
  ToolOutlined,
  ExportOutlined,
  LikeOutlined,
  CloudUploadOutlined,
  StopOutlined,
  DislikeOutlined,
  SwitcherOutlined,
  PlusCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import Paid from '../../icons/Paid'

const IconStyle = css`
  font-size: 2rem;
`

export const WorkingIcon = styled(ToolOutlined)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.green};
`

export const RecommendedIcon = styled(ExportOutlined)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.blue};
`

export const ApprovedIcon = styled(LikeOutlined)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.blue};
`

export const UploadedIcon = styled(CloudUploadOutlined)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.blue};
`

export const VoidedIcon = styled(StopOutlined)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.red};
`

export const DeclinedIcon = styled(DislikeOutlined)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.red};
`

export const WithdrawnIcon = styled(SwitcherOutlined)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.red};
`

export const HistoricalIcon = styled(PlusCircleOutlined)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.red};
`

export const WrittenOffIcon = styled(CloseOutlined)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.red};
`

export const PaidWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  height: 22px;
`

export const PaidIcon = styled(Paid)`
  ${IconStyle}
  font-size: unset;
  width: 33px;
  color: ${({ theme }) => theme.colors.red};
`
