import gql from 'graphql-tag'

const DecideLoanFragment = gql`
  fragment loanInfo on Loan {
    id
    displayId
    borrower: refBorrower {
      name {
        fullName
      }
    }
    comments {
      id
      date {
        day
        hour
        minute
        month
        second
        year
      }
      emoji
      userMessage
      systemMessage
      hasRead
      type
      author {
        name {
          fullName
        }
      }
    }
    isCrossCollateral
    pendingActions {
      escalationLevel
      id
      type
    }
    status
    viewerIsCommitteeMember
    viewerCommitteeMember {
      vote
    }
  }
`

const DecideMeFragment = gql`
  fragment PersonalData on Query {
    me {
      name {
        fullName
      }
    }
  }
`

export const DecidePageFragment = gql`
  fragment DecidePageData on Query {
    ...PersonalData
    loan(id: $loanId) {
      ...loanInfo
    }
  }
  ${DecideMeFragment}
  ${DecideLoanFragment}
`

export const GET_LOAN_COMMENTS = gql`
  query LoanComments($loanId: ID!) {
    ...PersonalData
    loan(id: $loanId) {
      ...loanInfo
    }
  }
  ${DecideLoanFragment}
  ${DecideMeFragment}
`

export const MARK_COMMENT_READ = gql`
  mutation loanCommentRead($commentId: ID!) {
    loanCommentRead(commentId: $commentId) {
      loan {
        ...loanInfo
      }
    }
  }
  ${DecideLoanFragment}
`

export const ADD_COMMENT = gql`
  mutation loanCommentAdd($applyToCrossCollateral: Boolean!, $loanId: ID!, $comment: LoanCommentAddInput!) {
    loanCommentAdd(applyToCrossCollateral: $applyToCrossCollateral, loanId: $loanId, comment: $comment) {
      loan {
        ...loanInfo
      }
    }
  }
  ${DecideLoanFragment}
`
