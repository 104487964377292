import React from 'react'
import gql from 'graphql-tag'

//components
import LoadingIndicator, { LoadingIndicatorWrapper } from '../../components/loadingIndicator'
import { LoanStatusIcon } from '../../components/loanStatusIcon/LoanStatusIcon'
import ErrorCard from '../../components/errorCard'
import EmptyCard from '../../components/emptyCard'

//styles
import {
  Wrapper,
  StatusIconWrapper,
} from './style'

import {
  TableHeader,
  TableHeaders,
  TableWrapper,
  TableValue,
  TableValues,
} from '../../components/gridTable/GridTable'

// types
import { BalancesData } from '../../types/BalancesData'

//constants
import { EMPTY_BALANCES_MESSAGE } from '../../constants/messages'

//helpers
import currencyFormat from '../../helpers/currencyFormat'

// fragment
export const BalancesFragment = gql`
  fragment BalancesData on Query {
    loan(id: $loanId) {
      id
      affiliatedLoans(includeSelf: true, where: { status: UPLOADED }) {
        applicationDate {
          day
          month
          year
        }
        balances {
          available
          outstandingBalance
          used
          usedPercent {
            percentage
          }
        }
        commitment {
          armCommitment
        }
        displayId
        borrower: refBorrower {
          name {
            fullName
          }
        }
        id
        terms {
          maturityDate {
            day
            month
            year
          }
        }
        status
      }
    }
  }
`

interface Props {
  error: boolean
  loading: boolean
  data: BalancesData
}

const BalancesPage: React.FC<Props> = ({ error, loading, data }) => {
  if (loading)
    return (
      <LoadingIndicatorWrapper>
        <LoadingIndicator />
      </LoadingIndicatorWrapper>
    )
  if (error) return <ErrorCard />

  if (data?.loan?.affiliatedLoans.length === 0)
    return <EmptyCard message={EMPTY_BALANCES_MESSAGE} />

  return (
    <Wrapper>
      <TableWrapper role="table" ariaLabel="Balances">
        <TableHeaders role="row">
          <TableHeader role="columnheader">Loan ID</TableHeader>
          <TableHeader role="columnheader">Borrower</TableHeader>
          <TableHeader role="columnheader">Status</TableHeader>
          <TableHeader role="columnheader">Maturity</TableHeader>
          <TableHeader role="columnheader">Outstanding</TableHeader>
          <TableHeader role="columnheader">ARM Commit</TableHeader>
          <TableHeader role="columnheader">Used</TableHeader>
          <TableHeader role="columnheader">Used %</TableHeader>
          <TableHeader role="columnheader">Available</TableHeader>
        </TableHeaders>

        {data?.loan?.affiliatedLoans.map((loan) => (
          <TableValues role="row" key={loan.id}>
            <TableValue role="cell" top>{loan.displayId}</TableValue>
            <TableValue role="cell" top>
              {loan.borrower && loan.borrower.name.fullName.length > 15
                ? `${loan.borrower.name.fullName.slice(0, 15)}...`
                : loan.borrower
                ? loan.borrower.name.fullName
                : null}
            </TableValue>
            <TableValue role="cell">
              <StatusIconWrapper>
                <LoanStatusIcon loanStatus={loan.status} />
              </StatusIconWrapper>
            </TableValue>
            <TableValue role="cell">{`${loan.terms.maturityDate.month}/${loan.terms.maturityDate.day}/${loan.terms.maturityDate.year}`}</TableValue>
            <TableValue role="cell">{currencyFormat(loan.balances.outstandingBalance)}</TableValue>
            <TableValue role="cell">{currencyFormat(loan.commitment.armCommitment)}</TableValue>
            <TableValue role="cell">{currencyFormat(loan.balances.used)}</TableValue>
            <TableValue role="cell">{loan.balances.usedPercent.percentage.toPrecision(4)}%</TableValue>
            <TableValue role="cell">{currencyFormat(loan.balances.available)}</TableValue>
          </TableValues>
        ))}
      </TableWrapper>
    </Wrapper>
  )
}

export default BalancesPage
