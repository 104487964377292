import React from 'react'

type Props = {
  size: number
  color?: string
}

const circle: React.FC<Props> = ({ size, color = '#000' }) => {
  return (
    <svg viewBox="0 0 50 50" width={size} height={size}>
      <circle cx={25} cy={25} r={25} fill={color} />
    </svg>
  )
}

export default circle
