import React, { useMemo } from 'react'
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useAuth } from '@arm/react-auth'

export const AuthorizedApolloProvider: React.FC = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth()
  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_API_URI,
    })

    const authLink = setContext(async () => {
      if (!isAuthenticated) {
        return {}
      }

      const token = await getAccessTokenSilently()
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    })

    return new ApolloClient({
      connectToDevTools: process.env.NODE_ENV === 'development',
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    })
  }, [isAuthenticated, getAccessTokenSilently])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
