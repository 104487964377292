import styled from 'styled-components'

export const BottomBar = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 0.4rem 1.6rem;
`

export const FarmingBlock = styled.p`
  font-size: ${({ header }) => (header ? '1rem' : '1.2rem')};
  font-weight: ${({ header }) => (header ? '700' : '400')};
`

export const FarmingBlocks = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-content: space-around;
  margin-top: 1.2rem;
  text-align: center;
`

export const Header = styled.h1`
  font-size: 1.4rem;
  font-weight: 700;
`

export const ItalicHeader = styled.p`
  font-size: 1.1rem;
  font-style: italic;
  grid-column: 1/3;
  padding-left: 2.2rem;
`

export const Location = styled.p`
  font-size: 1.1rem;
  font-weight: 700;
`

export const StaticHeaders = styled.div`
  display: grid;
  grid-column-gap: 0.6rem;
  grid-template-columns: 16px 1fr;
`

export const Subheader = styled.h2`
  font-size: 1.3rem;
  font-weight: 700;
`

export const TopBar = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.header};
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0rem 1.6rem;
`

export const Wrapper = styled.header`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  display: grid;
  grid-template-rows: 1fr 3.5fr;
  width: 100%;
`

export const SelectPrompt = styled.div`
  display: grid;
  justify-content: center;
  padding: 2rem;
`
