import {
  CLEAR_INSPECTION_DATA,
  NEW_INSPECTION,
  UPDATE_ANSWERS,
  UPDATE_RATINGS,
} from '../constants/cropInspections'

const BLANK_INSPECTION = {
  comment: null,
  cropInspectionAnswers: [],
  cropInspectionId: '',
  cropInspectionRatings: [],
  loanNumber: -1,
  numCrops: 0,
}

export default (state = BLANK_INSPECTION, action) => {
  switch (action.type) {
    case CLEAR_INSPECTION_DATA:
      return BLANK_INSPECTION

    case NEW_INSPECTION:
      return {
        ...BLANK_INSPECTION,
        loanNumber: action.loanNumber,
        cropInspectionAnswers: action.questionTemplate,
        cropInspectionRatings: action.ratingTemplate,
        numCrops: action.numCrops,
      }

    case UPDATE_RATINGS:
      return {
        ...state,
        cropInspectionRatings: action.updatedRatings,
      }

    case UPDATE_ANSWERS:
      return {
        ...state,
        cropInspectionAnswers: action.updatedAnswers,
      }

    default:
      return state
  }
}
