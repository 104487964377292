import { DecideCommentIcon } from './DecideCommentIcon'

export default DecideCommentIcon

export enum LoanCommentIconType {
  APPROVE = 'APPROVE',
  COMMITTEE_COMMENT = 'COMMITTEE_COMMENT',
  CONFERENCE_CALL = 'CONFERENCE_CALL',
  PASSIVE_COMMENT = 'PASSIVE_COMMENT',
  USER_NOTE = 'USER_NOTE',
  VOTE = 'VOTE',
  WATCH_LIST = 'WATCH_LIST',
  DECLINE = 'DECLINE',
  RESCIND = 'RESCIND',
}
