import styled from 'styled-components'

export const Comment = styled.div`
  padding: 1rem;
`

export const CommentHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 2rem;
  margin-bottom: 1rem;
`

export const CommentAuthorName = styled.div`
  font-weight: ${ ({ theme }) => theme.fontWeight.heavy };
  font-size: ${({ theme }) => theme.fontSize.med};
`

export const CommentDate = styled.div`
  font-size: ${({ theme }) => theme.fontSize.med};
  text-align: right;
`

export const CommentOutline = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 5px #d3d3d3;
  display: grid;
  grid-template-rows: auto auto auto;
  position: relative;
`

export const CommentText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.med};
  padding: 1rem;
`

export const NoCommentText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.med};
  font-style: italic;
  color: #bebebe;
  text-align: center;
  padding: 1rem;
`
