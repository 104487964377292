import React from 'react'
import { AuthProvider } from '@arm/react-auth'

export const AuthWrapper: React.FC = ({ children }) => {
  return (
    <AuthProvider
      authority={process.env.REACT_APP_AUTHORITY}
      clientId={process.env.REACT_APP_CLIENT_ID}
      redirectUri={`${process.env.REACT_APP_REDIRECT_HOST}/auth/callback`}
      postLogoutRedirectUri={`${process.env.REACT_APP_REDIRECT_HOST}/auth/logout/success`}
      scope="openid email profile offline_access synthesis:*"
      cacheStrategy="localStorage"
    >
      {children}
    </AuthProvider>
  )
}
