import React from 'react'

import ActionIcon from '../actionIcon'

import { Wrapper } from './style'

import { PendingActionType } from '../../types/globalTypes'

type PendingAction = {
  escalationLevel: number
  id: string
  type: PendingActionType
}

interface Props {
  pendingActions: PendingAction[]
}

export const PendingActions: React.FC<Props> = ({
  pendingActions,
}) => (
  <Wrapper count={pendingActions.length}>
    {pendingActions.map(pa => (
      <ActionIcon
        escalationLevel={pa.escalationLevel}
        key={pa.id}
        escalationType={pa.type}
      />
    ))}
  </Wrapper>
)
