import styled from 'styled-components'

export const BarLabel = styled.text`
  font-size: 1rem;
`

export const Label = styled.p`
  font-size: 0.8rem;
  font-weight: 700;
`

export const Legend = styled.div`
  align-items: center;
  display: grid;
  grid-column: 1/4;
  grid-gap: 0.4rem;
  grid-template-columns: repeat(3, 33%);
`

export const LegendColor = styled.div`
  background-color: ${ ({ color }) => color };
  height: 0.8rem;
  width: 0.8rem;
`

export const LegendEntry = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 0.4rem;
  grid-template-columns: 0.8rem 1fr;
`

export const RiskPercentage = styled.text`
  fill: ${ ({ theme }) => theme.colors.grey };
  font-size: 1rem;
`

export const Wrapper = styled.div`
  grid-column: 1/4;
`
