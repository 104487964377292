import React, { useEffect, useRef } from 'react'

import { createImageBitmap } from '../../helpers/createImageBitmap-polyfill'

import { ControlUnderlay, RetakeButton, UploadButton, Wrapper } from './style'

interface Props {
  retakePhoto(): void
  takenImageBlob: any
}

export const ImagePreview: React.FC<Props> = ({
  retakePhoto,
  takenImageBlob,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // render image from blob
    createImageBitmap(takenImageBlob).then(imageBitmap => {
      if (!canvasRef.current || !wrapperRef.current) return

      const { width } = wrapperRef.current.getBoundingClientRect()

      // set width to 100%
      canvasRef.current.width = width

      // get aspect ratio
      const imageHeight = imageBitmap.height
      const imageWidth = imageBitmap.width
      const aspectRatio = imageWidth > imageHeight
        ? imageWidth / imageHeight
        : imageHeight / imageWidth


      console.log(aspectRatio)
      // set height according to aspect ratio
      const height = width / aspectRatio
      canvasRef.current.height = height

      // set context
      const ctx = canvasRef.current.getContext('2d')
      const scale = window.devicePixelRatio || 1
      ctx?.scale(scale, scale)

      // clear stage
      ctx?.clearRect(0,0, width, height)

      // draw the image
      ctx?.drawImage(imageBitmap, 0, 0, width / scale, height / scale)
    })
  }, [
    takenImageBlob,
  ])

  return (
    <Wrapper ref={wrapperRef}>
      <canvas ref={canvasRef} />
      <ControlUnderlay>
        <div>
          <RetakeButton
            onClick={() => retakePhoto()}
          >
            Retake
          </RetakeButton>
          <UploadButton
            onClick={() => alert('Upload method')}
          >
            Upload
          </UploadButton>
        </div>
      </ControlUnderlay>
    </Wrapper>
  )
}
