import {
  MouseEvent,
  MutableRefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  cloneElement,
} from 'react'

import {
  createPortal,
} from 'react-dom'


type CustomEvent = {
  event?: SyntheticEvent<any, Event>
  portal: MutableRefObject<HTMLElement>
  targetEl: MutableRefObject<HTMLElement>
}

type CustomEventHandler = (customEvent: CustomEvent) => void

type UsePortalOptions = {
  closeOnOutsideClick?: boolean
  defaultedOpen?: boolean
  onOpen?: CustomEventHandler
  onClose?: CustomEventHandler
}

export default function usePortal({
  closeOnOutsideClick = true,
  defaultedOpen = false,
  onOpen,
  onClose,
}: UsePortalOptions = {}): any {
  // is portal open state
  const [ isOpen, setIsOpen ] = useState(defaultedOpen)

  // target for refs
  const targetEl = useRef<MutableRefObject<HTMLElement>>()

  // portal wrapper
  const portal = useRef<HTMLElement>(document.createElement('div'))

  useEffect(() => {
    if (!portal.current) portal.current = document.createElement('div')
  }, [
    portal,
  ])

  const createCustomEvent = useCallback((e: any) => {
    if (!e) {
      return { portal, targetEl, event: e }
    }

    const event = e || {}

    if (event.persist) event.persist()

    event.portal = portal
    event.targetEl = targetEl
    event.event = e

    const { currentTarget } = e

    if (!targetEl.current && currentTarget && currentTarget !== document) {
      targetEl.current = event.currentTarget
    }

    return event
  }, [

  ])

  const openPortal = useCallback((e: any) => {
    const customEvent = createCustomEvent(e)

    // if onOpen event exists, pass callback event
    if (onOpen) onOpen(customEvent)

    setIsOpen(true)
  }, [
    createCustomEvent,
    onOpen,
    setIsOpen,
  ])

  const closePortal = useCallback((e: any) => {
    const customEvent = createCustomEvent(e)

    // send onClose callback event
    if (onClose && isOpen) onClose(customEvent)

    if (isOpen) {
      setIsOpen(false)
    }
  }, [
    createCustomEvent,
    isOpen,
    onClose,
    setIsOpen,
  ])

  const handleOutsideMouseClick = useCallback((e: MouseEvent): void => {
    if (!e || !e.target) return

    const containsTarget = (target: MutableRefObject<HTMLElement>) =>
      target.current.contains(e.target as HTMLElement)

    if (containsTarget(portal) || !isOpen) return

    if (closeOnOutsideClick) closePortal(e)
  }, [
    closePortal,
    closeOnOutsideClick,
    isOpen,
    portal,
  ])

  useEffect(() => {
    if (!(document.body) || !(portal.current instanceof HTMLElement)) return

    const node = portal.current
    document.body.appendChild(portal.current)

    document.addEventListener('mousedown', handleOutsideMouseClick as any)

    return () => {
      document.removeEventListener('mousedown', handleOutsideMouseClick as any)
      document.body.removeChild(node)
    }
  }, [
    createCustomEvent,
    handleOutsideMouseClick,
    portal,
  ])

  const Portal = useCallback(({ children, ...restProps }) => {
    if (!portal.current || !isOpen) return null

    const childrenWithProps = cloneElement(children, { ...restProps })

    return createPortal(childrenWithProps, portal.current)
  }, [
    isOpen,
    portal
  ])

  return Object.assign(
    [
      closePortal,
      openPortal,
      portal,
      Portal,
      targetEl,
    ],
    {
      isOpen,
      openPortal,
      ref: targetEl,
      closePortal,
      Portal,
      portalRef: portal,
    }
  )
}
