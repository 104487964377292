import styled from 'styled-components'

export const Table = styled.table`
  font-size: 1.2rem;
  width: 100%;
`

export const Td = styled.td`
  padding: 1rem 0.5rem;

  text-align: ${({ align = 'left' }: { align?: 'left' | 'center' | 'right' }) => align};
`

export const Th = styled.th`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.heavy};
  color: ${({ theme }) => theme.table.headerTextColor};
  line-height: 4rem;
  background-color: ${({ color, theme }: { color: 'default' | 'red'; theme: any }) =>
    color === 'red' ? theme.colors.red : theme.table.headerBackground};

  text-align: ${({ align = 'left' }: { align?: 'left' | 'center' | 'right' }) => align};
`

export default Table
