import styled from 'styled-components'

export const CropInspectionBlockContainer = styled.div`
  border-bottom: solid .1rem ${({theme}) => theme.colors.grey};
`

export const TabsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
`

export const Tab = styled.div`
  background-color: ${({ active, theme }) => active ? theme.colors.background : theme.colors.greyLight };
  display: grid;
  justify-content: center;
  opacity: 1;
  padding: .5rem;
  transition: opacity 150ms cubic-bezier(0, 0, 0, 0);
  will-change: opacity;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const Wrapper = styled.div`
  padding-bottom: 14rem;
`
