import React, { useRef, useEffect } from 'react'

import { Video, Wrapper } from './style'

interface Props {
  children?: React.ReactNode
  currentOrientation: 'portrait' | 'landscape'
  setVideoMediaStream: React.Dispatch<React.SetStateAction<MediaStream | undefined>>
  videoMediaStream: MediaStream | undefined
}

export const Camera: React.FC<Props> = ({
  children,
  currentOrientation,
  setVideoMediaStream,
  videoMediaStream,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  // list of all video devices
  // const [ devices, setDevices ] = useState<MediaDeviceInfo[]>([])

  // selected video device
  // const [ selectedDevice, setSelectedDevice ] = useState<string>('')

  // useEffect(() => {
  //   if (navigator.mediaDevices) {
  //     const gotDevices = mediaDevices => {
  //       const videoInputs = mediaDevices.filter(md => md.kind === 'videoinput')

  //       setSelectedDevice(videoInputs[0].deviceId)
  //       setDevices(videoInputs)
  //     }

  //     navigator.mediaDevices.enumerateDevices().then(gotDevices)
  //   }
  // }, [])

  useEffect(() => {
    const stopAllCurrentStreams = async () => {
      if (videoMediaStream) {
        await videoMediaStream.getTracks().forEach(track => track.stop())
      }
    }

    stopAllCurrentStreams()

    const videoConstraints: any = {}

    // if (selectedDevice === '') {
      videoConstraints.facingMode = 'environment'
    // } else {
    //   videoConstraints.deviceId = { exact: selectedDevice }
    // }

    const constraints = {
      video: videoConstraints,
      audio: false
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        setVideoMediaStream(stream)

        if (!wrapperRef || !wrapperRef.current) return null

        const { height, width } = wrapperRef.current?.getBoundingClientRect()
        const vidHeight = currentOrientation === 'landscape'
          ? height
          : height - 120
        const vidWidth = currentOrientation === 'landscape'
          ? width - 120
          : width

        if (videoRef && videoRef.current) {
          videoRef.current.setAttribute('playsinline', 'true')
          videoRef.current.setAttribute('height', vidHeight.toString())
          videoRef.current.setAttribute('width', vidWidth.toString())
          videoRef.current.srcObject = stream
          videoRef.current.play()
          videoRef.current.removeAttribute('controls')
        }

        // return navigator.mediaDevices.enumerateDevices();
      })
      .catch(error => {
        console.error(error)
      })
  // NOTE | WK | Purposefully did not include 'videoMediaStream' because the effect
  // becomes incestuous and creates a loop from the track stopping and in the promise steps
  // eslint-disable-next-line
  }, [
    currentOrientation,
    // selectedDevice,
    setVideoMediaStream,
    // videoMediaStream,
  ])

  return (
    <Wrapper
      ref={wrapperRef}
    >
      <Video
        ref={videoRef}
      />
      <>
        {/* <select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            console.log(e.target.value)
            setSelectedDevice(e.target.value)
          }}
          value={selectedDevice}
        >
          {devices.map((d, index) => (
            <option value={d.deviceId} key={d.deviceId}>{d.label || `Camera ${ index + 1 }`}</option>
          ))}
        </select> */}
        {/* {videoInputs.length > 1 && (
          <SwitchCameraButton
            onClick={switchCamera}
          >
            Switch Camera
          </SwitchCameraButton>
        )} */}
        {children}
      </>
    </Wrapper>

  )
}
