import styled from 'styled-components'
import { ArrowUpOutlined } from '@ant-design/icons'

export const CheckBox = styled.input`
  appearance: none
  background-color: ${ ({ theme }) => theme.colors.background };
  border: 1px solid ${ ({ theme }) => theme.colors.grey };
  border-radius: 4px;
  height: 2rem;
  margin: 0;
  transition: background-color 150ms cubic-bezier(0, 0, 0, 0),
              border-color 150ms cubic-bezier(0, 0, 0, 0);
  width: 2rem;

  &:checked {
    background-color: ${ ({ theme }) => theme.colors.blue };
    border-color: ${ ({ theme }) => theme.colors.blue };
  }

  + label {
    color: ${ ({ theme }) => theme.colors.grey };
    font-size: 1.3rem;
  }
`

export const CrossCollateralInput = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: 2rem 1fr;
  margin: 0.4rem auto 0;
  max-width: 22rem;
  opacity: 0;
  transition: opacity 150ms cubic-bezier(0, 0, 0, 0);
  will-change: opacity;

  &.shown {
    opacity: 1;
  }
`

export const SendArrow = styled(ArrowUpOutlined)`
  font-size: 1.8rem;
  color: #fff;
`

export const SendArrowCircle = styled.button`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: ${({ disabled, hasCharacters, theme }) => disabled
    ? theme.colors.grey
    : hasCharacters
      ? theme.colors.blue
      : '#add8e6'
  };
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  border: none;
  transition: background-color 150ms cubic-bezier(0, 0, 0, 0);
`

export const Messagebar = styled.div`
  background-color: #fff;
  display: grid;
  padding: 1rem 1rem 0.5rem 1rem;
`

export const MessagebarInputContainer = styled.div`
  background-color: #fff;
  border: 1px solid grey;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  min-height: 35px;
  display: grid;
  padding-left: 1rem;
  padding-right: 1.2rem;
  grid-auto-flow: column;
  grid-template-columns: 1fr 20px;
  margin-top: 0.5rem;
`

export const MessagebarInput = styled.input`
  width: 80%;
  line-height: 25px;
  border: none;
`
export const Iconbar = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr;
`

export const IconGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, 30px);
  column-gap: 4px;
`

export const AlertBanner = styled.div`
  background: ${ ({ success }) => success ? '#c0ffd2' : '#ffc0cb' };
  color: ${({ success, theme }) => success ? theme.colors.green : theme.colors.red};
  display: grid;
  position: absolute;
  width: 100%;
  top: -3.5rem;
  justify-content: center;
  padding: 1rem 1rem;
  font-size: 1rem;
`
