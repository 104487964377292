import React, { useState, useEffect } from 'react'

import { ImageCapture } from 'image-capture'

// components
import Camera from '../../components/camera'
import ImagePreview from '../../components/imagePreview'

// style
import { CameraControls, CancelButton, CaptureButton } from './style'

const isDeviceLandscape = window.matchMedia('(orientation: landscape)')

interface Props {
  history: {
    goBack(): void
  }
}

export const PhotosPage: React.FC<Props> = ({
  history,
}) => {
  // stored blob of image/jpg
  const [ takenImageBlob, setTakenImageBlob ] = useState<any | undefined>()
  // current orientation for UI changes
  const [ currentOrientation, setCurrentOrientation ] = useState<'portrait' | 'landscape'>(
    isDeviceLandscape.matches ? 'landscape' : 'portrait'
  )
  // stored MediaStream from camera
  const [ videoMediaStream, setVideoMediaStream ] = useState<MediaStream | undefined>()




  useEffect(() => {
    const orientationEvent = () => {
      const degrees = window.orientation.toString()

      setCurrentOrientation([ '0', '180' ].includes(degrees)
        ? 'portrait'
        : 'landscape'
      )
    }

    window.addEventListener('orientationchange', orientationEvent)

    orientationEvent()

    return () => window.removeEventListener('orientationchange', orientationEvent)
  })


  const captureImage = () => {
    if (videoMediaStream) {
      const videoTrack = videoMediaStream.getVideoTracks()[0]

      const imageCapture = new ImageCapture(videoTrack)

      imageCapture.takePhoto()
        .then(blob => {
          setTakenImageBlob(blob)
        })
        .catch(e => {
          console.error('failed to capture image from stream')
          console.error(e)
        })
    }
  }

  if (takenImageBlob) {
    const retakePhoto = () => setTakenImageBlob(null)

    return (
      <ImagePreview
        retakePhoto={retakePhoto}
        takenImageBlob={takenImageBlob}
      />
    )
  }

  return (
    <>
      <Camera
        currentOrientation={currentOrientation}
        setVideoMediaStream={setVideoMediaStream}
        videoMediaStream={videoMediaStream}
      >
        <CameraControls>
          <div>
            <CancelButton
              onClick={() => history.goBack()}
            >
              Cancel
            </CancelButton>
            <CaptureButton
              name="Capture-Button"
              onClick={() => captureImage()}
            />
          </div>
        </CameraControls>
      </Camera>
    </>
  )
}
