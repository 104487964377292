import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  overflow: hidden;
  height: ${({ mainHeight }) => mainHeight}px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.device_landscape}) and (orientation: landscape) {
    height: auto;
  }
`

export const Comment = styled.div``

export const CommentHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sml};
  font-weight: ${({ theme }) => theme.fontWeight.heavy};
`

export const CommentText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.med};
  padding: 1.2rem;
  white-space: pre-line;
`

export const CommentsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  height: ${({ commentsHeight }) => commentsHeight}%;
  transition: height 0.5s cubic-bezier(0.4, 0.01, 1, 1);
  overflow-y: scroll;
`

export const Detail = styled.a`
  background-color: #dddddb;
  border-top: solid 1px #aeadaf;
  padding: 0.8rem 0rem;
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  text-decoration: none;
`

export const DetailIcon = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
`

export const DetailActionIcon = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`

export const DetailText = styled.div`
  display: grid;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.med};
  color: ${({ theme, validation, exception }) =>
    exception ? theme.colors.red : validation ? theme.colors.orange : theme.colors.textPrimary};
`

export const DetailsWrapper = styled.div`
  height: ${({ detailsHeight }) => detailsHeight}%;
  transition: height 0.5s cubic-bezier(0.4, 0.01, 1, 1);
  overflow-y: scroll;
  background-color: #d0d0d0;
  padding-bottom: 2.5rem;
`

export const ExceptionActionContainer = styled.div`
  background-color: ${({ theme, supported, authorized }) =>
    authorized ? (supported ? theme.colors.blue : theme.colors.green) : theme.colors.stateDisabled};
  display: grid;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
`
